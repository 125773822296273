import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {User} from '../models/user';
import {Role} from '../models/role';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthService} from '../auth/services/auth.service';
import {HotlineService} from './hotline.service';

@Injectable()

export class HouseholdService {
  householdData;
  household = new Subject();
  //sharedHouseholdData = this.household.asObservable();
  private householdSelected = false;
  householdTest = false;

  private user: User;
  userId;
  constructor(private http: HttpClient) {
    // if (localStorage.getItem('currentUser')) {
    // } else {
    // }
    // this.authService.getLoginInfo().subscribe((response: any) => {
    //   this.userId = response.user.id;
    // })
  }

  isAdmin() {
    if (localStorage.getItem('currentUser') == 'admin' || localStorage.getItem('currentUser') == 'owner') {
      return false;
    }
    return true;
  }


  isAuthorized() {
    if (localStorage.getItem('currentUser') == Role.Admin) {
      return true;
    } else {
      return false;
    }
    // return !!this.user;
  }

  hasRole(role: Role) {
    return this.isAuthorized() && this.user.role === role;
  }

  nextHouseholdState(data: any) {
    if (data) {
      this.household.next(data);
      localStorage.setItem('household_id', data.id);
      localStorage.setItem('household_fullName', data.name + ' ' + data.surname);
      localStorage.setItem('household_avatar', data.avatar_img);
      if (data.role === 'admin' || data.role === 'owner') {
        localStorage.setItem('currentUser', 'admin');
      } else {
        localStorage.setItem('currentUser', data.role);
      }
      localStorage.setItem('userRole', data.role)
    }
  }

  // getRole() {
  //   const householdId = localStorage.getItem('household_id');
  //   let data;
  //   return this.http.get(`${environment.api}user-type/${householdId}`)
  //   .subscribe(response => {
  //     data = response;
  //     if (data.user_type === 'admin' || data.user_type === 'owner') {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }, err => {
  //     return false;
  //   });
  // }

  getHouseholdData() {
    // const userId = localStorage.getItem('user_id');
    // const userId = this.authService.getId();
    const householdId = localStorage.getItem('household_id');
    return this.http.get(`${environment.api}households`).pipe(map((response: any) => {
      console.log('response in household service', response);
      const households = [];
      for (const x in response.households) {
        console.log('response household', response.households[x].user_type);
        households[x] =
          {
            id: response.households[x].id,
            name: response.households[x].alarm_owner[0]?.name,
            surname: response.households[x].alarm_owner[0]?.surname,
            avatar_img: response.households[x].alarm_owner[0]?.avatar_img,
            sensitivity: response.households[x]?.sensitivity,
            role: response.households[x]?.user_type
          }
        if (households[x].id == householdId) {
          this.householdData = households[x];
          console.log('YES', this.householdData)
        }
      }
      console.log('before returning households', households);
      return households;
    }));
  }

  pushSensitivity(sensitivity) {
    const householdId = localStorage.getItem('household_id');
    const data = {
      household_id: householdId,
      sensitivity
    }
    return this.http.post(`${environment.api}set-sensitivity`, data).pipe();
  }

  setHouseholdSelect(value) {
    console.log('is selected', value);
    this.householdSelected = value;
  }

  isHouseholdSelected() {
    if (this.isAdmin()) {
      return this.householdSelected;
    } else {
      return true;
    }
  }
}
