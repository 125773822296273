import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-hilfe',
  templateUrl: './hilfe.component.html',
  styleUrls: ['./hilfe.component.css']
})
export class HilfeComponent implements OnInit {

  faqs = []
  faqsLimit= 5;
  contactForm:FormGroup

  constructor(
    private cdr: ChangeDetectorRef, 
    private faqService: FaqService,
    private toasterService: ToastrService,
    private translate: TranslateService,
    ) {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone: new FormControl('',[Validators.pattern('((^[+][0-9]{10,19}))')]),
      description: new FormControl('',[Validators.maxLength(500)]),
      contactType: new FormControl('phone',[Validators.required]),

      reasons: new FormGroup({
        status: new FormControl(false),
        bookServices: new FormControl(false),
        statistics: new FormControl(false),
        settings: new FormControl(false),
        bills: new FormControl(false),
        accountManagement: new FormControl(false)
      },this.requireCheckboxesToBeCheckedValidator()),
    })
   }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.faqService.getFAQs().subscribe({
      next: (faqs) =>{
        this.faqs = faqs
      }
    })
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  isOpened(element){
    let value = element.parentNode.getAttribute('aria-expanded')
    return value
  }

  loadMore(){
    let valToAssign = this.faqsLimit + 6
    let diff = this.faqs.length-1 - valToAssign
    if(diff>0){
      this.faqsLimit = valToAssign
    }else{
      this.faqsLimit = this.faqs.length
    }
  }

  submitContactForm(){
    let data = new FormData()
    data.append('name', this.contactForm.get('name').value)
    data.append('email', this.contactForm.get('email').value)
    data.append('phone', this.contactForm.get('phone').value)
    data.append('description', this.contactForm.get('description').value)
    data.append('contactType', this.contactForm.get('contactType').value)
    let reasons = {
      status: ['Ist-Status / Dashboard', this.contactForm.get('reasons').get('status').value],
      bookServices: ['Services buchen', this.contactForm.get('reasons').get('bookServices').value],
      statistics: ['Statistiken', this.contactForm.get('reasons').get('statistics').value],
      settings: ['Service einrichten / Einstellungen', this.contactForm.get('reasons').get('settings').value],
      bills: ['Rechnungen', this.contactForm.get('reasons').get('bills').value],
      accountManagement: ['Kontoverwaltung / Mein Konto', this.contactForm.get('reasons').get('accountManagement').value],
    }
    data.append('reasons',JSON.stringify(reasons))
   
    this.faqService.sendEmail(data).subscribe({
      next: (res)=>{
        console.log('Urra', res)
        this.contactForm.reset()
        this.showSuccess(this.translate.instant('faq.successEmail'), this.translate.instant('toasterMessages.success'));
      },
      error: (err)=>{
        console.log(err)
        this.showError(this.translate.instant('faq.errorEmail'), this.translate.instant('toasterMessages.error'));
      }
    })
  }

  requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate (formGroup: FormGroup) {
      let checked = 0;
  
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];
  
        if (control.value === true) {
          checked ++;
        }
      });
  
      if (checked < minRequired) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      }
  
      return null;
    };
  }

  isEditFieldValid(field: string) {
    return !this.contactForm.get(field).valid && this.contactForm.get(field).touched;
  }

  showSuccess(message, title) {
    this.toasterService.success(message, title)
  }

  showError(message, title) {
    this.toasterService.error(message, title)
  }
}
