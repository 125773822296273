<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'tabs.wifiConfig' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a>
              </li>
              <li class="breadcrumb-item active">{{'tabs.wifiConfig' | translate}}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">

        <div class="row mb-1 mt-1">
          <div class="col s12">
            <div class="card mb-4">
              <div class="card-content">
                <h4 class="card-title mb-0">{{'wifiConfig.header' | translate}}</h4>

                <div class="mt-5 mb-2">
                  <div *ngIf="loadingAvailableWifis" class="text-center mt-4">
                    <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>

                  <div  #divToScroll *ngIf="!loadingAvailableWifis" [ngClass]="availableWifis.length === 0 ? '' : 'scrollbox'">
                    <h6 *ngIf="availableWifis.length === 0" class="text-center">
                      {{'wifiConfig.noAvailable' | translate}}
                    </h6>
                    <accordion *ngIf="availableWifis.length !== 0" [closeOthers]="true">
                      <accordion-group *ngFor="let wifi of availableWifis" [isDisabled]="wifi.connected">
                        <button (click)="inputPassword(wifi)"
                          class="btn-link btn-block justify-content-between d-flex w-100"
                          accordion-heading type="button">
                          <i class="material-icons my-auto wifi-icon">wifi</i>
                          <h6 id="{{wifi.name}}-name" class="my-auto wifi-name cursor-pointer" [ngClass]="wifi.connected ? 'connected' : ''">{{wifi.name}}</h6>
                          <h6 *ngIf="!wifi.connected" class="my-auto empty" id="{{wifi.name}}-empty"></h6>
                          <i *ngIf="wifi.connected" class="material-icons done my-auto" id="{{wifi.name}}-connected">
                            done
                          </i>
                        </button>
                        <div class="d-flex justify-content-between" *ngIf="!wifi.connected">
                          <small class="my-auto">{{'global.password' | translate}}</small>
                          <input type="password" id="{{wifi.name}}-input" class="text-center w-50" (input)="inputChanged($event)">
                          <i class="material-icons my-auto cursor-pointer" id="{{wifi.name}}-visibility" (click)="toggleVisibility();$event.stopPropagation()">
                            <ng-container *ngIf="passwordVisibility">visibility</ng-container>
                            <ng-container *ngIf="!passwordVisibility">visibility_off</ng-container>
                          </i>
                          <div class="lds-default lds-small hidden" id="{{wifi.name}}-loader">
                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                            <i class="material-icons done my-auto hidden" id="{{wifi.name}}-connected">
                              done
                            </i>

                            <i class="material-icons eror my-auto hidden" id="{{wifi.name}}-connectionError">
                              priority_high
                            </i>
                        </div>
                      </accordion-group>
                    </accordion>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-4">
                  <button class="btn btn-large btn-primary" (click)="goToContacts()">{{'wifiConfig.done' | translate}}</button>
                  <button class="btn btn-large"
                    [ngClass]="validInput ? 'btn-success' : 'btn-inactive'"
                    [disabled]="!validInput" (click)="connectToWifi()">
                      <span *ngIf="!loadingConnect">
                        {{'wifiConfig.connect' | translate}}
                      </span>
                      <div *ngIf="loadingConnect" class="lds-connect lds-default">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </button>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->
