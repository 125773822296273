<div
  class="horizontal-layout page-header-light horizontal-menu preload-transitions 1-column login-bg   blank-page blank-page"
  data-open="click" data-menu="horizontal-menu" data-col="1-column">
  <div class="row">
    <div class="col s12">
      <div class="container">
        <div id="login-page" class="row">
          <div class="col s12 m8 l8 z-depth-4 card-panel login-card bg-opacity-8">
            <form class="login-form">
              <div class="row">
                <div class="input-field mt-4 mb-0 col s12">
                  <h5 class="ml-2">Haushalt wählen</h5>
                  <p class="ml-2 mb-0">Bitte wählen Sie zuerst den Haushalt, der geladen werden soll:</p>
                </div>
              </div>
              <div class="row ml-0 mr-0">
                <div class="input-field col s12">
                  <div class="" dropdown>
                    <div class="">
                      <input readonly [value]="currentHousehold" id="button-disabled-item-1" dropdownToggle type="button"
                             aria-controls="dropdown-disabled-item-1" type="text"
                             class="form-control dropdown-toggle">
                    </div>
                    <ul id="dropdown-disabled-item-1" *dropdownMenu
                        class="dropdown-menu custom-option"
                        role="menu" aria-labelledby="button-disabled-item-1">
                      <li *ngFor="let item of households" role="menuitem" (click)="selectHousehold(item)">
                        <a class="dropdown-item">{{item?.name}} {{item?.surname}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="input-field col s12">
                  <button (click)="goToDashboard()" [disabled]="disabled" class="btn waves-effect waves-light border-round red col s12">Weiter zum
                    Dashboard</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="content-overlay"></div>
    </div>
  </div>
</div>
