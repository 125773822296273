<div class="horizontal-layout page-header-light horizontal-menu preload-transitions 1-column blank-page blank-page"
     data-open="click" data-menu="horizontal-menu" data-col="1-column">
  <div class="row">
    <div class="col s12">
      <div class="container">
        <div id="login-page" class="row">
          <div class="col s12 m8 l8 z-depth-4 card-panel login-card bg-opacity-8">
            <form class="forgot-password-form" [formGroup]="forgotPasswordForm" (ngSubmit)="resetPassword()">
              <div class="row">
                <div class="input-field mt-4 col s12">
                  <h5 class="ml-4">{{'authentication.forgotPassword' | translate}}</h5>
                  <p class="ml-4 mb-0">{{'authentication.forgotPasswordDescription' | translate}}</p>
                </div>
              </div>
              <div class="row margin">
                <div class="input-field col s12">
                  <i class="material-icons notranslate prefix pt-2">person_outline</i>
                  <input id="username" type="text" placeholder="E-Mail-Adresse" id="email" type="email"
                         formControlName="email" required>
                  <!--                  <label for="username" class="center-align">E-Mail-Adresse</label>-->
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <button type="submit"
                          [disabled]="!forgotPasswordForm.valid" href="#"
                          class="btn waves-effect waves-light border-round red col s12">{{'authentication.resetPassword' | translate}}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s6 m6 l6">
                  <p class="margin medium-small"><a class="forgot-text" routerLink="/login">{{'authentication.login' | translate}}</a></p>
                </div>
                <!--                <div class="input-field col s6 m6 l6">-->
                <!--                  <p class="margin right-align medium-small"><a class="forgot-text" href="#">Registrieren</a></p>-->
                <!--                </div>-->
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="content-overlay"></div>
    </div>
  </div>
</div>
