<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>Services buchen</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a>
              </li>
              <li class="breadcrumb-item active">Services buchen
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">
        <div class="row mb-1 mt-1">
          <div class="col s12 l6">
            <div class="card mb-2">
              <div class="card-content pb-0">
                <h4 class="card-title mb-0">Abo-Verträge</h4>
                <table class="no-border-last">
                  <tbody>
                  <tr>
                    <td><b>Paket 1</b><br>Klassischer Notruf</td>
                    <td></td>
                    <td class="right-align"><a class="tooltipped right" data-position="bottom" data-tooltip="Der klassische Hannah-Notruf bietet..."><i class="notranslate material-icons">help</i></a></td>
                  </tr>
                  <tr>
                    <td><b>Paket 2</b><br>Aktiver Notrufalarm – Alarmkette</td>
                    <td></td>
                    <td class="right-align"><a class="tooltipped right" data-position="bottom" data-tooltip="Der aktive Hannah-Notrufalarm..."><i class="notranslate material-icons">help</i></a></td>
                  </tr>
                  <tr>
                    <td><b>Paket 3</b><br>Passiver Notrufalarm – Ereigniskette</td>
                    <td><span class="badge green lighten-5 green-text text-accent-4">GEBUCHT</span></td>
                    <td class="right-align"><a class="tooltipped right" data-position="bottom" data-tooltip="Der passive Hannah-Notrufalarm..."><i class="notranslate material-icons">help</i></a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-action border-non center">
                <a class="waves-effect waves-light btn mb-1">Alle Pakete im Shop ansehen</a>
              </div>
            </div>
          </div>
          <div class="col s12 l6">
            <div class="card mb-4">
              <div class="card-content">
                <h4 class="card-title mb-2">Zusätzliche Leistungen</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
              </div>
              <div class="card-action border-non center">
                <a class="waves-effect waves-light btn mb-1">Alle Leistungen im Shop ansehen</a>
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-content">
                <h4 class="card-title mb-0">Zusatz-Leistungen</h4>
                <table class="no-border-last">
                  <tbody>
                  <tr>
                    <td><b>Leistungen 1</b><br>Lorem ipsum dolor sit amet</td>
                    <td><span class="badge green lighten-5 green-text text-accent-4">GEBUCHT</span></td>
                    <td class="right-align"><a class="tooltipped right" data-position="bottom" data-tooltip="Der passive Hannah-Notrufalarm..."><i class="notranslate material-icons">help</i></a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->
