<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>Hilfe</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a>
              </li>
              <li class="breadcrumb-item active">Hilfe
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">

        <div class="row mb-1 mt-1">
          <div class="col s12 l6">
            <div class="card mb-4">
              <div class="card-content service">
                <div class="row">
                  <div class="col s9 m9">
                    <h4 class="mb-0 mt-0">Hotline: <b>+49 (0) 160 188 3785</b></h4>
                    <p class="no-margin">Bitte beachten Sie unsere Servicezeiten!
                      <br><br>
                      Montags - Donnerstag<br>
                      08:30 Uhr bis 13:30 Uhr</p>
                  </div>
                  <div class="col s3 m3 right-align">
                    <i class="material-icons notranslate background-round mt-5 mb-5 green white-text">local_phone</i>
                  </div>
                </div>
              </div>

            </div>
            <div class="card">
              <div class="card-content faq">
                <h4 class="card-title mb-0">FAQ (Häufig gestellte Fragen)</h4>
                <accordion [isAnimated]="true" [closeOthers]="true">
                  <accordion-group [panelClass]="'customClass'">
                    <button #ref
                      class="btn btn-link btn-block justify-content-between d-flex w-100  shadow-none"
                      accordion-heading type="button">
                      <div class=" text-format">1. Was bedeuten die einzelnen Symbole/Funktionalitäten auf meiner HANNAH?</div>
                      <i *ngIf="isOpened(ref) === 'false'" class="material-icons notranslate icon-format">
                        keyboard_arrow_right </i>
                        <i *ngIf="isOpened(ref) === 'true'" class="material-icons notranslate icon-format">
                          keyboard_arrow_down </i>
                    </button>
                    <img style="width: 100%;" src="../../../assets/images/hannah-info.png">
                  </accordion-group>
                  <accordion-group *ngFor="let question of faqs | slice:0:faqsLimit; let i = index" [panelClass]="'customClass'">
                    <button #ref
                      class="btn btn-link btn-block justify-content-between d-flex w-100  shadow-none"
                      accordion-heading type="button">
                      <div class=" text-format">{{i+2}}. {{question.question}}</div>
                      <i *ngIf="isOpened(ref) === 'false'" class="material-icons notranslate icon-format">
                        keyboard_arrow_right </i>
                        <i *ngIf="isOpened(ref) === 'true'" class="material-icons notranslate icon-format">
                          keyboard_arrow_down </i>
                    </button>
                    <p *ngIf="question.additionalInfo" class="mb-2">{{question.additionalInfo}}</p>
                    <div *ngFor="let answer of question.answers" class="d-box align-items-start mb-2">
                      <span style="color: red;">&#8211; &nbsp;</span>
                      <p class="pr-6">{{answer}}</p>
                    </div>

                    <div *ngFor="let subAnswer of question.subAnswers" class="d-box align-items-start">

                      <span style="color: red;">&nbsp; &nbsp; &bull; &nbsp; &nbsp;</span>

                      <p class="pr-6">{{subAnswer}}</p>
                    </div>
                  </accordion-group>
                </accordion>
                <div class="text-center">
                  <button *ngIf="faqsLimit<faqs.length" class="btn mt-2" (click)="loadMore()">Mehr laden</button>
                </div>
              </div>

            </div>
          </div>
          <div class="col s12 l6">
            <div class="card mb-4">
              <div class="card-content">
                <h4 class="card-title mb-2">Kontaktformular</h4>
                <div class="row">
                  <form [formGroup]="contactForm" (ngSubmit)="submitContactForm()">
                    <div class="input-field col s12 mb-0">
                      <input id="name" type="text" class="validate" placeholder="Name" formControlName="name">
                      <label for="name">Name</label>
                      <p *ngIf="isEditFieldValid('name')" class="materialize-red-text">{{'validation.contactFormName' | translate}}</p>
                    </div>
                    <div class="input-field col s12 mb-0">
                      <input id="email" type="email" class="validate" placeholder="E-Mail" formControlName="email">
                      <label for="email">E-Mail</label>
                      <p *ngIf="isEditFieldValid('email')" class="materialize-red-text">{{'validation.emailAddress' | translate}}</p>
                    </div>
                    <div class="input-field col s12 mb-0">
                      <input id="phone" type="text" class="validate" placeholder="Telefonnummer" formControlName="phone">
                      <label for="phone">Telefonnummer</label>
                      <p *ngIf="isEditFieldValid('phone')" class="materialize-red-text">{{'validation.mobileNumber' | translate}}</p>
                    </div>
                    <div class="input-field col s12">
                      <textarea id="description" type="text" class="materialize-textarea" placeholder="Beschreibung" formControlName="description"></textarea>
                      <label for="description">Beschreibung</label>
                      <p *ngIf="isEditFieldValid('description')" class="materialize-red-text">{{'validation.description' | translate}}</p>
                    </div>
                    <div class="col s12">
                      <p class="mb-1">Wie dürfen wir Sie kontaktieren?</p>
                      <p>
                        <label>
                          <input class="validate" name="contactType" type="radio" value="phone" formControlName="contactType">
                          <span>Telefon</span>
                        </label>
                      </p>
                      <label>
                        <input class="validate" name="contactType" type="radio" value='email' formControlName="contactType">
                        <span>E-Mail</span>
                      </label>
                      <div class="input-field">
                      </div>
                    </div>
                    <div class="col s12" formGroupName="reasons">
                      <p class="mb-1">Worum geht es?</p>
                      <p>
                        <label>
                          <input type="checkbox" formControlName='status'/>
                          <span>Ist-Status / Dashboard</span>
                        </label>
                      </p>
                      <p>
                        <label>
                          <input type="checkbox" formControlName='bookServices'/>
                          <span>Services buchen</span>
                        </label>
                      </p>
                      <p>
                        <label>
                          <input type="checkbox" formControlName='statistics'/>
                          <span>Statistiken</span>
                        </label>
                      </p>
                      <p>
                        <label>
                          <input type="checkbox" formControlName='settings'/>
                          <span>Service einrichten / Einstellungen</span>
                        </label>
                      </p>
                      <p>
                        <label>
                          <input type="checkbox" formControlName="bills" />
                          <span>Rechnungen</span>
                        </label>
                      </p>
                      <p class="mb-3">
                        <label>
                          <input type="checkbox" formControlName='accountManagement'/>
                          <span>Kontoverwaltung / Mein Konto</span>
                        </label>
                      </p>
                      <button class="btn waves-effect waves-light button-save" [disabled]="contactForm.invalid" type="submit" name="action">Absenden</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 l6">

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->
