<div id="main">
  <div class="row">
    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'calendar.title' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a>
              </li>
              <li class="breadcrumb-item active">{{'calendar.title' | translate}}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12">
      <div class="actions right-align">
        <a class="modal-trigger waves-effect waves-light btn create-contact"
           (click)="openCreateModal()">{{'calendar.createNewEvent' | translate}}</a>
      </div>
      <div class="mt-2 mb-2">
        <full-calendar #fullcalendar [options]="calendarOptions"></full-calendar>
      </div>
    </div>
    <!-- <ng-template #beforeDeleteTemplate>
      <div class="dialog-modal">
        <div class="col-md-12">
          <div class="row">
            <h4 class="pull-left">{{'calendar.chooseDescription' | translate}}</h4>
            <button (click)="closeModal()" type="button" class="close pull-right" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row pb-2">
            <button *ngIf="hasRecurrence" (click)="deleteSeries($event)" type="button"
                    class="btn mr-2"> {{'calendar.deleteSeries' | translate}}</button>
            <button (click)="deleteAppointment($event)" type="button"
                    class="btn">{{'calendar.deleteAppointment' | translate}}</button>
          </div>
        </div>
      </div>
    </ng-template> -->
    <ng-template #beforeEditTemplate>
      <div class="dialog-modal">
        <div class="col-md-12">
          <div class="row">
            <h4 class="pull-left">{{'calendar.chooseDescription' | translate}}</h4>
            <button (click)="closeModal()" type="button" class="close pull-right" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row pb-2">
            <button *ngIf="hasRecurrence" (click)="editSeries($event)" type="button"
                    class="btn mr-2">{{'calendar.editSeries' | translate}}
            </button>
            <button (click)="editAppointment()" type="button"
                    class="btn">{{'calendar.editAppointment' | translate}}</button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
