import {Component, NgZone, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Services} from '../../../services';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {SharedService} from '../../../services/shared.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {HouseholdService} from '../../../services/household.service';
import {UploadService} from '../../../services/upload-service';
import {AuthService} from '../../../auth/services/auth.service';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit {
  householdId;
  users;
  formGroup;
  formData: FormData = new FormData();
  fileToUpload: File = null;
  public imagePath;
  url: string | ArrayBuffer;
  userId;
  imgURL: any;
  image;
  showUserFields = false;
  public message: string;
  filedata: any;
  selected = 1;
  headers = new HttpHeaders();

  imgLink: any;
  imgData: File = null;
  logo: any;
  selectedOption: string;
  fd;
  selectedGender: string;
  selectedFile: File = null;
  selectedGenderId;
  genderValue;
  role = 1;
  phoneNumber = '1232131';
  status = false;
  updated = false;
  responseError;
  imageSrc;
  userData: any;
  avatarExists = false;
  avatarImg;
  imageUploaded = true;
  contactTypes: any = [
    {
      id: 0, name: 'Bitte wählen', disabled: true
    },
    {
      id: 1, name: 'Normalkontakt', disabled: false
    },
    {
      id: 2, name: 'Notfallkontakt', disabled: false
    },
    {
      id: 3, name: 'Administrator', disabled: false
    },
  ];
  genderArray: any = [
    {
      id: 0, name: 'Bitte wählen', state: true
    },
    {
      id: 1, name: 'Frau', state: false
    },
    {
      id: 2, name: 'Herr', state: false
    },
  ];

  constructor(private services: Services,
              public RefEC: BsModalRef,
              private sharedService: SharedService,
              private http: HttpClient,
              private householdService: HouseholdService,
              private zone: NgZone,
              private uploadService: UploadService,
              private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.url = environment.imagesApi;
    this.householdId = localStorage.getItem('household_id');
    // this.userId = localStorage.getItem('user_id');
    this.userId = this.authService.getId();
    if (this.userData.gender == 'female') {
      this.selectedGender = 'Frau';
    } else if (this.userData.gender == 'male') {
      this.selectedGender = 'Herr';
    } else {
      this.selectedGender = this.genderArray[0].name;
    }
    console.log('user data', this.userData);
    if (this.userData.user_type === 'admin') {
      this.selectedOption = this.contactTypes[3].name;
    } else if (this.userData.user_type === 'emergency') {
      this.selectedOption = this.contactTypes[2].name;
    } else if (this.userData.user_type === 'normal') {
      this.selectedOption = this.contactTypes[1].name;
    } else {
      this.selectedOption = this.contactTypes[0].name;
    }
    if (this.userData.id == this.userId) {
      this.showUserFields = true;
      console.log('u are editing user id');
    } else {
      this.showUserFields = false;
    }
    this.editHandler(this.userData);
    this.services.getAlarmUsers(this.householdId).subscribe((data: any) => {
      this.users = data.users;
      console.log(data);
      console.log('users', data);
    });
    console.log('selected gender', this.selectedGender);
  }

  editHandler(dataItem) {
    console.log('data item', dataItem);
    if (dataItem.avatar_img) {
      console.log('has avatar', dataItem.avatar_img);
      this.avatarImg = dataItem.avatar_img;
      this.avatarExists = true;
    }
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id, []),
      // gender: new FormControl(this.selectedGender, [
      //   Validators.required
      // ]),
      // name: new FormControl(dataItem.name, [
      //   Validators.required
      // ]),
      // surname: new FormControl(dataItem.surname, [
      //   Validators.required
      // ]),
      // mobile_number: new FormControl(dataItem.mobile_number, [
      //   Validators.required
      // ]),
      // email: new FormControl(dataItem.email, [
      //   Validators.required,
      //   Validators.email
      // ]),
      user_type: new FormControl(dataItem.user_type),
      // streetname: new FormControl(dataItem.streetname != 'null' ? dataItem.streetname : ''),
      // townname: new FormControl(dataItem.townname != 'null' ? dataItem.townname : ''),
      // housingnumber: new FormControl(dataItem.housingnumber != 0 ? dataItem.housingnumber : ''),
      // zipcode: new FormControl(dataItem.zipcode != 0 ? dataItem.zipcode : ''),
      household_id: new FormControl(this.householdId, [])
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    const file = files[0];

    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;

    reader.readAsDataURL(file);
    this.imageUploaded = true;
  }


  updateUsers() {
    this.sharedService.sendUpdatedData();
  }

  saveHandler() {
    // this.formGroup.patchValue({
    //   // gender: (this.selectedGenderId == 1 ? 'female' : 'male'),
    //   // user_type: this.role == 3 ? 'admin' : this.role == 2 ? 'emergency' : 'normal'
    // });

    // if (this.selectedGender == 'Frau') {
    //   this.selectedGender = 'female';
    // } else if (this.selectedGender == 'Herr') {
    //   this.selectedGender = 'male';
    // }
    const item = {
      id: this.formGroup.value.id,
      // name: this.formGroup.value.name,
      // surname: this.formGroup.value.surname,
      // gender: this.selectedGender == 'Frau' ? 'female' : this.selectedGender == 'Herr' ? 'male' : '',
      // mobile_number: this.formGroup.value.mobile_number,
      // email: this.formGroup.value.email,
      household_id: this.formGroup.value.household_id,
      user_type: this.selectedOption === 'Administrator' ? 'admin' : this.selectedOption === 'Notfallkontakt' ? 'emergency' : 'normal',
      // townname: this.formGroup.value.townname,
      // streetname: this.formGroup.value.streetname,
      // housingnumber: this.formGroup.value.housingnumber,
      // zipcode: this.formGroup.value.zipcode,
    };
    console.log('item', item);
    this.uploadService.postFile(this.fileToUpload, item).subscribe((response) => {
        console.log('response', response);
        this.updateUsers();
        this.closeModal();
      }, error => {
        console.log('error', error);
        this.responseError = error;
        console.log(this.responseError);
      }
    );
  }

  selectOption(id: any) {
    // getted from event
    console.log('id', id);
    this.role = id;
    // getted from binding
    console.log('selected', this.selected);
    this.selectedOption = this.contactTypes[id].name;
  }

  selectGender(id: any) {
    console.log('id', id);
    this.selectedGenderId = id;
    if (id == 0) {
    } else {
      this.selectedGender = this.genderArray[id].name;
    }
  }

  closeModal() {
    this.RefEC.hide();
  }

  isFieldValid(field: string) {
    return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
  }

  removeAvatar() {
    this.avatarExists = false;
    console.log('removing avatar', this.avatarExists);
  }

  addAvatar() {
    if (this.imageUploaded) {
      this.avatarExists = true;
    }
    console.log('removing avatar', this.avatarExists);
  }

  checkImageError() {
    this.avatarExists = false;
  }
}
