import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class UserRegisterService {

  public userData: any;
  private apiPartialEndpoint = environment.api;

  constructor(private http: HttpClient) {
  }


  findUserfromToken(token: any) {
    return this.http.get(this.apiPartialEndpoint + `user-from-token/${token}`).pipe();
  }

  // registerUser(data: any){
  //   return this.http.post(this.apiPartialEndpoint + `update-normal-user`, data).pipe();
  // }
  registerUser(fileToUpload: File, item): Observable<any> {
    console.log('data after saving', fileToUpload);
    const formData: FormData = new FormData();
    if (fileToUpload !== null) {
      formData.append('avatar_img', fileToUpload, fileToUpload.name);
    }
    formData.append('id', item.id);
    formData.append('user_type', item.user_type);
    formData.append('name', item.name);
    formData.append('surname', item.surname);
    formData.append('mobile_number', item.mobile_number);
    formData.append('gender', item.gender);
    formData.append('email', item.email);
    formData.append('token', item.token);
    formData.append('password', item.password);
    formData.append('housingnumber', item.housingnumber);
    formData.append('townname', item.townname);
    formData.append('streetname', item.streetname);
    formData.append('zipcode', item.zipcode);
    return this.http
      .post(`${environment.api}update-normal-user`, formData)
      .pipe(map(response => {
        return response;
      }))
  }

  InvitationAccept(data) {
    return this.http
    .post(`${environment.api}update-existing-user`, data)
    .pipe(map(response => {
      return response;
    }))
  }
}
