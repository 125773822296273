import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  private subject = new Subject<void>();
  private errorMessage = new Subject<void>();
  private householdSelect = new Subject<void>();
  private subjectHistory = new Subject<void>();
  @Output() sidebar: EventEmitter<any> = new EventEmitter();
  invokeEvent: Subject<any> = new Subject();
  sendUpdatedData() {
    this.subject.next();
  }
  getUpdatedData(): Observable<any> {
    return this.subject.asObservable();
  }
  getUpdatedErrorData(): Observable<any> {
    return this.errorMessage.asObservable();
  }
  toggleSidebar() {
    this.sidebar.emit();
  }
  sendUpdatedErrorData() {
    this.errorMessage.next();
  }
  sendUpdatedCalendarData(data) {
    this.subject.next();
  }
  getUpdatedCalendarData(): Observable<any> {
    return this.subject.asObservable();
  }

  sendUpdatedHousehold() {
    this.subject.next();
  }
  getUpdatedHousehold(): Observable<any> {
    return this.subject.asObservable();
  }

  sendUpdatedHistoryData() {
    this.subjectHistory.next();
  }
  getUpdatedHistoryData(): Observable<any> {
    return this.subjectHistory.asObservable();
  }
  sendUpdatedHouseholdSelect(data) {
    this.householdSelect.next(data);
  }
  getUpdatedHouseholdSelect(): Observable<any> {
    return this.householdSelect.asObservable();
  }
  closeModal(type){
    if(type === 'event'){
      this.invokeEvent.next('close-eventChain-modal')
    }
    if(type === 'alarm'){
      this.invokeEvent.next('close-alarmChain-modal')
    } 
  }
  approveChanges(type){
    if(type === 'event'){
      this.invokeEvent.next('accept-eventChain-changes')
    }
    if(type === 'alarm'){
      this.invokeEvent.next('accept-alarmChain-changes')
    }
  }
  start5minCountdown(){
    this.invokeEvent.next('5minLeft')
  }
  stopCountDown(){
    this.invokeEvent.next('userisActive')
  }
}
