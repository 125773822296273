import {TranslateService} from '@ngx-translate/core';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  constructor() {
  }
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
        return 'Jetzt';
      }
      // const intervals = {
      //   Jahr: 31536000,
      //   Monat: 2592000,
      //   Woche: 604800,
      //   Tag: 86400,
      //   Stunde: 3600,
      //   Minute: 60,
      //   Sekunde: 1
      // };
      const intervals = [
        {
          name: 'Jahr', value: 31536000, plural: 'en'
        },
        {
          name: 'Monat', value: 2592000, plural: 'en'
        },
        {
          name: 'Woche', value: 604800, plural: 'n'
        },
        {
          name: 'Tag', value: 86400, plural: 'en'
        },
        {
          name: 'Stunde', value: 3600, plural: 'n'
        },
        {
          name: 'Minute', value: 60, plural: 'n'
        },
        {
          name: 'Sekunde', value: 1, plural: 'n'
        }
      ];
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i].value);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + intervals[i].name + ''; // singular (1 day ago)
          } else {
            return counter + ' ' + intervals[i].name + intervals[i].plural; // plural (2 days ago)
          }
        }
      }
    }
    return value;
  }

}
