<div id="main">
  <div class="row">
    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'manageContacts.title' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a>
              </li>
              <li class="breadcrumb-item active">{{'manageContacts.title' | translate}}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12"  (click)="(alarmStateData === 'alarm' || alarmStateData === 'event') && nAToaster()" >
      <div class="container" [ngClass]="(alarmStateData === 'alarm' || alarmStateData === 'event')? 'disabledContacts' : '' "  >

        <div class="actions right-align mb-1">
          <a class="modal-trigger waves-effect waves-light btn create-contact"
             (click)="createContactModal()">
            {{'manageContacts.createContact.title' | translate}}
          </a>
        </div>
        <div class="row mb-1">
          <div class="col s12 m12 l12">

            <div id="responsive-table-desktop" class="card card card-default contacts hide-on-small-and-down">
              <div class="card-content">
                <div class="row">
                  <div class="col s12">
                    <h4 class="card-title">{{'manageContacts.contactList' | translate}}</h4>
                  </div>
                  <div class="col s12">
                    <table>
                      <thead>
                      <tr>
                        <th data-field="avatar"></th>
                        <th data-field="name">{{'global.name' | translate}}</th>
                        <th data-field="email">{{'global.email' | translate}}</th>
                        <th data-field="phone">{{'global.telephone' | translate}}</th>
                        <th data-field="approval">{{'global.release' | translate}}</th>
                        <th data-field="app">{{'global.app' | translate}}</th>
                        <th data-field="role">{{'global.role' | translate}}</th>
                        <th data-field="actions" class="right-align">{{'global.action' | translate}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let user of users">
                        <td>
                          <!-- <img *ngIf="user.avatar_path === null || user.avatar_path === undefined || user.avatar_path === imagesApi"
                               class="avatar user-icon" src="assets/images/avatar/no-image.png" alt="avatar"
                               class="avatar user-icon"/>
                          <img *ngIf="user.avatar_path == imagesApi + 'female-avatar'"
                               class="avatar user-icon" src="assets/images/avatar/female-avatar.png" alt="avatar"
                               class="avatar user-icon"/> -->
                          <img *ngIf="user.avatar_path !== null && user.avatar_path !== undefined && user.avatar_path !== ''"
                               onError="this.src='../../assets/images/avatar/no-image.png'" class="avatar user-icon"
                               src="{{user.avatar_path}}" alt="avatar"
                               class="avatar user-icon"/>
                        </td>
                        <td>{{user.name}} {{user.surname}}</td>
                        <td class="data-field-email">{{user.email}}</td>
                        <td class="data-field-phone">{{user.mobile_number}}</td>
                        <td><i class="material-icons notranslate green-text">check</i></td>
                        <td>
                          <i class="material-icons notranslate"
                             [ngClass]="user.fcm_token ? 'green-text' : 'red-text'">
                            {{user.fcm_token ? 'check' : 'close'}}</i>
                        </td>
                        <td>{{user.user_type === 'admin' ? 'Administrator' : user.user_type === 'normal' ? 'Normalkontakt' : 'Notfallkontakt'}}</td>
                        <td class="right-align">
                          <a style="cursor: pointer" class="modal-trigger"
                             (click)="(alarmStateData !== 'alarm' && alarmStateData !== 'event') && editContactModal(user)">
                            <i class="material-icons notranslate grey-text">edit</i>
                          </a>
                          <button class="btn-delete" (click)="(alarmStateData !== 'alarm' && alarmStateData !== 'event') && deleteUser(user)">
                            <i class="material-icons notranslate">delete</i>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div id="responsive-table-mobile" class="card card card-default contacts hide-on-med-and-up">
              <div class="card-content">
                <div class="row">
                  <div class="col s12">
                    <h4 class="card-title">{{'manageContacts.contactList' | translate}}</h4>
                  </div>
                  <div class="col s12">
                    <table class="no-border">
                      <tbody>
                      <tr *ngFor="let user of users">
                        <td>
                          <div class="actions-custom">
                            <a class="modal-trigger" (click)="editContactModal(user)">
                              <i class="material-icons notranslate grey-text">edit</i>
                            </a>
                            <a (click)="deleteUser(user)">
                              <i class="material-icons notranslate">delete</i>
                            </a>
                          </div>
                          <div class="data">
                            <!-- <img *ngIf="user.avatar_path === null || user.avatar_path === undefined || user.avatar_path == imagesApi"
                                 class="avatar user-icon-mobile" src="assets/images/avatar/no-image.png" alt="avatar"
                                 class="avatar user-icon-mobile"/>
                            <img *ngIf="user.avatar_path == imagesApi + 'female-avatar'"
                                 class="avatar user-icon-mobile" src="assets/images/avatar/female-avatar.png" alt="avatar"
                                 class="avatar user-icon-mobile"/> -->
                            <img *ngIf="user.avatar_path !== null && user.avatar_path !== undefined"
                                 onError="this.src='../../assets/images/avatar/no-image.png'"
                                 class="avatar user-icon-mobile"
                                 src="{{user.avatar_path}}" alt="avatar"
                                 class="avatar user-icon-mobile"/>
                            <!--                            <img src="app-assets/images/avatar/opa-karl.png" alt="avatar" class="avatar" />-->
                            <br>
                            <div class="mt-2" style="margin-bottom: 10px">
                              <b>{{user.name}} {{user.surname}}</b> {{user.user_type === 'admin' ? ' (Administrator)' : user.user_type === 'normal' ? ' (Normalkontakt)' : ' (Notfallkontakt)'}}
                            </div>
                            {{'global.email' | translate}}: <b>{{user.email}}</b><br>
                            {{'global.telephone' | translate}}: <b>{{user.mobile_number}}</b><br>
                            {{'global.release' | translate}}: <i class="material-icons notranslate green-text">check</i>
                            {{'global.app' | translate}}: <i
                            class="material-icons notranslate"
                            [ngClass]="user.fcm_token ? 'green-text' : 'red-text'">
                            {{user.fcm_token ? 'check' : 'close'}}</i>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
