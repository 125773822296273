<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">
    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'myData.title' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a>
              </li>
              <li class="breadcrumb-item active">{{'myData.title' | translate}}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12" (click)="userActiveAlarm && nAToaster()">
      <div class="container" [ngClass]="(userActiveAlarm)? 'disableChanges' : '' "  >
        <div class="row mb-1 mt-1">
          <div class="col s12">
            <div class="card mb-2 personal-information">
              <div class="card-content">
                <h4 class="card-title mb-1">{{'myData.personalData' | translate}}</h4>

                <div [ngStyle]="{'visibility': !showAvatar ? 'hidden' : 'visible'}" class="input-field col-md-5 col-sm-4">
                  <input (change)="handleFileInput($event.target.files)" (click)="stopPropagation($event)" type="file" id="input-file-now2" data-show-remove="false" data-height="200" class="dropify"/>
                </div>
                <form [formGroup]="myDataForm" *ngIf="myDataForm">
                 <div class="row">
                   <div class="col l4 m6 s12 mb-2">

                   </div>
                 </div>

                  <div class="row">
                    <div class="input-field col m6 s12">
                      <input id="name" type="text" formControlName="name" required>
                      <label for="name" class="active">{{'global.firstName' | translate}}</label>
                      <p *ngIf="isEditFieldValid('name')" class="materialize-red-text">{{'validation.name' | translate}}</p>
                    </div>
                    <div class="input-field col m6 s12">
                      <input id="surname" type="text" formControlName="surname" required>
                      <label for="surname" class="active">{{'global.lastName' | translate}}</label>
                      <p *ngIf="isEditFieldValid('surname')" class="materialize-red-text">{{'validation.surname' | translate}}</p>

                    </div>
                  </div>

                  <div class="row">
                    <div class="input-field col m6 s12">
                      <input id="email" type="email" formControlName="email" required>
                      <label for="email" class="active">{{'global.email' | translate}}</label>
                      <p *ngIf="isEditFieldValid('email')" class="materialize-red-text">{{'validation.emailAddress' | translate}}</p>

                    </div>
                    <div class="input-field col m6 s12">
                      <input id="mobile_number" type="text" formControlName="mobile_number" required>
                      <label for="mobile_number" class="active">{{'global.telephone' | translate}}</label>
                      <p *ngIf="isEditFieldValid('mobile_number')" class="materialize-red-text">{{'validation.mobileNumber' | translate}}</p>

                    </div>
                    <div class="col s12">
                      <button
                        class="btn waves-effect waves-light button-save"
                        type="submit"
                        name="action"
                        [disabled]="!myDataForm.valid"
                        (click)="!userActiveAlarm && editUser()">
                        {{'global.save' | translate}}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="card reset-password">
              <div class="card-content">
                <h4 class="card-title mb-1">{{'global.changePassword' | translate}}</h4>
                <form [formGroup]="passFormGroup">

                  <div class="row">
                    <div class="input-field col m6 s12">
                      <input placeholder="{{'global.oldPassword' | translate}}" id="password" type="password" formControlName="password" (input)="currentPassword = true" autocomplete="password" required>
<!--                      <label for="password">Altes Passwort</label>-->
                      <p *ngIf="isPassFieldValid('password')" class="materialize-red-text">{{'validation.writePassword' | translate}}</p>
                      <p *ngIf="!currentPassword" class="materialize-red-text">{{'validation.wrongPassword' | translate}}</p>
                      
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col m6 s12">
                      <input placeholder="{{'global.newPassword' | translate}}" (keydown)="resetConfirm()" id="new_password" type="password" autocomplete="new_password" formControlName="new_password" required>
<!--                      <label for="new_password">Neues Passwort</label>-->
                      <!-- <p *ngIf="isPassFieldValid('new_password')" class="materialize-red-text">{{'validation.newPassword' | translate}}</p> -->
                      <p *ngIf="isPassFieldCurrentPass('new_password')" class="materialize-red-text">{{'validation.samePassword' | translate}}</p>
                      <p *ngFor="let validation of password_validation_messages">
                        <span *ngIf="passFormGroup.get('new_password').hasError(validation.type) && (passFormGroup.get('new_password').touched)" class="materialize-red-text">{{'validation.'+validation.type | translate}}</span>
                      </p>
                      <small *ngIf="passFormGroup.get('new_password').hasError('required') && (passFormGroup.get('new_password').touched)"><b>{{'validation.passInfo' | translate}}</b></small>

                    </div>
                    <div class="input-field col m6 s12">
                      <input placeholder="{{'global.confirmNewPassword' | translate}}" id="retype_password" type="password" autocomplete="retype_password" formControlName="retype_password" required>
<!--                      <label for="retype_password">Neues Passwort bestätigen</label>-->
                      <p *ngIf="isPassConfirmFieldValid('retype_password')" class="materialize-red-text">{{'validation.repeatPassword' | translate}}</p>

                    </div>
                    <div class="col s12">
                      <button
                        class="btn waves-effect waves-light button-save"
                        type="submit"
                        name="action"
                        [disabled]="!passFormGroup.valid"
                        (click)="!userActiveAlarm && changePassword()">
                        {{'global.changePassword' | translate}}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <button *ngIf="!isOwner()" (click)="!userActiveAlarm && removeAccountFromHousehold()" class="btn waves-effect waves-light button-save">{{'myData.removeAccountFromHousehold' | translate}}</button>
              <button *ngIf="!ownsHousehold" (click)="removeAccountFully()" class="btn waves-effect waves-light button-save">{{'myData.removeAccountFully' | translate}}</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->
