import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Role } from './models/role';
import {HouseholdService} from './services/household.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private householdService: HouseholdService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canActivate')
    if (!this.householdService.isAuthorized()) {
      console.log('canActivate not authorized')
      this.router.navigate(['meine-daten']);
      return false;
    }

    const roles = route.data.roles as Role[];
    if (roles && !roles.some(r => this.householdService.hasRole(r))) {
      console.log('canActivate not authorized2')
      this.router.navigate(['error', 'not-found']);
      return false;
    }
    console.log('canActivate authorized')

    return true;
  }

  // canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
  //   if (!this.authService.isAuthorized()) {
  //     return false;
  //   }
  //
  //   const roles = route.data && route.data.roles as Role[];
  //   if (roles && !roles.some(r => this.authService.hasRole(r))) {
  //     return false;
  //   }
  //
  //   return true;
  // }
}
