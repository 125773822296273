import {Component, NgZone, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CreateEventComponent} from '../create-event/create-event.component';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {CalendarService} from '../../../services/calendar.service';
import {SharedService} from '../../../services/shared.service';
import {EditEventComponent} from '../edit-event/edit-event.component';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../auth/services/auth.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Calendar} from '@fullcalendar/core';
import { CalendarOptions, EventDropArg, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import deLocale from '@fullcalendar/core/locales/de';
import * as moment from 'moment';

declare var require: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  @ViewChild('beforeDeleteTemplate')
  private beforeDeleting: TemplateRef<any>;
  @ViewChild('beforeEditTemplate')
  private beforeEditing: TemplateRef<any>;
  calendarData;
  // eslint-disable-next-line @typescript-eslint/ban-types
  public allowMultipleOwner: Boolean = true;
  dataLoaded = false;
  hasRecurrence = false;
  currentId;
  recurrenceType;
  selectedDate = new Date();
  dataPassed;
  householdId;
  householdData;
  firstTime = false;
  description;
  subject;
  inputHtmlField = '';
  @ViewChild("fullcalendar", { static: false })
  calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions
  locales = [deLocale];
  dateInputEl

  constructor(private calendarService: CalendarService,
              private modalRef: BsModalRef, private modalDialog: BsModalRef,
              private modalService: BsModalService,
              private datePipe: DatePipe,
              private sharedService: SharedService,
              private http: HttpClient,
              private translate: TranslateService,
              private authService: AuthService,
              private sanitizer: DomSanitizer
  ) {
    const name = Calendar.name;
  }

  ngOnInit() {
    this.setupCalendar()
    // this.inputHtmlField = `>\'>"><img src=x onerror=alert("HELLO")>`
    this.sharedService.getUpdatedHousehold().subscribe(() => {
      window.location.reload();
    });
    this.householdId = localStorage.getItem('household_id');
    this.firstTime = true;
    this.sharedService.getUpdatedCalendarData().subscribe(response => {
      this.firstTime = false;
      this.dataLoaded = false;
      setTimeout(() => {
        this.dataLoaded = true;
      }, 2000);
      this.getCalendarData();
    });
    this.getCalendarData();
    if (this.dataLoaded) {
    }
  }

  ngAfterViewInit(){
    const calendarTitleEl = document.getElementsByClassName('fc-toolbar-title')[0]
    console.log(calendarTitleEl)
    const html = `
      <input type='month' id='date_picker'>
      `
    calendarTitleEl.insertAdjacentHTML("afterbegin",html)
    this.dateInputEl = document.getElementById('date_picker')
    console.log('1111', this.dateInputEl)
    this.dateInputEl.addEventListener('change', this.changeMonth.bind(this))
  }
  changeMonth(){
    console.log(this.dateInputEl.value)
    console.log(new Date(this.dateInputEl.value))
    this.calendarComponent.getApi().gotoDate(this.dateInputEl.value)
  }
  openCreateModal(data = new Date()) {
    data = new Date(new Date(data.setHours(new Date().getHours())).setMinutes(new Date().getMinutes()))
    const initialState = {
      startDate:data,
      endDate:data
    }
    this.modalRef = this.modalService.show(CreateEventComponent, {initialState});
  }

  onCreated(e) {
    console.log('on created', e);
  }

  getCalendarData = () => {
    this.calendarService.getCalendarData(this.householdId).subscribe((res: any) => {
      this.dataLoaded = true;
      console.log(res)
      // if (!this.firstTime) {
      //   this.selectedDate = new Date(
      //     this.formatDate(res.events[res.events.length - 1].obs_intervall_start).year.toString(),
      //     Number(this.formatDate(res.events[res.events.length - 1].obs_intervall_start).month),
      //     this.formatDate(res.events[res.events.length - 1].obs_intervall_start).day.toString(),
      //     this.formatDate(res.events[res.events.length - 1].obs_intervall_start).hour.toString(),
      //     this.formatDate(res.events[res.events.length - 1].obs_intervall_start).minutes.toString())
      //   // this.scheduleObj.scrollTo('12:01', new Date());
      // }
      // this.pushDataToCalendar(res.events);

      res.events.map(event => {
           // check desc and title to dispay in calendar
      if (event.title != '' && event.description != '') {
        this.subject = event.title;
        this.description = event.description;
      } else if (event.title !== '' && event.description === '') {
        this.subject = event.title;
        this.description = this.translate.instant('global.noDescription');
      } else if (event.description !== '' && event.title === '') {
        this.subject = this.translate.instant('global.noDescription');
        this.description = event.description;
      } else if (event.title === '' && event.description === ''){
        this.subject = this.translate.instant('global.noDescription');
        this.description = this.translate.instant('global.noDescription');
      }
        
        //event.recurrence = event.recurrence,
        event.recurrenceCount = event.recurrence_count,
        event.Id = event.id,
        event.Subject = this.subject,
        event.description = this.description,
        event.isOwnerHome = event.is_owner_home,
        event.StartTime = 
          new Date(
            this.formatDate(event.obs_intervall_start).year.toString(),
            Number(this.formatDate(event.obs_intervall_start).month),
            this.formatDate(event.obs_intervall_start).day.toString(),
            this.formatDate(event.obs_intervall_start).hour.toString(),
            this.formatDate(event.obs_intervall_start).minutes.toString()),
        event.EndTime = new Date(
          this.formatDate(event.obs_intervall_end).year.toString(),
          Number(this.formatDate(event.obs_intervall_end).month),
          this.formatDate(event.obs_intervall_end).day.toString(),
          this.formatDate(event.obs_intervall_end).hour.toString(),
          this.formatDate(event.obs_intervall_end).minutes.toString()
        )

        event.id = event.Id
        event.start = event.StartTime
        event.end = event.EndTime
        
        let timebetweenStartEnd = Math.abs(new Date(event.obs_intervall_end).getTime() - new Date(event.obs_intervall_start).getTime())
        let minutes:any = timebetweenStartEnd/(1000*60)
        const hours = Math.floor(minutes/60)
        minutes = minutes - hours*60
        event.milisecDuration = timebetweenStartEnd
        event.hours = hours
        minutes = ("0" + minutes).slice(-2)
      
        if(hours <= 24){
          event.duration = '24:00'
        }else{
          event.duration = hours.toString()+':' + minutes
        }
        console.log(event.duration, event.recurrenceCount)
        if(event.start.getDate() == event.end.getDate()){
          event.allDay = true
          event.title = event.StartTime.getHours() +":"+ event.StartTime.getMinutes() +" "+event.Subject
        }else {
          event.title = event.Subject
        }
        if(event.recurrence_rule){
          event.rrule = event.recurrence_full_rule 
        }
       
      })
      this.calendarOptions.events = res.events
      console.log(res.events)
    }, err => {
      console.log(err);
    });
  }

  openDialogBox(template: TemplateRef<any>, data: any) {
    const initialState = {
      data
    }
    this.checkEventType(data);
    this.currentId = data.Id;
    this.recurrenceType = data.recurrence;
    this.dataPassed = data;
    if(data.recurrence !== 'none' && data.recurrence !== ''){
      this.modalDialog = this.modalService.show(template, {initialState});
    }
    else{
      this.editAppointment()
    }
  }

  checkEventType(data) {
    if (data.recurrence !== 'none' && data.recurrence !== '') {
      this.hasRecurrence = true;
    } else {
      this.hasRecurrence = false
    }
  }

  // onPopupOpen(args): void {
  //   console.log(args);
  //   const argsData: any = args;
  //   if (args.type === 'QuickInfo') {
  //     if (!argsData.data.Id) {
  //       args.cancel = true;
  //     }
  //   }
  //   if (args.type === 'Editor') {
  //     if (argsData.data.Id) {
  //       args.cancel = true;
  //       this.openDialogBox(this.beforeEditing, args.data);
  //     } else {
  //       args.cancel = true;
  //     }
  //   } else if (args.type === 'DeleteAlert') {
  //     args.cancel = true;
  //     this.openDialogBox(this.beforeDeleting, args.data);
  //   } else {
  //   }
  // }
  escapeRegExp(s) {
    if (s) {
      return s.replace(/[*.+?^${}()<>|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
  }

  formatDate(date) {
    let month = (date.substring(5, 7));
    if (month < 10) {
      month = (date.substring(6, 7)) - 1;
    } else {
      month = ((date.substring(5, 7))) - 1;
    }
    const data = {
      year: date.substring(0, 4),
      month: month.toString(),
      day: date.substring(8, 10),
      hour: date.substring(11, 13),
      minutes: date.substring(14, 16)
    }
    return data;
  }

  // pushDataToCalendar(calendarData) {
  //   this.eventSettings.dataSource = calendarData;
  //   for (const x in calendarData) {

  //     // check desc and title to dispay in calendar
  //     if (calendarData[x].title != '' && calendarData[x].description != '') {
  //       this.subject = this.escapeRegExp(calendarData[x].title);
  //       this.description = this.escapeRegExp(calendarData[x].description);
  //     } else if (calendarData[x].title !== '' && calendarData[x].description === '') {
  //       this.subject = this.escapeRegExp(calendarData[x].title);
  //       this.description = this.translate.instant('global.noDescription');
  //     } else if (calendarData[x].description !== '' && calendarData[x].title === '') {
  //       this.subject = this.translate.instant('global.noDescription');
  //       this.description = this.escapeRegExp(calendarData[x].description);
  //     } else if (calendarData[x].title === '' && calendarData[x].description === ''){
  //       this.subject = this.translate.instant('global.noDescription');
  //       this.description = this.translate.instant('global.noDescription');
  //     }

  //     this.eventSettings.dataSource[x] =
  //       {
  //         recurrence: calendarData[x].recurrence,
  //         recurrenceCount: calendarData[x].recurrence_count,
  //         Id: calendarData[x].id,
  //         Subject: this.subject,
  //         description: this.description,
  //         isOwnerHome: calendarData[x].is_owner_home,
  //         StartTime:
  //           new Date(
  //             this.formatDate(calendarData[x].obs_intervall_start).year.toString(),
  //             Number(this.formatDate(calendarData[x].obs_intervall_start).month),
  //             this.formatDate(calendarData[x].obs_intervall_start).day.toString(),
  //             this.formatDate(calendarData[x].obs_intervall_start).hour.toString(),
  //             this.formatDate(calendarData[x].obs_intervall_start).minutes.toString()),
  //         EndTime: new Date(
  //           this.formatDate(calendarData[x].obs_intervall_end).year.toString(),
  //           Number(this.formatDate(calendarData[x].obs_intervall_end).month),
  //           this.formatDate(calendarData[x].obs_intervall_end).day.toString(),
  //           this.formatDate(calendarData[x].obs_intervall_end).hour.toString(),
  //           this.formatDate(calendarData[x].obs_intervall_end).minutes.toString()
  //         )
  //       }
  //     // this.scheduleObj.addEvent(data);
  //     // this.scheduleObj.addEvent(this.eventSettings.dataSource);
  //   }
  // }

  editSeries(e) {
    this.closeModal();
    this.openEditModal('series');
  }

  editAppointment() {
    //this.closeModal();
    this.openEditModal('appointment');
  }

  openEditModal(type) {
    const initialState = {
      data: this.dataPassed,
      type
    }
    this.modalRef = this.modalService.show(EditEventComponent, {initialState});
  }

  deleteAppointment(e) {
    const recurrence = this.recurrenceType;
    const data = {
      recurrence: 'none'
    }
    let opt = {
      headers: {},
      params: {
        recurrence: 'none'
      }
    }
    this.http.delete(`${environment.api}calendar-events/${this.currentId}`, opt).subscribe(res => {
      this.sharedService.sendUpdatedData();
    });
    this.closeModal();
  }

  deleteSeries(e) {
    const recurrence = this.recurrenceType;
    const data = {
      recurrence
    }
    this.http.delete(`${environment.api}calendar-events/${this.currentId}`, recurrence).subscribe(res => {
      this.sharedService.sendUpdatedData();
    });
    this.closeModal();
  }

  // onDragStart(args: DragEventArgs): void {
  //   // this.groupIndex = parseInt(<string>args.data.TaskId);
  // }

  onDragStop(args: EventDropArg): void {
    if(args.event.start == args.oldEvent.start){
      return
    }
    const userId = this.authService.getId();
    const start = args.event.start.setHours(args.oldEvent._def.extendedProps.StartTime.getHours(), args.oldEvent._def.extendedProps.StartTime.getMinutes())
    let end
    if(args.event.allDay){
      end = args.event.start.setHours(args.oldEvent._def.extendedProps.EndTime.getHours(), args.oldEvent._def.extendedProps.EndTime.getMinutes())
    }else {
      end = args.event.end.setHours(args.oldEvent._def.extendedProps.EndTime.getHours(), args.oldEvent._def.extendedProps.StartTime.getMinutes())
    }
    const data = {
      title: args.oldEvent._def.extendedProps.Subject.toString(),
      description: args.oldEvent._def.extendedProps.description,
      obs_intervall_start: this.datePipe.transform(start, 'yyyy-MM-dd HH:mm'),
      obs_intervall_end: this.datePipe.transform(end, 'yyyy-MM-dd HH:mm'),
      recurrence: 'none',
      recurrence_count: 0,
      location: 1,
      art: 1,
      all_day_flag: 1,
      event_at_home_flag: 1,
      user_id: userId,
      household_id: this.householdId,
      is_owner_home: 1,
    };
    this.calendarService.updateCalendarData(args.event.id, data).subscribe(res => {
    });
  }

  // onRenderCell(args: RenderCellEventArgs): void {
  //   // args.element.classList.contains('redColo');
  //   // if (args.element.classList.contains('e-work-cells')) {
  //   //   if (args.date < new Date(2018, 6, 31, 0, 0)) {
  //   //     args.element.setAttribute('aria-readonly', 'true');
  //   //     args.element.classList.add('e-read-only-cells');
  //   //   }
  //   // }
  //   // if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
  //   //   let target: HTMLElement = args.element.querySelector('.e-resource-text') as HTMLElement;
  //   //   target.innerHTML = '<div class="name">Rooms</div><div class="type">Type</div><div class="capacity">Capacity</div>';
  //   // }
  // }

  // onEventRendered(args: EventRenderedArgs): void {
  //   args.element.style.backgroundColor = '#dd3333';
  //   // let data: { [key: string]: Object } = args.data;
  //   // if (this.isReadOnly(data.EndTime as Date)) {
  //   //   args.element.setAttribute('aria-readonly', 'true');
  //   //   args.element.classList.add('e-read-only');
  //   // }
  // }

  closeModal() {
    console.log(this.modalDialog)
    this.modalDialog.hide();
  }

  setupCalendar(){
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      locale:deLocale,
      firstDay: 0,
      eventBackgroundColor: '#dd3333',
      headerToolbar:{
        left: 'prev,next,title',
        center: '',
        right: 'today'
      },
      dayMaxEvents: true,
      
      //editable: true,
      // eventStartEditable:true,
      // eventDurationEditable: false,
      // eventDragStart:(arg)=>{console.log('drag started', arg)},
      // eventDragStop:(info)=>{console.log('drag stopped', info)},
      // eventDrop:(args)=>this.onDragStop(args),
      // eventAllow:(dropInfo, draggedEvent)=>{
      //   console.log('doni', new Date(dropInfo.start).getTime()== new Date(draggedEvent.start).getTime() )
      //   return new Date(dropInfo.start).getTime()!= new Date(draggedEvent.start).getTime() 
      // },
      height:800,
      eventClick:(info)=>{
        let eventDate = new Date(((info.jsEvent.target as any).closest("td") as any).getAttribute("data-date"))
        const eventInfoo = this.calendarComponent.getApi().getEventById(info.event.id)._def.extendedProps
        const eventInfo = {...eventInfoo}
        const eventStartHour = eventInfo.StartTime.getHours()
        const eventStartMinutes = eventInfo.StartTime.getMinutes()
        const eventEndHour = eventInfo.EndTime.getHours()
        const eventEndMinutes = eventInfo.EndTime.getMinutes()
  
        eventInfo.StartTime = eventDate.setHours(eventStartHour)
        eventInfo.StartTime = eventDate.setMinutes(eventStartMinutes)
        eventInfo.StartTime = new Date(eventInfo.StartTime)
        eventInfo.EndTime = new Date(eventInfo.StartTime.getTime() + eventInfo.milisecDuration)
        //eventInfo.EndTime = eventDate.setMinutes(eventEndMinutes)
        eventInfo.EndTime = new Date(eventInfo.EndTime)
        eventInfo.clicked_date = new Date(eventInfo.StartTime)
        this.openDialogBox(this.beforeEditing, eventInfo);
      },
      
      dayHeaderFormat: (args) => {
        return moment(args.date).locale("de").format('dddd')
      },
      dayHeaderDidMount: (args)=>{
        if(args.date.getDay() == new Date().getDay()){
          args.el.classList.add('today')
        }
      },
      dateClick:(arg)=>{
        this.openCreateModal(arg.date)
      }
    };
  }
}
