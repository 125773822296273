// src/app/auth/auth.guard.ts
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {HouseholdService} from '../../services/household.service';
import {Observable} from 'rxjs';
import {Role} from '../../models/role';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public authService: AuthService, public router: Router, private householdService: HouseholdService) {
  }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const currentUser = this.householdService.currentUserValue;
  //   if (currentUser) {
  //     // check if route is restricted by role
  //     if (route.data.roles && route.data.roles.indexOf(currentUser) === -1) {
  //       // role not authorised so redirect to home page
  //       this.router.navigate(['/']);
  //       return false;
  //     }
  //
  //     // authorised so return true
  //     return true;
  //   }
  //
  //   // not logged in so redirect to login page with the return url
  //   this.router.navigate(['/login'], );
  //   return false;
  // }
  // canActivate(): boolean {
  //   if (!this.authService.isLoggedIn()) {
  //     this.router.navigate(['/login']);
  //   }
  //   return this.authService.isLoggedIn();
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }
    return this.authService.isLoggedIn();
  }
  // canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
  //   console.log('can loadLOADLOAD', route);
  //   if (!this.authService.isLoggedIn()) {
  //     return false;
  //   }
  //
  //   const roles = route.data && route.data.roles as Role[]
  //   console.log('roles asd', roles);
  //   if (roles && !roles.some(r => this.householdService.hasRole(r))) {
  //     return false;
  //   }
  //
  //   return true;
  // }
}
