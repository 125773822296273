import {Component, OnInit} from '@angular/core';
import {AlarmService} from 'src/app/services/alarm.service';
import * as Highcharts from 'highcharts';
import {DatePipe} from '@angular/common';
import {SharedService} from '../../services/shared.service';


@Component({
  selector: 'app-statistiken',
  templateUrl: './statistiken.component.html',
  styleUrls: ['./statistiken.component.css']
})
export class StatistikenComponent implements OnInit {
  highcharts = Highcharts;
  date = new Date();
  fromDate;
  toDate;
  showGraph = true;
  type = 'get-admin-consumption';
  maxDate = new Date();
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  currentDay = new Date().getDay();
  currentHour = new Date().getHours();
  dataEnergy;
  dataMotion;
  dataTemperature;
  dataHumidity;
  dataSocialHannah;
  dataSocialApp;
  socialHannahIntervallStart = [];
  socialAppIntervallStart = [];
  temperatureIntervallStart = [];
  humidityIntervallStart = [];
  socialScoringIntervallStart = [];
  motionIntervallStart = [];
  energyIntervallStart = [];
  waterIntervallStart = [];
  dataWater;
  currentMinute = this.date.getMinutes();
  hour = 4500 * 1000;
  graphTitle = 'consumption';
  graphSubtitle = 'electricityAndWater';
  selected = true;
  updateChart1 = false;
  times = [];
  statisticsTabs = [
    {
      id: 0,
      title: 'consumption',
      subtitle: 'electricityAndWater',
      selected: true

    },
    {
      id: 1,
      title: 'environment',
      subtitle: 'movement',
      selected: false
    },
    {
      id: 2,
      title: 'environment',
      subtitle: 'temperatureAndHumidity',
      selected: false
    },
    {
      id: 3,
      title: 'interaction',
      subtitle: 'appAndBaseStation',
      selected: false
    }
  ]


  chartOptions = {
    title: {
      text: '',
    },
    chart: {
      zoomType: 'x',
      // height: (9 / 16 * 100) + '%',
    },
    credits: {
      enabled: false
    },
    yAxis: [
      {
        title: {
          text: '',
          style: {
            color: 'red',
          }
        },
        labels: {
          enabled: true
        }
      },
      {
        opposite: true,
        title: {
          text: '',
          style: {
            color: 'blue',
          }
        },
        labels: {
          enabled: true
        }
      }],
    legend: {
      enabled: false
    },

    xAxis: {
      // categories: ['00:15', '00:30', '00:45', '1:00', '1:15', '1:30', '1:45', '2:00'],
      categories: [],
      // min: 0,
      // max: 3,
      labels: {
        // y: 19,
        // align: 'left',
        style: {
          color: '#dd3333',
          // fontWeight: 'normal',
          // fontSize: '11px',
          // whiteSpace: 'wrap'
        }
      },
      // lineColor: 'transparent',
      showLastLabel: false,
      tickmarkPlacement: 'on',
      tickPosition: 'inside',
      tickWidth: 0,
      tickPixelInterval: 60,
      minPadding: 0,
      gridLineWidth: 0,
      // offset: 35,
      startOnTick: true,
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%H:%M'
      },
      endOnTick: true
    },
    // tooltip: {
    //   valueSuffix: `<br>Something else: ${this.countDots}`
    // },
    series: [
      {
        type: 'line',
        // pointStart: 1,
        // pointStart: Date.UTC(2020,10,1,18,0,0),
        // pointStart: Date.UTC(this.currentYear, this.currentMonth, this.currentDay - 1, this.currentHour, 0, 0),
        // pointInterval: this.hour,
        name: 'Strom',
        marker: {enabled: true, symbol: 'circle'},
        data: [],
        color: 'red',
        id: 1
      },
      {
        type: 'line',
        // pointInterval: this.hour,
        // pointStart: [this.energyIntervallStart],
        name: 'Wasser',
        marker: {enabled: true, symbol: 'circle'},
        data: [],
        color: 'blue',
        yAxis: 1,
        id: 2
      }
    ],

  };
  datePickerConfig = {
    dateInputFormat: 'DD.MM.YYYY',
    containerClass: 'theme-blue',
    isAnimated: true,
    adaptivePosition: true,
    showTodayButton: true,
  };
  householdId;
  showChart = false;
  stats;
  bsValue = new Date();
  buttonsTexts: any;


  constructor(private alarmService: AlarmService,
              private datePipe: DatePipe, private sharedService: SharedService) {
  }

  ngOnInit() {
    const qt = ['00', '15', '30', '45'];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        let time = i + ':' + qt[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.times.push(time);
      }
    }
    this.chartOptions.xAxis.categories = this.times;
    this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.householdId = localStorage.getItem('household_id');
      this.getTodayData();
    });
    this.householdId = localStorage.getItem('household_id');
    this.getTodayData();
    // this.fromDate.setDate(this.fromDate.getDate() - 1);
    // this.convertedFromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd hh:mm:ss')
    // this.convertedToDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd hh:mm:ss')
    // console.log('from date: ', this.convertedFromDate);
    // this.getConsumption(this.householdId, this.convertedFromDate, this.convertedToDate);
  }
  getTodayData() {
    this.fromDate = this.date;
    const todayDate = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.getConsumption(this.householdId, todayDate, this.toDate, this.type)
  }
  getConsumption(hid, fromDate, toDate, type) {
    console.log('form data', fromDate);
    hid = this.householdId;
    this.alarmService.getConsumption(hid, fromDate, toDate, type).subscribe((data: any) => {
      console.log('data in get consumption', data);
      // this.stats = data;
      if (this.type === 'get-admin-consumption') {
        this.calcEnergyWater(data);
      } else if (this.type === 'get-motion-data') {
        this.calcMotion(data)
      } else if (this.type === 'get-temp-hum') {
        this.calcTemperatureHumidity(data)
      } else if (this.type === 'get-social-score-data') {
        this.calcSocialScore(data);
      }
    }, error => {
      console.log(error);
      this.updateChart1 = true;
      this.showGraph = false;
    });
  }

  calcEnergyWater = (data) => {
    this.dataEnergy = data.energy;
    this.dataWater = data.water;
    console.log('graph data', data);
    const chartData = {
      electricity: [],
      water: [],
    };
    if (data.energy.length !== 0 && data.water.length !== 0) {
      let energyValue = null;
      for (const x in data.energy) {
        energyValue = +data.energy[x].consumption * 1000
      }
      let waterValue = null;
      for (const x in data.water) {
        waterValue = +data.water[x].consumption * 1000
      }
      if (!isNaN(energyValue)) {
        const energyData = {
          startDate: this.dataEnergy[0].obs_intervall_start,
          endDate: this.fromDate
        }
        for (const x in this.dataEnergy) {
          // chartData.electricity.push(Math.round((this.dataEnergy[x].consumption + Number.EPSILON) * 100) / 100);
          // if (this.dataEnergy[x].id) {
            chartData.electricity.push(this.dataEnergy[x].consumption);
          // }
          if (this.calculateDiff(energyData) <= 0) {
            this.energyIntervallStart[x] = this.datePipe.transform(this.dataEnergy[x].obs_intervall_start, 'HH:mm');
          } else if ((this.calculateDiff(energyData) > 0) && (this.calculateDiff(energyData) <= 4380)) {
            this.energyIntervallStart[x] = this.datePipe.transform(this.dataEnergy[x].obs_intervall_start, 'dd.MM HH:mm');
          } else if (this.calculateDiff(energyData) >= 4380) {
            this.energyIntervallStart[x] = this.datePipe.transform(this.dataEnergy[x].obs_intervall_start, 'dd.MM.yyyy');
          }
        }
        console.log('chart data electricity', chartData.electricity);
        this.chartOptions.yAxis[0].title.text = 'Strom in Wattstunde (Wh)'
        this.chartOptions.series[0].name = 'Strom';
        this.chartOptions.series[0].data = chartData.electricity;
        console.log('energy data', this.chartOptions.series[0].data);
      } else {
        this.chartOptions.yAxis[0].title.text = 'Strom in Wattstunde (Wh)'
        this.chartOptions.series[0].name = 'Strom';
        this.chartOptions.series[0].data = [null];
      }
      if (!isNaN(waterValue)) {
        const waterData = {
          startDate: this.dataWater[0].obs_intervall_start,
          endDate: this.fromDate
          // endDate: this.dataWater[this.dataWater.length - 1].obs_intervall_start
        }
        for (const x in this.dataWater) {
          // if (this.dataWater[x].id) {
            chartData.water.push((this.dataWater[x].consumption));
          // }
          if (this.calculateDiff(waterData) <= 0) {
            this.waterIntervallStart[x] = this.datePipe.transform(this.dataWater[x].obs_intervall_start, 'HH:mm');
          } else if ((this.calculateDiff(waterData) > 0) && (this.calculateDiff(waterData) < 4380)) {
            this.waterIntervallStart[x] = this.datePipe.transform(this.dataWater[x].obs_intervall_start, 'dd.MM HH:mm');
          } else if (this.calculateDiff(waterData) >= 4380) {
            this.waterIntervallStart[x] = this.datePipe.transform(this.dataWater[x].obs_intervall_start, 'dd.MM.yyyy');
          }
          // const el = this.stats[x].items;
          // el.forEach(element => {
          //   chartData.water.push(element.meter_reading);
          // });
        }
        this.chartOptions.yAxis[1].title.text = 'Wasser in Liter (L)'
        this.chartOptions.series[1].name = 'Wasser';
        this.chartOptions.series[1].data = chartData.water;
      } else {
        this.chartOptions.yAxis[1].title.text = 'Wasser in Liter (L)'
        this.chartOptions.series[1].name = 'Wasser';
        this.chartOptions.series[1].data = [null];
      }
      // this.chartOptions.xAxis.categories = this.times;

      if (this.energyIntervallStart.length === 0 && this.waterIntervallStart.length === 0) {
        this.chartOptions.xAxis.categories = this.times;
      } else if (this.energyIntervallStart.length !== 0 && this.waterIntervallStart.length === 0) {
        this.chartOptions.xAxis.categories = this.energyIntervallStart;
      } else if (this.energyIntervallStart.length === 0 && this.waterIntervallStart.length !== 0) {
        this.chartOptions.xAxis.categories = this.waterIntervallStart;
      } else {
        if (this.energyIntervallStart.length > this.waterIntervallStart.length) {
          this.chartOptions.xAxis.categories = this.energyIntervallStart;
        } else {
          this.chartOptions.xAxis.categories = this.waterIntervallStart;
        }
      }
      // this.chartOptions.xAxis.categories = this.times;
      //
      // console.log('chart options', this.chartOptions.xAxis.categories)

      this.showGraph = true;
      this.updateChart1 = true;
    } else {
      this.showGraph = false;
    }
  }

  calcMotion = (data) => {
    this.dataMotion = data.motion;
    console.log('graph data', data);
    const chartData = {
      motion: [],
    };
    if (data.motion.length !== 0) {
      let motionValue = null;
      for (const x in data.motion) {
        motionValue = +data.motion[x].motion_count * 1000
      }
      if (!isNaN(motionValue)) {
        const motionData = {
          startDate: this.dataMotion[0].obs_intervall_start,
          endDate: this.fromDate
        }
        for (const x in this.dataMotion) {
          // chartData.electricity.push(Math.round((this.dataEnergy[x].consumption + Number.EPSILON) * 100) / 100);
          if (this.dataMotion[x].id) {
            chartData.motion.push(this.dataMotion[x].motion_count);
          }
          if (this.calculateDiff(motionData) <= 0) {
            this.motionIntervallStart[x] = this.datePipe.transform(this.dataMotion[x].obs_intervall_start, 'HH:mm');
          } else if ((this.calculateDiff(motionData) > 0) && (this.calculateDiff(motionData) <= 4380)) {
            this.motionIntervallStart[x] = this.datePipe.transform(this.dataMotion[x].obs_intervall_start, 'dd.MM HH:mm');
          } else if (this.calculateDiff(motionData) >= 4380) {
            this.motionIntervallStart[x] = this.datePipe.transform(this.dataMotion[x].obs_intervall_start, 'dd.MM.yyyy');
          }
        }
        this.chartOptions.series[0].name = 'Beweung';
        this.chartOptions.yAxis[0].title.text = 'Beweung'
        this.chartOptions.series[0].data = chartData.motion;
        this.chartOptions.yAxis[1].title.text = ''
        this.chartOptions.series[1].name = '';
        this.chartOptions.series[1].data = [null];
      } else {
        this.chartOptions.series[0].name = 'Beweung';
        this.chartOptions.yAxis[0].title.text = 'Beweung'
        this.chartOptions.series[0].data = [null];
        this.chartOptions.yAxis[1].title.text = ''
        this.chartOptions.series[1].name = '';
        this.chartOptions.series[1].data = [null];
      }
      if (this.motionIntervallStart.length === 0) {
        this.chartOptions.xAxis.categories = this.times;
      } else {
        this.chartOptions.xAxis.categories = this.motionIntervallStart;
      }
      this.showGraph = true;
      // this.chartOptions.series[0].name = 'Beweung';
      // this.chartOptions.yAxis[0].title.text = 'Beweung'
      // this.chartOptions.series[0].data = [null];
      // this.chartOptions.yAxis[1].title.text = ''
      // this.chartOptions.series[1].name = '';
      // this.chartOptions.series[1].data = [null];
      console.log('this.chart option series', this.chartOptions.series);
      this.updateChart1 = true;
    } else {
      this.showGraph = false;
    }
  }

  calcTemperatureHumidity(data) {
    this.dataTemperature = data.temperature;
    this.dataHumidity = data.humidity;
    console.log('graph data', data);
    const chartData = {
      temperature: [],
      humidity: [],
    };
    if (data.temperature.length !== 0 && data.humidity.length !== 0) {
      let temperatureValue = null;
      for (const x in data.energy) {
        temperatureValue = +data.energy[x].temperature_c * 1000
        console.log('energyValue in consumption', temperatureValue);
      }
      let humidityValue = null;
      for (const x in data.water) {
        humidityValue = +data.water[x].humidity * 1000
        console.log('waterValue in consumption', humidityValue);
      }
      if (!isNaN(temperatureValue)) {
        const temperatureData = {
          startDate: this.dataTemperature[0].obs_intervall_start,
          endDate: this.fromDate
        }
        for (const x in this.dataTemperature) {
          // chartData.electricity.push(Math.round((this.dataEnergy[x].consumption + Number.EPSILON) * 100) / 100);
          if (this.dataTemperature[x].id) {
            chartData.temperature.push(this.dataTemperature[x].temperature_c);
          }
          if (this.calculateDiff(temperatureData) <= 0) {
            this.temperatureIntervallStart[x] = this.datePipe.transform(this.dataTemperature[x].obs_intervall_start, 'HH:mm');
          } else if ((this.calculateDiff(temperatureData) > 0) && (this.calculateDiff(temperatureData) <= 4380)) {
            this.temperatureIntervallStart[x] = this.datePipe.transform(this.dataTemperature[x].obs_intervall_start, 'dd.MM HH:mm');
          } else if (this.calculateDiff(temperatureData) >= 4380) {
            this.temperatureIntervallStart[x] = this.datePipe.transform(this.dataTemperature[x].obs_intervall_start, 'dd.MM.yyyy');
          }
        }
        this.chartOptions.yAxis[0].title.text = 'Temperatur'
        this.chartOptions.series[0].name = 'Temperatur';
        this.chartOptions.series[0].data = chartData.temperature;
      } else {
        this.chartOptions.yAxis[0].title.text = 'Temperatur'
        this.chartOptions.series[0].name = 'Temperatur'
        this.chartOptions.series[0].data = [null];
      }
      if (!isNaN(humidityValue)) {
        const humidityData = {
          startDate: this.dataHumidity[0].obs_intervall_start,
          endDate: this.fromDate
          // endDate: this.dataWater[this.dataWater.length - 1].obs_intervall_start
        }
        for (const x in this.dataHumidity) {
          if (this.dataHumidity[x].id) {
            chartData.humidity.push((this.dataHumidity[x].humidity));
          }
          if (this.calculateDiff(humidityData) <= 0) {
            this.humidityIntervallStart[x] = this.datePipe.transform(this.dataHumidity[x].obs_intervall_start, 'HH:mm');
          } else if ((this.calculateDiff(humidityData) > 0) && (this.calculateDiff(humidityData) < 4380)) {
            this.humidityIntervallStart[x] = this.datePipe.transform(this.dataHumidity[x].obs_intervall_start, 'dd.MM HH:mm');
          } else if (this.calculateDiff(humidityData) >= 4380) {
            this.humidityIntervallStart[x] = this.datePipe.transform(this.dataHumidity[x].obs_intervall_start, 'dd.MM.yyyy');
          }
          // const el = this.stats[x].items;
          // el.forEach(element => {
          //   chartData.water.push(element.meter_reading);
          // });
        }
        this.chartOptions.yAxis[1].title.text = 'Lufteuchte'
        this.chartOptions.series[1].name = 'Lufteuchte';
        this.chartOptions.series[1].data = chartData.humidity;
      } else {
        this.chartOptions.yAxis[1].title.text = 'Lufteuchte'
        this.chartOptions.series[1].name = 'Lufteuchte';
        this.chartOptions.series[1].data = [null];
      }
      this.showGraph = true;
      console.log('temperature intervall start length: ', this.temperatureIntervallStart.length);
      if (this.temperatureIntervallStart.length !== 0) {
        this.chartOptions.xAxis.categories = this.temperatureIntervallStart;
      } else {
        this.chartOptions.xAxis.categories = this.times;
      }
      if (this.temperatureIntervallStart.length === 0 && this.humidityIntervallStart.length === 0) {
        this.chartOptions.xAxis.categories = this.times;
      } else if (this.temperatureIntervallStart.length !== 0 && this.humidityIntervallStart.length === 0) {
        this.chartOptions.xAxis.categories = this.temperatureIntervallStart;
      } else if (this.temperatureIntervallStart.length === 0 && this.humidityIntervallStart.length !== 0) {
        this.chartOptions.xAxis.categories = this.humidityIntervallStart;
      } else {
        if (this.temperatureIntervallStart.length > this.humidityIntervallStart.length) {
          this.chartOptions.xAxis.categories = this.temperatureIntervallStart;
        } else {
          this.chartOptions.xAxis.categories = this.humidityIntervallStart;
        }
      }
      console.log('chart options', this.chartOptions);
      this.updateChart1 = true;
    } else {
      this.showGraph = false;
    }
  }

  calcSocialScore(data) {
    this.dataSocialHannah = data.hannah;
    this.dataSocialApp = data.appl;
    console.log('graph data', data.appl);
    const chartData = {
      socialHannah: [],
      socialApp: [],
    };
    if (data.hannah.length !== 0 || data.appl.length !== 0) {
      let hannahValue = null;
      for (const x in data.hannah) {
        hannahValue = +data.hannah[x].count_activities * 1000
      }
      let appValue = null;
      for (const x in data.appl) {
        console.log('HERE');
        appValue = +data.appl[x].count_activities * 1000
      }
      if (!isNaN(hannahValue) && data.hannah.length !== 0) {
        const socialHannahData = {
          startDate: this.dataSocialHannah[0].obs_intervall_start,
          endDate: this.fromDate
        }
        for (const x in this.dataSocialHannah) {
          // chartData.electricity.push(Math.round((this.dataEnergy[x].consumption + Number.EPSILON) * 100) / 100);
          if (this.dataSocialHannah[x].id) {
            chartData.socialHannah.push(this.dataSocialHannah[x].count_activities);
          }
          if (this.calculateDiff(socialHannahData) <= 0) {
            this.socialHannahIntervallStart[x] = this.datePipe.transform(this.dataSocialHannah[x].obs_intervall_start, 'HH:mm');
          } else if ((this.calculateDiff(socialHannahData) > 0) && (this.calculateDiff(socialHannahData) <= 4380)) {
            this.socialHannahIntervallStart[x] = this.datePipe.transform(this.dataSocialHannah[x].obs_intervall_start, 'dd.MM HH:mm');
          } else if (this.calculateDiff(socialHannahData) >= 4380) {
            this.socialHannahIntervallStart[x] = this.datePipe.transform(this.dataSocialHannah[x].obs_intervall_start, 'dd.MM.yyyy');
          }
        }
        this.chartOptions.yAxis[0].title.text = 'Hannah'
        this.chartOptions.series[0].name = 'Hannah';
        this.chartOptions.series[0].data = chartData.socialHannah;
      } else {
        this.chartOptions.yAxis[0].title.text = 'Hannah'
        this.chartOptions.series[0].name = 'Hannah';
        this.chartOptions.series[0].data = [null];
      }
      if (!isNaN(appValue) && data.appl.length !== 0) {
        const socialAppData = {
          startDate: this.dataSocialApp[0].obs_intervall_start,
          endDate: this.fromDate
          // endDate: this.dataWater[this.dataWater.length - 1].obs_intervall_start
        }
        for (const x in this.dataSocialApp) {
          if (this.dataSocialApp[x].id) {
            chartData.socialApp.push((this.dataSocialApp[x].count_activities));
          }
          if (this.calculateDiff(socialAppData) <= 0) {
            this.socialAppIntervallStart[x] = this.datePipe.transform(this.dataSocialApp[x].obs_intervall_start, 'HH:mm');
          } else if ((this.calculateDiff(socialAppData) > 0) && (this.calculateDiff(socialAppData) < 4380)) {
            this.socialAppIntervallStart[x] = this.datePipe.transform(this.dataSocialApp[x].obs_intervall_start, 'dd.MM HH:mm');
          } else if (this.calculateDiff(socialAppData) >= 4380) {
            this.socialAppIntervallStart[x] = this.datePipe.transform(this.dataSocialApp[x].obs_intervall_start, 'dd.MM.yyyy');
          }
          // const el = this.stats[x].items;
          // el.forEach(element => {
          //   chartData.water.push(element.meter_reading);
          // });
        }
        this.chartOptions.yAxis[1].title.text = 'App'
        this.chartOptions.series[1].name = 'App';
        this.chartOptions.series[1].data = chartData.socialApp;
      } else {
        this.chartOptions.yAxis[1].title.text = 'App'
        this.chartOptions.series[1].name = 'App';
        this.chartOptions.series[1].data = [null];
      }
      if (this.socialAppIntervallStart.length === 0 && this.socialHannahIntervallStart.length === 0) {
        this.chartOptions.xAxis.categories = this.times;
      } else if (this.socialAppIntervallStart.length !== 0 && this.socialHannahIntervallStart.length === 0) {
        this.chartOptions.xAxis.categories = this.socialAppIntervallStart;
      } else if (this.socialAppIntervallStart.length === 0 && this.socialHannahIntervallStart.length !== 0) {
        this.chartOptions.xAxis.categories = this.socialHannahIntervallStart;
      } else {
        if (this.socialAppIntervallStart.length > this.socialHannahIntervallStart.length) {
          this.chartOptions.xAxis.categories = this.socialAppIntervallStart;
        } else {
          this.chartOptions.xAxis.categories = this.socialHannahIntervallStart;
        }
      }
      this.showGraph = true;
      console.log('this.chart option series', this.chartOptions.series);
      this.updateChart1 = true;
    } else {
      this.showGraph = false;
    }
  }

  fetchHistory(date, e) {
    console.log('fetch history EEEE', e);
    if (date == 1) {
      this.fromDate = e;
    } else {
      this.toDate = e;
    }
    if (this.fromDate) {
      const data = {
        householdId: this.householdId,
        fromDate: this.formatDate(this.fromDate),
        toDate: this.formatDate(this.toDate),
      };
      console.log('this.datePipe on fetch history', this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'))
      this.getConsumption(this.householdId, this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'), data.toDate, this.type);
    }
  }

  formatDate(d) {
    const date = new Date(d);
    let dd: any = date.getDate();
    let mm: any = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return d = yyyy + '-' + mm + '-' + dd;
  }

  calculateDiff(data) {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);

    const days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }

  setStatisticsType(type) {
    this.graphTitle = this.statisticsTabs[type].title;
    this.graphSubtitle = this.statisticsTabs[type].subtitle;
    if (type === 0) {
      this.type = 'get-admin-consumption'
    } else if (type === 1) {
      this.type = 'get-motion-data';
    } else if (type === 2) {
      this.type = 'get-temp-hum'
    } else if (type === 3) {
      this.type = 'get-social-score-data'
    }
    const data = {
      householdId: this.householdId,
      fromDate: this.formatDate(this.fromDate),
      toDate: this.formatDate(this.toDate),
    };

    this.getConsumption(this.householdId, this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'), data.toDate, this.type);
    for (const x of this.statisticsTabs) {
      if (type === x.id) {
        this.statisticsTabs[x.id].selected = true;
      } else {
        this.statisticsTabs[x.id].selected = false;
      }
    }
    this.updateChart1 = true;
  }
}
