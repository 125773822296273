<div class="register-main">
  <!-- BEGIN: Header-->
  <div class="page-topbar" id="header">
    <div class="navbar navbar-fixed">
      <nav class="navbar-main red no-shadow">
      </nav>
    </div>
  </div>
  <!-- END: Header-->

  <!-- BEGIN: Page Main-->
  <div class="row h-100">
    <div class="col s12 m10 offset-m1 l8 offset-l2">
      <div class="container">
        <div class="row mb-1">
          <div class="col s12">
            <div class="right-align"><img src="assets/images/logo/hannah-logo.svg" alt="HANNAH" class="hannah-logo"/>
            </div>
            <div class="clearfix"></div>
            <div id="notfallkontakt-anlegen" class="card card card-default mt-3">
              <div class="card-content">

                <div *ngIf="loadingPage" class="mt-5 mb-5 text-center">
                  <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <!-- content if link is valid -->
               <div *ngIf="validLink">
                <h4
                [translate]="'hannahConfig.title'" [translateParams]="{ user: 'Elsa' }" class="mb-6">
                 </h4>

                 <h4 class="mb-6">{{'hannahConfig.description' | translate}}</h4>

                 <h4>1. {{'hannahConfig.step1' | translate}}</h4>
                 <h4>2. {{'hannahConfig.step2' | translate}}</h4>

                 <div class="text-center mt-5">
                  <button class="btn btn-start" routerLink="/wifiConfig">{{'hannahConfig.start' | translate}}</button>
                </div>
               </div>

               <div *ngIf="!validLink && !loadingPage" class="expired">
                <div class="text-center mb-3">
                  <h5 class="cursor-default">{{'hannahConfig.link-expired-title' | translate}}</h5>
                  <h5 class="cursor-default">{{'hannahConfig.link-expired-description' | translate}}</h5>
                 </div>
                 <div class="text-center mt-5">
                  <i class="material-icons my-auto info-icon cursor-default">info</i>
                 </div>

                 <div class="row mt-5">
                  <!-- resend email button -->
                  <div class="col-md-6 text-center">
                    <button class="btn btn-start resend text-center" (click)="resendEmail()">
                      <span *ngIf="!sendingEmail && !emailSent">{{'hannahConfig.resend-email' | translate}}</span>
                      <span *ngIf="sendingEmail">
                        <div class="lds-small lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      </span>
                      <span *ngIf="emailSent && !sendingEmail" class="sent"><i class="material-icons check-icon">done</i></span>
                    </button>
                  </div>
                  <!-- go home button -->
                  <div class="col-md-6 text-center">
                    <button class="btn btn-start" routerLink="/dashboard">{{'hannahConfig.go-home' | translate}}</button>
                  </div>
                </div>
               </div>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- END: Page Main-->
</div>
