import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(private http: HttpClient) {
  }

  getNightMode() {
    const householdId = localStorage.getItem('household_id');
    return this.http.get(`${environment.api}get-night-mode-time/${householdId}`).pipe(map((response: any) => {
      return response.result
    }));
  }
  postNightMode(data) {
    return this.http.post(`${environment.api}update-night-mode-time`, data).pipe();
  }
}
