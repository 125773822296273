import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Services} from '../../services';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {first, timeout} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {HouseholdService} from '../../services/household.service';
import {SharedService} from '../../services/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {RemoveAccountPopupComponent} from './remove-account-popup/remove-account-popup.component';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/services/auth.service';
import { AlarmStateService } from 'src/app/services/alarm-state.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-meine-daten',
  templateUrl: './meine-daten.component.html',
  styleUrls: ['./meine-daten.component.scss']
})

export class MeineDatenComponent implements OnInit, OnDestroy {
  householdId;
  userId;
  myDataForm;
  passFormGroup;
  showInputField = false;
  showAvatar = true;
  userData;
  fileToUpload: File = null;
  imageSrc;
  avatarExists = false;
  avatarImg;
  imageUploaded = true;
  url: string | ArrayBuffer;
  userActiveAlarm:boolean = false;
  private subscriptions:Subscription;
  //isOwner = localStorage.getItem('userRole') ? localStorage.getItem('userRole') == 'owner' : true;
  ownsHousehold = localStorage.getItem('ownsHousehold') ? localStorage.getItem('ownsHousehold')=='true':false
  password_validation_messages = [
    {
      type: 'required',
      message: 'Password is required'
    },
    {
      type: 'minlength',
      message: 'Password must be at least 8 characters long'
    },
    {
      type: 'maxlength',
      message: 'Password must be at most 25 characters long'
    },
    {
      type: 'pattern',
      message: 'Your password must contain at least one uppercase, one lowercase, one number, and one special character'
    }
  ]


  constructor(private services: Services,
              private toasterService: ToastrService,
              private sharedService: SharedService,
              private translate: TranslateService,
              private modalService: BsModalService,
              private householdService: HouseholdService,
              private authService: AuthService,
              public alarmStateService: AlarmStateService,
              private translateService: TranslateService,
              private router: Router
  ) {
    this.subscriptions = new Subscription()
  }

  ngOnInit(): void {

    const checkUserActiveAlarm = this.alarmStateService.checkUserActiveAlarm().subscribe({
      next: (data) => {
        this.userActiveAlarm = data.alarmActive
        if(this.userActiveAlarm){
          this.nAToaster()
        }
      },
      error:(err)=>{
        this.userActiveAlarm=false
      }
    })
    this.url = environment.imagesApi;
    const updatedHousehold = this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.getData();
    });
    this.getData();

    this.subscriptions.add(checkUserActiveAlarm)
    this.subscriptions.add(updatedHousehold)
  }

  get isAuthorized() {
    return this.householdService.isAuthorized();
  }

  handleFileInput(files: FileList) {
    console.log('gizza', files)
    this.fileToUpload = files.item(0);
    const file = files[0];

    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;

    reader.readAsDataURL(file);
    this.imageUploaded = true;
  }

  removeAvatar() {
    this.avatarExists = false;
    console.log('removing avatar', this.avatarExists);
  }

  addAvatar() {
    if (this.imageUploaded) {
      this.avatarExists = true;
    }
    console.log('removing avatar', this.avatarExists);
  }

  getData() {
    this.householdId = localStorage.getItem('household_id');
    // this.userId = localStorage.getItem('user_id');
    this.userId = this.authService.getId();
    const getMyData = this.services.getMyData({id: this.userId, household_id: this.householdId}).subscribe((data: any) => {
      this.userData = data;
      this.showAvatar = true;
      if (this.userData.user.avatar_img) {
        console.log('has avatar', this.userData.user.avatar_img);
        this.avatarImg = this.userData.user.avatar_path;
        this.avatarExists = true;
      }
      const dragAndDrop = $('.dropify').dropify({
        defaultFile: this.avatarImg,
        messages: {
          default: 'Neues Profilbild hochladen',
          replace: 'Profilbild ersetzen',
          remove: 'Entfernen',
          error: 'Leider ist etwas schief gelaufen!'
        },
        tpl: {
          filename: '',
        }
      });
      console.log('URL image', this.url, this.avatarImg);
      console.log(data);
      this.editUserHandler();
      console.log('users data', data);
    }, (error) => {
      console.log(error);
    });
    this.addPasswordHandler();
    this.subscriptions.add(getMyData)
  }

  showSuccess(message, title) {
    this.toasterService.success(message, title)
  }

  showError(message, title) {
    this.toasterService.error(message, title)
  }

  showInfo(message, title) {
    this.toasterService.info(message, title)
  }

  showWarning(message, title) {
    this.toasterService.warning(message, title)
  }

  public editUserHandler() {
    this.myDataForm = new FormGroup({
      // id: new FormControl(this.userData.user.id),
      // gender: new FormControl(this.userData.user.gender, [
      //   Validators.required
      // ]),
      name: new FormControl(this.userData.user.name, [
        Validators.required
      ]),
      surname: new FormControl(this.userData.user.surname, [
        Validators.required
      ]),
      mobile_number: new FormControl(this.userData.user.mobile_number, [
        Validators.required, Validators.pattern('((^[+][0-9 ]{10,19}))')
      ]),
      email: new FormControl(this.userData.user.email, [
        Validators.required,
        Validators.email
      ]),
      townname: new FormControl(this.userData.user.townname != 'null' ? this.userData.user.townname : ''),
      streetname: new FormControl(this.userData.user.streetname != 'null' ? this.userData.user.streetname : ''),
      zipcode: new FormControl(this.userData.user.zipcode != 0 ? this.userData.user.zipcode : ''),
      housingnumber: new FormControl(this.userData.user.housingnumber != 0 ? this.userData.user.housingnumber : ''),
      household_id: new FormControl(this.householdId)
    });

  }

  public addPasswordHandler() {

    this.passFormGroup = new FormGroup({
      id: new FormControl(this.userId),
      password: new FormControl('', [
        Validators.required
      ]),
      new_password: new FormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.required,
        Validators.pattern(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.\\[@$!\\]&;§%<>:*=\\\\,+_\\-"'#|~?&(){}\\/])[a-zA-Z0-9.@$!%*"'#\\[,§(+)\\]<=>;\\-_&{:\\\\~}|?&\\/]+$`) // includes UpperCase, LoweCase, Number and Special Character
      ])),

      retype_password: new FormControl('', [
        Validators.required
      ]),
      household_id: new FormControl(this.householdId)
    }, {validators:[this.checkPasswords, this.checkIfSamePassword]});
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = group.get('new_password').value;
    let confirmPass = group.get('retype_password').value
    return pass === confirmPass ? null : { notSame: true }
  }
  checkIfSamePassword: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let newpass = group.get('new_password').value;
    let pass = group.get('password').value
    return pass != newpass ? null : { sameValues: true }
  }

  public editUser() {
    this.alarmStateService.checkAlarmState(this.householdId)
    const checkUserActiveAlarm = this.alarmStateService.checkUserActiveAlarm().subscribe({
      next: (data) => {
        this.userActiveAlarm = data.alarmActive
        if(this.userActiveAlarm){
          this.nAToaster()
          this.getData()
          return false
        }
        this.services.changeAvatar(this.fileToUpload).subscribe((response) => {
          console.log(response);
        });
        this.services.editMyData(this.myDataForm.value)
          .pipe(first())
          .subscribe(data => {
              this.showSuccess(this.translate.instant('toasterMessages.successMessage'), this.translate.instant('toasterMessages.success'));
              console.log(data);
              if(data['isEmailChanged'] == 'true' || data['isEmailChanged'] == true){
                console.log('Loging out')
                this.authService.logout();
              }
            },
            error => {
              this.showError(this.translate.instant('toasterMessages.errorMessage'), this.translate.instant('toasterMessages.error'));
              console.log('error', error);
            });
      }
    })
    this.subscriptions.add(checkUserActiveAlarm)

  }
  currentPassword:boolean = true;
  public changePassword() {
    // validations missing
    this.services.changeMyPassword(this.passFormGroup.value)
      .pipe(first())
      .subscribe(data => {
        console.log(data);
        this.currentPassword = data['isPasswordUpdated']
        if(this.currentPassword){
          this.showSuccess(this.translate.instant('toasterMessages.successMessage'), this.translate.instant('toasterMessages.success'));
          this.passFormGroup.reset()
        }
        },
        error => {
          this.showError(this.translate.instant('toasterMessages.errorMessage'), this.translate.instant('toasterMessages.error'));
          console.log('error', error);
        });
  }

  isEditFieldValid(field: string) {
    return !this.myDataForm.get(field).valid && this.myDataForm.get(field).touched;
  }

  isPassFieldValid(field: string) {
    return !this.passFormGroup.get(field).valid && this.passFormGroup.get(field).touched;
  }
  isPassConfirmFieldValid(field: string) {

    const pass = this.passFormGroup.get('new_password').value;
    const confirmPass = this.passFormGroup.get(field).value;
    const match:boolean = pass === confirmPass
    return (!this.passFormGroup.get(field).valid && this.passFormGroup.get(field).touched) || (!match && this.passFormGroup.get(field).touched);
  }
  isPassFieldCurrentPass(field: string){
    if(this.passFormGroup.get('password').value == ""){
      return false
    }
    return this.passFormGroup.get(field).value === this.passFormGroup.get('password').value && this.passFormGroup.get(field).touched  && this.passFormGroup.get(field).dirty;
  }

  resetConfirm(){
    this.passFormGroup.patchValue({
      retype_password: ''
    })
  }

  removeAccountFromHousehold() {
    if(!this.isOwner()){
      const initialState = {
        householdId: localStorage.getItem('household_id'),
      };
      this.modalService.show(RemoveAccountPopupComponent, {initialState});
    }
  }
  removeAccountFully(){
    const initialState = {
      householdId: localStorage.getItem('household_id'),
      removeFully:true
    };
    this.modalService.show(RemoveAccountPopupComponent, {initialState});
  }
  nAToaster(){
    this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'),{timeOut:10000});
  }
  isOwner(){
    return localStorage.getItem('userRole') ? localStorage.getItem('userRole') == 'owner' : true;
  }
  stopPropagation(e:Event){
    e.stopPropagation()
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    this.toasterService.clear()
  }
}
