import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()

export class HannahAvailabilityService {
  householdId;
  availabilityData;
  availabilityStatus;
  availabilityState = new Subject();
  //sharedHannahStateData = this.availabilityState.asObservable();

  constructor(private http: HttpClient) {
  }

  nextAlarmState(data: any) {
    this.availabilityState.next(data);
  }

  sharedHannahState(data) {
    this.availabilityData = data;
    this.nextAlarmState(this.availabilityData);
  }

  getAvailability(householdId) {
    const data: any = {};
    this.http.get(`${environment.api}get-maintenance-status/${householdId}`).subscribe((res: any) => {
      console.log('hannah in betrierb', res);
      if (res.result.status === 'offline') {
        data.status = 'offline';
        data.date = res.result.timestamp;
        this.sharedHannahState(data);
      } else if (res.result.status === 'online') {
        data.status = 'online';
        data.date = res.result.timestamp;
        this.sharedHannahState(data);
      }
    }, err => {
      console.log(err);
      data.status = 'offline';
      data.date = new Date();
      this.sharedHannahState(data);
    });
  }
}
