<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'alarmEdit.title' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a></li>
              <li class="breadcrumb-item">{{'tabs.alarmStatus' | translate}}</li>
              <li class="breadcrumb-item active">{{'alarmEdit.title' | translate}}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">

        <div class="row mt-1">
          <div class="col s12 m12 l12">
            <div class="card-alert card green lighten-5">
              <div class="card-content green-text">
                <p>{{isHotlineBooked === true ? ('alarmEdit.note' | translate) : ('alarmEdit.noteNotBooked' | translate)}}</p>
              </div>
<!--              <button type="button" class="close green-text" data-dismiss="alert" aria-label="Close">-->
<!--                <span aria-hidden="true">×</span>-->
<!--              </button>-->
            </div>
          </div>

          <!--          draganddrop-->

          <div class="col s12 m12 l6">
            <div class="card card-default contacts">
              <div class="card-content">
                <div class="row">
                  <div class="col s12 mb-2">
                    <h4 class="card-title">{{'alarmEdit.descriptionWithPosition' | translate}}
                      <a type="button" class="tooltipped right popover-content" data-position="bottom"
                         popover="{{isHotlineBooked ? ('alarmEdit.help' | translate) : ('alarmEdit.helpNotBooked' | translate)}}"
                         placement="bottom"

                      >
                        <i class="material-icons notranslate">help</i>
                      </a>
                    </h4>
                  </div>
                  <div class="col s12">
                    <div class="draggable-field">
                      <div class="dd" id="nestable">
                        <div
                          cdkDropList
                          #todoList="cdkDropList"
                          [cdkDropListData]="usersWithPosition"
                          [cdkDropListConnectedTo]="[doneList]"
                          class="example-list"
                          (cdkDropListDropped)="drop($event, 'left')">
                          <ol class="dd-list">
                            <li class="dd-item" *ngFor="let item of usersWithPosition " cdkDrag>
                              <div class="dd-handle">
                                <img *ngIf="item.avatar_path !== null && item.avatar_path !== undefined"
                                     onError="this.src='../../assets/images/avatar/no-image.png'"
                                     src="{{item.avatar_path}}" alt="image" class="avatar user-icon">
                                <!-- <img *ngIf="item.avatar_path =='female-avatar'"
                                     src="../../assets/images/avatar/female-avatar.png" alt="image" class="avatar user-icon">
                                <img *ngIf="item.avatar_path == null && item.avatar_path == undefined"
                                     src="../../assets/images/avatar/no-image.png" alt="image" class="avatar user-icon"> -->
                                <span>{{item.name}} {{item.surname}}</span><i class="material-icons notranslate zoom-out-map">zoom_out_map</i>
                              </div>
                            </li>
                          </ol>
                        </div>
                        <!--                          <ol class=""></ol>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col s12 m12 l6">
            <div class="card card-default contacts">
              <div class="card-content">
                <div class="row">
                  <div class="col s12 mb-2">
                    <h4 class="card-title">{{'alarmEdit.descriptionWithoutPosition' | translate}}</h4>
                  </div>
                  <div class="col s12">
                    <div class="draggable-field">

                      <div class="dd" id="nestable2">
                        <div
                          cdkDropList
                          #doneList="cdkDropList"
                          [cdkDropListData]="usersWithoutPosition"
                          [cdkDropListConnectedTo]="[todoList]"
                          class="example-list"
                          (cdkDropListDropped)="drop($event, 'right')">
                          <ol class="dd-list">
                            <li class="dd-item" *ngFor="let item of usersWithoutPosition" cdkDrag>
                              <div class="dd-handle">
                                <img *ngIf="item.avatar_path !== null && item.avatar_path !== undefined"
                                     onError="this.src='../../assets/images/avatar/no-image.png'"
                                     src="{{item.avatar_path}}" alt="image" class="avatar user-icon">
                                <!-- <img *ngIf="item.avatar_path ==='female-avatar'"
                                     src="../../assets/images/avatar/female-avatar.png" alt="image" class="avatar user-icon">
                                <img *ngIf="item.avatar_path === null || item.avatar_path === undefined"
                                     src="../../assets/images/avatar/no-image.png" alt="image" class="avatar user-icon"> -->
                                <span>{{item.name}} {{item.surname}}</span><i class="material-icons notranslate zoom-out-map">zoom_out_map</i>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--        <li class="dd-item" *ngFor="let item of alarmUsers" cdkDrag>-->
        <!--          <div class="dd-handle">-->
        <!--            {{item.id}}-->
        <!--            &lt;!&ndash;                                <img  src="{{item.avatar_path}}" alt="image" class="avatar">&ndash;&gt;-->
        <!--            &lt;!&ndash;                                <span>{{item.name}} {{item.surname}}</span><i class="material-icons zoom-out-map">zoom_out_map</i>&ndash;&gt;-->
        <!--          </div>-->
        <!--        </li>-->

        <!--  hidden temporary -->
        <div class="row mb-1" *ngIf="isHotlineBooked">
          <!-- Optional -->
          <div class="col s12 m12 l6">
            <div class="card card-default">
              <div class="card-content">
                <h4 class="mb-0 mt-0">{{'alarmEdit.callCenter.title' | translate}}</h4>
                <p class="no-margin" [innerHtml]="'alarmEdit.callCenter.emergencyCentral' | translate"></p>
              </div>
            </div>
          </div>
        </div>
        <!--  to here hidden temporary -->

      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <app-confirm-changes [type]="'alarm'"></app-confirm-changes>
</ng-template>
<!-- END: Page Main-->
