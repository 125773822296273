import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Services } from 'src/app/services';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-confirm-changes',
  templateUrl: './confirm-changes.component.html',
  styleUrls: ['./confirm-changes.component.scss']
})
export class ConfirmChangesComponent implements OnInit {
  @Input() type
  
  constructor(private services: Services,
    public RefEC: BsModalRef,
    private sharedService: SharedService,
    ) { }
    
  ngOnInit(): void {

  }
  closeModal() {
    this.sharedService.closeModal(this.type)
  }
  approveChanges(){
    this.sharedService.approveChanges(this.type)
  }
}
