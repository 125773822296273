import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  constructor(private http: HttpClient,
              private formBuilder: FormBuilder,
              private router: Router,
              private toastrService: ToastrService,
              private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]],
    });
  }

  showSuccess(message, title) {
    this.toastrService.success(message, title)
  }

  showError(message, title) {
    this.toastrService.error(message, title)
  }

  showInfo(message, title) {
    this.toastrService.info(message, title)
  }

  showWarning(message, title) {
    this.toastrService.warning(message, title)
  }

  resetPassword() {
    this.http.post(`${environment.api}send-reset-password-email`, {email: this.forgotPasswordForm.value.email.trim()}).subscribe(response => {
      this.showSuccess(this.translate.instant('toasterMessages.forgotPasswordSuccess'), this.translate.instant('toasterMessages.success'))
      this.router.navigateByUrl('login');
    }, error => {
      this.showSuccess(this.translate.instant('toasterMessages.forgotPasswordSuccess'), this.translate.instant('toasterMessages.success'))
    });
    // this.authService.login(
    //   {
    //     email: this.f.email.value,
    //     password: this.f.password.value,
    //     web: 1
    //   }
    // )
    //   .subscribe(success => {
    //       console.log('success', success)
    //       if (success) {
    //         this.router.navigate(['/household-select']);
    //       }
    //     }, err => this.error = 'Could not authenticate'
    //   );
  }
}
