import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  socket: any = io;
  socketId;
  householdID;

  constructor(private http: HttpClient) {
    this.socket = this.socket(`https://chat-${environment.name}.xvalue-services.de`, {transports: ['polling']});
    this.householdID = localStorage.getItem('household_id');

    this.http.get(`${environment.api}get-login-info`).subscribe((response: any) => {

      this.socket.emit('authenticate', {
        token: localStorage.getItem('JWT_TOKEN')
      }, (res) => {
        if (res) {
          console.log('elsaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
          this.socket.emit('join', {
            household_id: localStorage.getItem('household_id'),
            user_id: response.user.id,
            room: this.householdID + 'wifiConfig',
            name: 'wifiConfiguration'
          }, (response) => {
            console.log('response on join', response);
            this.socketId = response.socket_id;
          });
        }
        console.log('response on authenticate: ', res);
      });
    });

  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any) {
    console.log('emitting');
    this.socket.emit(eventName, data, (response) => {
      console.log('response from emit', response);
    });
  }
}
