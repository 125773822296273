import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AlarmStateService} from '../../services/alarm-state.service';
import {HannahAvailabilityService} from '../../services/hannah-availability.service';
import {AlarmLatestHistoryService} from '../../services/alarm-latest-history.service';
import {EventScoreService} from '../../services/event-score.service';
import {DatePipe} from '@angular/common';
import {HouseholdService} from '../../services/household.service';
import {SharedService} from '../../services/shared.service';
import {SensorsService} from '../../services/sensors-service';
import {environment} from '../../../environments/environment';
import {Services} from '../../services';
import {of, Subscription} from 'rxjs';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  alarmStateData;
  currentDate;
  householdId;
  scoreTotal;
  availabilityData;
  consumptionIcon = 'defaultIcon'
  latestAlarmHistoryData: any = [];
  public canvasWidth = 380;
  needleValue = 0;
  public centralLabel = '';
  public name = '';
  householdFullName;
  householdAvatar;
  scoreTitle = 'Aktueller Status';
  scoreSubtitle = 'Keine nennenswerten Auffälligkeiten';
  userId;
  displayGauge = true;
  sensorData: any = [];
  history = [];
  url;
  scoreData;
  public bottomLabel = '';
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    // rangeLabel: ['0', '100'],
    needleStartValue: 50,
    arcColors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff',
      '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff',
      '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
    arcDelimiters: [1, 5, 10, 15, 20, 25, 30, 32, 40, 45, 50, 55, 60, 65, 67, 75, 80, 85, 90, 95, 99.99],
  };
  alarmStatuses = ['Alarm Handled', 'Alarm Forwarded', 'Called Emergency', 'Called Person', 'Alarm Ended', 'Alarm Started']

  subscriptions: Subscription

  constructor(public alarmStateService: AlarmStateService,
              private availabilityService: HannahAvailabilityService,
              private alarmLatestHistoryService: AlarmLatestHistoryService,
              private eventScoreService: EventScoreService,
              private householdService: HouseholdService,
              private zone: NgZone,
              private datePipe: DatePipe,
              private socketService: WebSocketService,
              private sharedService: SharedService, private sensorsService: SensorsService, private services: Services,
              ) {
                this.subscriptions = new Subscription
              }

  updateNeedle(value) {
    this.needleValue = value;
  }

  private _opened: boolean = false;

  private _toggleSidebar() {
    this._opened = !this._opened;
  }


  ngOnInit() {
    this.householdId = localStorage.getItem('household_id');
    this.getUpdatedData();
    const updatedHistoryData = this.sharedService.getUpdatedHistoryData().subscribe(() => {
      this.alarmLatestHistoryService.getLatestHistory(this.householdId).subscribe(response => {
        console.log('DOOOONCHEEEEE111', response)
        this.latestAlarmHistoryData = response;
        console.log('%clatest history data', this.latestAlarmHistoryData, 'color: green;');
      });
    });
    this.subscriptions.add(updatedHistoryData)

    const sensors =this.sensorsService.getSensors().subscribe(response => {
      this.sensorData = response;
    });
    this.subscriptions.add(sensors)

    const updatedHousehold = this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.getUpdatedData();
    });
    this.subscriptions.add(updatedHousehold)

  }

  getUpdatedData() {
    this.currentDate = this.datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm')
    this.householdFullName = localStorage.getItem('household_fullName');
    this.householdAvatar = localStorage.getItem('household_avatar');
    this.householdId = localStorage.getItem('household_id');
    this.url = environment.imagesApi;
    const sensors = this.sensorsService.getSensors().subscribe(response => {
      this.sensorData = response;
      console.log(response);
    });
    this.subscriptions.add(sensors)

    const sharedAlarmData = this.alarmStateService.alarmState.subscribe(response => {
      this.zone.run(() => {
        this.alarmStateData = response;
        console.log('alarm state data', response, this.alarmStateData);
      });
    });
    this.subscriptions.add(sharedAlarmData)

    const hannahStateData = this.availabilityService.availabilityState.subscribe(response => {
      this.zone.run(() => {
        this.availabilityData = response;
      });
    });
    this.subscriptions.add(hannahStateData)

    this.availabilityService.getAvailability(this.householdId);
    const latestHistory = this.alarmLatestHistoryService.getLatestHistory(this.householdId).subscribe(response => {
      this.latestAlarmHistoryData = response;
    });
    this.subscriptions.add(latestHistory)

    this.alarmStateService.checkAlarmState(this.householdId);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
