import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-remove-account-popup',
  templateUrl: './remove-account-popup.component.html',
  styleUrls: ['./remove-account-popup.component.scss']
})
export class RemoveAccountPopupComponent implements OnInit {
  householdId;
  removeFully;
  buttonTriggered = false;
  constructor(private refModal: BsModalRef, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
  }
  closeModal() {
    this.refModal.hide();
  }
  removeAccount() {
    this.buttonTriggered = true;
    this.http.delete(`${environment.api}remove-my-account/${this.householdId}`).subscribe(response => {
      this.buttonTriggered = false;
      this.closeModal();
      localStorage.clear();
      this.router.navigateByUrl('/login');
    }, error => {
      console.log('error', error);
      this.buttonTriggered = false;
    });
  }

  removeAccountFully() {
    this.buttonTriggered = true;
    this.http.delete(`${environment.api}delete-user-permanently`).subscribe(response => {
      this.buttonTriggered = false;
      this.closeModal();
      localStorage.clear();
      this.router.navigateByUrl('/login');
    }, error => {
      console.log('error', error);
      this.buttonTriggered = false;
    });
  }
}
