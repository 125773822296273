import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {SharedService} from '../../../services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error;
  loginForm: FormGroup;
  isFocused = false;
  someValue: string;
  showError = false;
  errorMessage;
  loggedOutDueToInactivity = false
  constructor(private authService: AuthService, private formBuilder: FormBuilder,
              private router: Router, private http: HttpClient, private sharedService: SharedService) {
  }

  ngOnInit() {
    if(localStorage.getItem('logOut_dueTo_Inactivity')){
      this.loggedOutDueToInactivity = true
      localStorage.removeItem('logOut_dueTo_Inactivity')
    }
    
    this.sharedService.getUpdatedErrorData().subscribe(response => {
      console.log('response from error 401', response);
      this.errorMessage = 'Passwort falsch oder Email falsch';
      this.showError = true;
    });
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['',]
    });
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    console.log('%c error message', 'color: red;', this.errorMessage);
    this.authService.login(
      {
        email: this.f.email.value,
        password: this.f.password.value,
        web: 1
      }
    )
      .subscribe(success => {
          console.log('success', success)
          if (success) {
            this.router.navigate(['/household-select']);
          }
        }, err => this.error = 'Could not authenticate'
      );
  }
  resetErrors(){
    console.log('changed');
    this.showError = false;
  }
}
