import {Component, OnInit, OnDestroy} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Services} from '../../services';
import {UserRegisterService} from '../../services/user-register.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {PassingDataService} from '../../services/passing-data.service';

declare var $: any;

@Component({
  selector: 'app-notfallkontakt-anlegen',
  templateUrl: './notfallkontakt-anlegen.component.html',
  styleUrls: ['./notfallkontakt-anlegen.component.scss']
})
export class NotfallkontaktAnlegenComponent implements OnInit, OnDestroy {
  formGroup;
  formData;
  public imagePath;
  url: string | ArrayBuffer;

  imgURL: any;
  image;
  filedata: any;
  selected = 1;
  headers = new HttpHeaders();
  fileToUpload: File = null;
  imgLink: any;
  imgData: File = null;
  logo: any;
  selectedOption: string;
  selectedGender: string = null;
  selectedFile: File = null;
  selectedGenderId;
  responseError;
  userData: any;
  userType: any;
  token: any;
  userEmail: any;
  termsChecked = false;
  avatarExists = false;
  avatarImg;
  imageSrc;
  imageUploaded = false;
  genderArray: any = [
    {
      id: 0, name: 'Bitte wählen', state: true
    },
    {
      id: 1, name: 'Frau', state: false
    },
    {
      id: 2, name: 'Herr', state: false
    },
  ];

  password_validation_messages = [
    {
      type: 'minlength', 
      message: 'Password must be at least 8 characters long' 
    },
    {
      type: 'maxlength', 
      message: 'Password must be at most 25 characters long' 
    },
    {
      type: 'pattern', 
      message: 'Your password must contain at least one uppercase, one lowercase, one number, and one special character' 
    }
  ]

  checkUser = false
  hidePassword = true
  hideCPassword = true

  constructor(private services: Services,
              public RefEC: BsModalRef,
              private http: HttpClient,
              private route: ActivatedRoute,
              private userRegisterService: UserRegisterService,
              private toasterService: ToastrService,
              private translate: TranslateService,
              private router: Router,
              private passingData: PassingDataService
  ) {
  }

  ngOnInit(): void {
    const dragAndDrop = $('.dropify').dropify({
      messages: {
        default: 'Neues Profilbild hochladen',
        replace: 'Profilbild ersetzen',
        remove: 'Entfernen',
        error: 'Leider ist etwas schief gelaufen!'
      }
    });
    this.formGroup = new FormGroup({
      gender: new FormControl(''),
      name: new FormControl('', [
        Validators.required
      ]),
      surname: new FormControl('', [
        Validators.required
      ]),
      mobile_number: new FormControl('', [
        Validators.required, Validators.pattern('((^[+][0-9]{10,19}))')
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      streetname: new FormControl(''),
      townname: new FormControl(''),
      housingnumber: new FormControl(''),
      zipcode: new FormControl(''),
      password: new FormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.required,
        Validators.pattern(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.\\[@$!\\]&;§%<>:*=\\\\,+_\\-"'#|~?&(){}\\/])[a-zA-Z0-9.@$!%*"'#\\[,§(+)\\]<=>;\\-_&{:\\\\~}|?&\\/]+$`) // includes UpperCase, LoweCase, Number and Special Character
      ])),

      password_confirmation: new FormControl('', [
        Validators.required,
      ]),
      terms: new FormControl(false, [
        Validators.requiredTrue,
      ])
    },
    {validators:[this.checkPasswords]});
  
    this.findUser(); // find user from token
    // this.addHandler(this.userData);
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('password_confirmation').value
    return pass === confirmPass ? null : { notSame: true }
  }

  isPassConfirmFieldValid(field: string) {
    
    const pass = this.formGroup.get('password').value;
    const confirmPass = this.formGroup.get(field).value;
    const match:boolean = pass === confirmPass
    return (!this.formGroup.get(field).valid && this.formGroup.get(field).touched) || (!match && this.formGroup.get(field).touched);
  }

  resetConfirm(){
    this.formGroup.patchValue({
      retype_password: ''
    })
  }

  findUser() {
    this.route.params.subscribe(params => {

      this.token = params.token;
      this.http.get(`${environment.api}invites/invite-allowed/${this.token}`).subscribe({
        next:(response) =>{
          console.log('response for invite token: ', response);
          this.http.post(`${environment.api}is-user-registered`, {token:this.token}).subscribe({
            next: (response)=>{
              console.log(response)
              if(response['message']=='User is registered'){
                this.router.navigateByUrl(`position-confirmed/${this.token}`);
              }
              else{
                this.findUserfromToken(this.token);
                this.checkUser = true
              }
            }
          })
        },
        error: (error) =>{
          if(error.error['Message'] ==  'Token expired'){
            console.log(error.error['Message'])
            this.router.navigateByUrl(`invalid-invitation/1`);
          }
          else{
            console.log(error.error['Message'])
            this.router.navigateByUrl(`invalid-invitation/0`);
          }
          console.log('error on token invite: ', error.error);
        }
      })      
    });
  }

  showSuccess(message, title) {
    this.toasterService.success(message, title)
  }

  showError(message, title) {
    this.toasterService.error(message, title)
  }

  showInfo(message, title) {
    this.toasterService.info(message, title)
  }

  showWarning(message, title) {
    this.toasterService.warning(message, title)
  }

  findUserfromToken(token) {
    console.log('tokeni', token);
    this.userRegisterService.findUserfromToken(token).subscribe(res => {
      console.log('user from token notfall kontakt: ', res);
      this.saveUserData(res);
    });
  }

  saveUserData(data) {
    this.userData = data.user;
    this.userType = data.user_type;
    this.userEmail = data.user.email;
    this.addHandler(this.userData);
    console.log('data notfallkontakt user token', data);
  }

  onFileSelected(event) {
    this.filedata = event.target.files[0];
  }

  public isChecked(event) {
    this.termsChecked = !this.termsChecked;
  }

  public addHandler(userData) {

    this.userData = userData;
    console.log('userData', userData);

    if (this.userData.gender == 'female') {
      this.selectedGender = 'Frau';
    } else if (this.userData.gender == 'male') {
      this.selectedGender = 'Herr';
    }

    this.formGroup = new FormGroup({
      gender: new FormControl(userData.gender),
      name: new FormControl(userData.name, [
        Validators.required
      ]),
      surname: new FormControl(userData.surname, [
        Validators.required
      ]),
      mobile_number: new FormControl(userData.mobile_number, [
        Validators.required, Validators.pattern('((^[+][0-9]{10,19}))')
      ]),
      email: new FormControl(userData.email, [
        Validators.required,
        Validators.email
      ]),
      streetname: new FormControl(''),
      townname: new FormControl(''),
      housingnumber: new FormControl(''),
      zipcode: new FormControl(''),
      password: new FormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.required,
        Validators.pattern(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.\\[@$!\\]&;§%<>:*=\\\\,+_\\-"'#|~?&(){}\\/])[a-zA-Z0-9.@$!%*"'#\\[,§(+)\\]<=>;\\-_&{:\\\\~}|?&\\/]+$`) // includes UpperCase, LoweCase, Number and Special Character
      ])),

      password_confirmation: new FormControl('', [
        Validators.required,
      ]),
      terms: new FormControl(false, [
        Validators.requiredTrue,
      ])
    },
    {validators:[this.checkPasswords]});

    this.formGroup.patchValue({
      name: this.userData.name
    })

  }

  handleFile(e) {
    console.log('files', e);
  }

  handleFileInput(files: FileList) {
    console.log('files', files);
    this.fileToUpload = files.item(0);
    const file = files[0];

    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;

    reader.readAsDataURL(file);
    this.imageUploaded = true;
  }

  saveHandler() {
    // this.formGroup.patchValue({
    //   gender: (this.selectedGenderId == 2 ? 'male' : 'female'),
    // });
    // if (this.selectedGender == 'Frau') {
    //   this.selectedGender = 'female';
    // } else if (this.selectedGender == 'Herr') {
    //   this.selectedGender = 'male';
    // }

    let myFormData = new FormData();
    if (this.formGroup.value.password == this.formGroup.value.password_confirmation) {
      const item = {
        // id: this.formGroup.value.id,
        id: this.userData.id,
        user_type: this.userType,
        name: this.formGroup.value.name,
        surname: this.formGroup.value.surname,
        gender: (this.formGroup.value.gender == 'Herr' || this.formGroup.value.gender == 'male') ? 'male' : 'female',
        mobile_number: this.formGroup.value.mobile_number,
        email: this.formGroup.value.email,
        password: this.formGroup.value.password,
        avatar_img: this.filedata,
        token: this.token,
        townname: this.formGroup.value.townname,
        streetname: this.formGroup.value.streetname,
        housingnumber: this.formGroup.value.housingnumber,
        zipcode: this.formGroup.value.zipcode,
      };
      this.userRegisterService.registerUser(this.fileToUpload, item).subscribe((response) => {// this.showSuccess(this.translate.instant('toasterMessages.createAccountSuccess'), this.translate.instant('toasterMessages.success'));
          this.passingData.shareUserDataState(this.userType);
          this.router.navigateByUrl('/position-confirmed');
          console.log('response', response);
          // this.closeModal();
        }, error => {
          console.log('error', error);
          this.showError(this.translate.instant('toasterMessages.createAccountError'),
            this.translate.instant('toasterMessages.error'));
          this.responseError = error;
        }
      );
    } else {
      this.showInfo(this.translate.instant('toasterMessages.resetPasswordWarning'), this.translate.instant('toasterMessages.warning'))
    }
  }


  selectGender(id: any) {
    this.selectedGenderId = id;
    if (id == 0) {
    } else {
      this.selectedGender = this.genderArray[id].name;
      this.formGroup.patchValue({
        gender: this.selectedGender
      });
    }
  }

  //
  // closeModal() {
  //   this.RefEC.hide();
  // }

  isFieldValid(field: string) {
    return !this.formGroup.get(field).valid
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('notfallkontakt');
  }

  // removeAvatar() {
  //   this.avatarExists = false;
  //   console.log('removing avatar', this.avatarExists);
  // }

  // addAvatar() {
  //   if (this.imageUploaded) {
  //     this.avatarExists = true;
  //   }
  //   console.log('removing avatar', this.avatarExists);
  // }
  togglePasswordShow(){
    this.hidePassword = !this.hidePassword
  }
  toggleCPasswordShow(){
    this.hideCPassword = !this.hideCPassword
  }
}
