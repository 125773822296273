<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>Rechnungen</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a>
              </li>
              <li class="breadcrumb-item active">Rechnungen
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">

        <div class="row mb-1 mt-1">
          <div class="col s12 m12 l12">
            <div id="responsive-table-desktop" class="card card-default hide-on-small-and-down">
              <div class="card-content">
                <p class="mb-1"><b>Filteroptionen</b></p>
                <div class="row filter-options">
                  <div class="input-field col m3 l2 s12">
                    <select>
                      <option value="" disabled selected>Bitte wählen</option>
                      <option value="1">Juni</option>
                      <option value="2">Mai</option>
                      <option value="3">April</option>
                      <option value="4">März</option>
                      <option value="5">Februar</option>
                      <option value="6">Januar</option>
                    </select>
                    <label>Monat</label>
                  </div>
                  <div class="input-field col m3 l2 s12">
                    <select>
                      <option value="" disabled selected>Bitte wählen</option>
                      <option value="1">2020</option>
                      <option value="2">2019</option>
                      <option value="3">2018</option>
                    </select>
                    <label>Jahr</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col s12">
                    <h4 class="card-title">Übersicht der letzten Rechnungen</h4>
                  </div>
                  <div class="col s12">
                    <table>
                      <thead>
                      <tr>
                        <th data-field="invoice-number">Rechnungsnummer</th>
                        <th data-field="invoice-date">Rechnungsdatum</th>
                        <th data-field="email">Abrechnungszeitraum</th>
                        <th data-field="invoice-period">Rechnungsbetrag</th>
                        <th data-field="actions" class="right-align">Aktionen</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>3-15032020</td>
                        <td>15.03.2020</td>
                        <td>15.03. - 14.04.2020</td>
                        <td>23,00 €</td>
                        <td class="right-align"><a href="#"><i class="notranslate material-icons grey-text">file_download</i></a><a href="#"><i class="notranslate material-icons">search</i></a></td>
                      </tr>
                      <tr>
                        <td>3-15022020</td>
                        <td>15.02.2020</td>
                        <td>15.02. - 14.03.2020</td>
                        <td>23,00 €</td>
                        <td class="right-align"><a href="#"><i class="notranslate material-icons grey-text">file_download</i></a><a href="#"><i class="notranslate material-icons">search</i></a></td>
                      </tr>
                      <tr>
                        <td>3-15012020</td>
                        <td>15.01.2020</td>
                        <td>15.01. - 14.02.2020</td>
                        <td>23,00 €</td>
                        <td class="right-align"><a href="#"><i class="notranslate material-icons grey-text">file_download</i></a><a href="#"><i class="notranslate material-icons">search</i></a></td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="row">
                      <div class="col mt-1 s12 m6">Rechnungen 1-3 von 3</div>
                      <div class="col mt-1 s12 m6 right-align">Seite 1 von 1</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="responsive-table-mobile" class="card card-default hide-on-med-and-up">
              <div class="card-content">
                <div class="row">
                  <div class="col s12">
                    <h4 class="card-title">Übersicht der letzten Rechnungen</h4>
                  </div>
                  <div class="col s12">
                    <table class="no-border">
                      <tbody>
                      <tr>
                        <td>
                          <div class="actions"><a href="#"><i class="notranslate material-icons grey-text">file_download</i></a><a href="#"><i class="notranslate material-icons">search</i></a></div>
                          <div class="data">
                            <div>Rechnungsnummer:</div>
                            <div style="margin-bottom:10px;font-size:20px"><b>3-15032020</b></div>
                            Rechnungsdatum: <b>15.03.2020</b><br>
                            Abrechnungszeitraum: <b>15.03. - 14.04.2020</b><br>
                            Rechnungsbetrag: <b>23,00 €</b><br>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="no-border">
                      <tbody>
                      <tr>
                        <td>
                          <div class="actions"><a href="#"><i class="notranslate material-icons grey-text">file_download</i></a><a href="#"><i class="notranslate material-icons">search</i></a></div>
                          <div class="data">
                            <div>Rechnungsnummer:</div>
                            <div style="margin-bottom:10px;font-size:20px"><b>3-15022020</b></div>
                            Rechnungsdatum: <b>15.02.2020</b><br>
                            Abrechnungszeitraum: <b>15.02. - 14.03.2020</b><br>
                            Rechnungsbetrag: <b>23,00 €</b><br>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="no-border">
                      <tbody>
                      <tr>
                        <td>
                          <div class="actions"><a href="#"><i class="notranslate material-icons grey-text">file_download</i></a><a href="#"><i class="notranslate material-icons">search</i></a></div>
                          <div class="data">
                            <div>Rechnungsnummer:</div>
                            <div style="margin-bottom:10px;font-size:20px"><b>3-15012020</b></div>
                            Rechnungsdatum: <b>15.01.2020</b><br>
                            Abrechnungszeitraum: <b>15.01. - 14.02.2020</b><br>
                            Rechnungsbetrag: <b>23,00 €</b><br>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="row mt-4">
                      <div class="col mt-1 s12 center-align">Rechnungen 1-3 von 3</div>
                      <div class="col mt-1 s12 center-align">Seite 1 von 1</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->
