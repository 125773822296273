import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AlarmService {
    constructor(private http: HttpClient) {
  }
  getConsumption(hid, fromDate, toDate, type) {
    return this.http.get(`${environment.api}${type}/${hid}?toDate=${fromDate}`).pipe();
  }

  getHistory(householdId, fromDate, toDate, currentPage, type, previousPage, nextPage, resIds) {
    if (nextPage == undefined) {
      nextPage = 0;
    }
    if (previousPage == undefined) {
      previousPage = 0;
    }
    let data;
    fromDate = fromDate;
    toDate = toDate;
    console.log('current page in service', currentPage);
    console.log('latest id in service', nextPage);
    if (currentPage === 0) {
      data = {
        householdId,
        fromDate,
        toDate,
        type,
        page: currentPage,
        previousId: previousPage,
        latestId: nextPage,
        ids: []
      };
    } else {
      data = {
        householdId,
        fromDate,
        toDate,
        type,
        page: currentPage,
        previousId: previousPage,
        latestId: nextPage,
        ids: resIds
      };
    }
    console.log('data in service', data);
    return this.http.post(`${environment.api}get-history-by-date`, data).pipe(map(response => {
      return response;
    }));
  }
}
