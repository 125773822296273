<li class="nav-item" *ngFor="let path of routingPaths">
    <a *ngIf="path.id === 3 && path.isAuthorized"  (click)="collapseAlarmTabs()" class="waves-effect waves-cyan tabStyle">
      <i class="material-icons notranslate">{{path.icon}}</i>
      <span class="menu-title">{{'tabs.alarmStatus' | translate}}</span>
      <i *ngIf="!collapse" class="material-icons float-right notranslate">keyboard_arrow_right</i>
      <i *ngIf="collapse" class="material-icons float-right notranslate">keyboard_arrow_down</i>
    </a>
    <ul class="" *ngIf="collapse && path.id === 3 && path.isAuthorized" style="background-color: #f6f6f6">
      <li *ngFor="let underPath of path.underTabs"><a class="tabStyle navigate" routerLink="{{underPath.path}}" routerLinkActive="active">
        <i class="material-icons tab-icons-custom notranslate">{{underPath.icon}}</i><span
        data-i18n="Historie" class="navigate">{{'tabs.' + underPath.name | translate}}</span></a></li>
    </ul>
  <a *ngIf="path.id !== 3 && path.isAuthorized" class="waves-effect waves-cyan tabStyle navigate" routerLink="{{path.path}}" routerLinkActive="active">
    <!--    <mat-icon class="material-icons" aria-hidden="false">{{path.icon}}</mat-icon>-->
    <i class="material-icons notranslate navigate">{{path.icon}}</i>
    <span class="menu-title navigate"> {{'tabs.' + path.name | translate}}</span>
  </a>
</li>
