import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hannah-config',
  templateUrl: './hannah-config.component.html',
  styleUrls: ['./hannah-config.component.scss']
})
export class HannahConfigComponent implements OnInit {
  checkUser = false;
  loadingPage: boolean = true;
  validLink: boolean = false;
  householdId;
  sendingEmail: boolean = false;
  emailSent: boolean = false;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.householdId = localStorage.getItem('household_id');
    this.route.queryParams.subscribe(params => {
      let expires = params['expires'];
      let signature = params['signature'];

      this.http.get(`${environment.api}validate-wifi-url/${this.householdId}?expires=${expires}&signature=${signature}`).subscribe({
        next:(res:any) => {
          this.loadingPage = false;

          if(res.message == "Link is valid"){
            this.validLink = true;
          }else if(res.message == "Link expired!"){
            this.validLink = false;
          }
        },error: (error:any) => {
          console.log('erorrrr', error);
          this.validLink = false;
        }
      });
    });
  }

  resendEmail(){
    this.sendingEmail = true;

    this.http.post(environment.api + 'send-wifi-email', {
      household_id: this.householdId,
    }).subscribe((response:any) => {
      if(response.code == 200){
        this.sendingEmail = false;
        this.emailSent = true;
      }
    });
  }

}
