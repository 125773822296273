import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from './services/auth.service';
import {SharedService} from '../services/shared.service';
import {Router} from '@angular/router';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService, private sharedService: SharedService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    return next.handle(request).pipe(catchError(error => {
      console.log('REQUEST IN INTERCEPTOR', request);
      if (error instanceof HttpErrorResponse && error.status === 401) {
        localStorage.clear();
        this.router.navigateByUrl('login');
        console.log('error message in interceptor: ', error);
        if (error.error.detail === 'Authentication failed') {
          console.log('error message asd', error.error.error);
          // this.authService.setErrorMessage('Passwort falsch oder Email falsch')
          this.sharedService.sendUpdatedErrorData();
        }
        // return this.handle401Error(request, next);
      } else {
        console.log('errorr', error);
        
      }
      return throwError(()=>(error))
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (!request.url.includes('user_refresh')) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else if (request.url.includes('user_refresh')){
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN_REFRESH')}`
        }
      });
    }
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);
  //     //
  //     // return this.authService.refreshToken().pipe(
  //     //   switchMap((token: any) => {
  //     //     this.isRefreshing = false;
  //     //     this.refreshTokenSubject.next(token.jwt);
  //     //     return next.handle(this.addToken(request, token.jwt));
  //     //   }));
  //
  //   } else {
  //     return this.refreshTokenSubject.pipe(
  //       filter(token => token != null),
  //       take(1),
  //       switchMap(jwt => {
  //         return next.handle(this.addToken(request, jwt));
  //       }));
  //   }
  // }
}
