<div *ngIf="checkUser" class="register-main">
  <!-- BEGIN: Header-->
  <div class="page-topbar" id="header">
    <div class="navbar navbar-fixed">
      <nav class="navbar-main red no-shadow">
      </nav>
    </div>
  </div>
  <!-- END: Header-->

  <!-- BEGIN: Page Main-->
  <div class="row">
    <div class="col s12 m10 offset-m1 l8 offset-l2">
      <div class="container">
        <div class="row mb-1">
          <div class="col s12">
            <div class="right-align"><img src="assets/images/logo/hannah-logo.svg" alt="HANNAH" class="hannah-logo"/>
            </div>
            <div class="clearfix"></div>
            <div id="notfallkontakt-anlegen" class="card card card-default mt-3">
              <div class="card-content">
                <h4 class="card-title">{{'registerContact.title' | translate}}
                  <a class="tooltipped fixedheadline" data-position="bottom"
                     data-tooltip="Der klassische Hannah-Notruf bietet...">
                    <i class="material-icons notranslate">help</i></a></h4>
                <form [formGroup]="formGroup">
                  <div class="row">
                    <div class="col-md-7 s12">
                      <div class="input-field pb-2">
                        <div class="" dropdown>
                          <div class="">
                            <label>{{'registerContact.other' | translate}}</label>
                            <input id="button-disabled-item-1" formControlName="gender" dropdownToggle type="button"
                                   aria-controls="dropdown-disabled-item-1" type="text" [value]="selectedGender"
                                   class="form-control dropdown-toggle">
                          </div>
                          <ul id="dropdown-disabled-item-1" *dropdownMenu
                              class="dropdown-menu custom-option"
                              role="menu" aria-labelledby="button-disabled-item-1">
                            <li [value]="item.id" *ngFor="let item of genderArray" role="menuitem"
                                [ngClass]="item.state ? 'disabled' : 'dropdown-item'" (click)="selectGender(item.id)">
                              <a class="dropdown-item">{{item.name}}</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="input-field pb-2">
                        <input id="first_name_edit" type="text" formControlName="name">
                        <label for="first_name_edit" class="active">{{'global.firstName' | translate}}</label>
                        <p *ngIf="isFieldValid('name')" class="materialize-red-text">{{'validation.name' | translate}}</p>
                      </div>
                      <div class="input-field">
                        <input id="last_name_edit" type="text" formControlName="surname">
                        <label for="last_name_edit" class="active">{{'global.lastName' | translate}}</label>
                        <p *ngIf="isFieldValid('surname')" class="materialize-red-text">{{'validation.surname' | translate}}</p>
                      </div>
                    </div>
                    <div id="" class="input-field col-md-5 col-sm-4 text-center">
                      <img src="../../../assets/images/avatar/user1.png" *ngIf="!imageSrc && selectedGender!='Frau'" class="imageUploaded" height="185px">
                      <img src="../../../assets/images/avatar/female-avatar.png" *ngIf="!imageSrc && selectedGender=='Frau'" class="imageUploaded" height="185px">
                      <img [src]="imageSrc" *ngIf="imageSrc" class="imageUploaded">
                      <input (change)="handleFileInput($event.target.files)" #profilePicture style="display: none;" type="file" id="input-file-now2" class="dropify form-control-file" data-show-remove="false" data-height="200"  accept="image/png, image/jpeg" data-default-file="" />
                      <button class="btn mt-4" (click)="profilePicture.click()">{{'global.uploadProfilePicture' | translate}}</button>


                     <!-- <div class="product" on-mouseover="removeAvatar()" on-mouseout="addAvatar()">
                        <div class="edit-avatar" *ngIf="avatarExists">
                          <img (click)="fileInput.click()" [src]="imageSrc"
                               class="edit-avatar-img"/>
                        </div>
                        <div class="upload-image-content">

                          <div class="row text-center">
                            <i class="material-icons text-center upload-cloud">
                              cloud_upload
                            </i>
                            <h6 class="upload-text">{{'global.editProfilePicture' | translate}}</h6>
                          </div>
                        </div>
                        <div class="product-hover" (click)="fileInput.click()">
                          <input
                            style="display: none" (change)="handleFileInput($event.target.files)"
                            type="file"
                            accept="image/png, image/jpeg"
                            #fileInput>
                          <div class="upload-image-box">
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div id="responsive-table-mobile" class="input-field col m5 s12 hide-on-med-and-up">
<!--                      <div class="product-responsive" on-mouseover="removeAvatar()" on-mouseout="addAvatar()">-->
<!--                        <div class="edit-avatar" *ngIf="avatarExists">-->
<!--                          <img (click)="fileInput.click()" [src]="imageSrc"-->
<!--                               class="edit-avatar-img"/>-->
<!--                        </div>-->
<!--                        <div class="upload-image-content-responsive">-->

<!--                          <div class="row text-center">-->
<!--                            <i class="material-icons text-center upload-cloud">-->
<!--                              cloud_upload-->
<!--                            </i>-->
<!--                            <h6 class="upload-text">{{'global.editProfilePicture' | translate}}</h6>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="product-hover" (click)="fileInput.click()">-->
<!--                          <input-->
<!--                            style="display: none" (change)="handleFileInput($event.target.files)"-->
<!--                            type="file"-->
<!--                            #fileInput>-->
<!--                          <div class="upload-image-box">-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <!--                  <div class="row">-->
                  <!--                    <div class="input-field col s12 m7">-->
                  <!--                      <input id="street_edit" type="text" value="">-->
                  <!--                      <label for="street_edit" class="active">Straße</label>-->
                  <!--                    </div>-->
                  <!--                    <div class="input-field col s12 m5">-->
                  <!--                      <input id="number_edit" type="text" value="">-->
                  <!--                      <label for="number_edit" class="active">Hausnummer</label>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="row">
                    <div class="input-field col s12">
                      <a class="tooltipped fixedinput" data-position="bottom"
                         data-tooltip="Die Handynummer muss identisch mit der Nummer sein, auf welcher die App geladen wird."><i
                        class="material-icons notranslate">help</i></a>
                      <input id="phone_edit" type="text" formControlName="mobile_number">
                      <label for="phone_edit" class="active">{{'global.mobileNumber' | translate}}</label>
                      <p *ngIf="isFieldValid('mobile_number')" class="materialize-red-text">{{'validation.mobileNumber' | translate}}</p>
                    </div>
                  </div>
                  <div class="row" *ngIf="userType !== 'normal'">
                    <div class="input-field col s12 m8">
                      <input id="street_edit" type="text" value="" formControlName="streetname">
                      <label for="street_edit" class="active">{{'global.street' | translate}}</label>
                    </div>
                    <div class="input-field col s12 m4">
                      <input id="number_edit" type="text" value="" formControlName="housingnumber">
                      <label for="number_edit" class="active">{{'global.houseNumber' | translate}}</label>
                    </div>
                  </div>
                  <div class="row" *ngIf="userType !== 'normal'">
                    <div class="input-field col s12 m8">
                      <input id="townname_edit" type="text" value="" formControlName="townname">
                      <label for="townname_edit" class="active">{{'global.townName' | translate}}</label>
                    </div>
                    <div class="input-field col s12 m4">
                      <input id="zipcode_edit" type="text" value="" formControlName="zipcode">
                      <label for="zipcode_edit" class="active">{{'global.zipCode' | translate}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col s12">
                      <input readonly id="email_edit" formControlName="email">
                      <label for="email_edit" class="active">{{'global.email' | translate}}</label>
                      <p *ngIf="isFieldValid('email')" class="materialize-red-text">{{'validation.emailAddress' | translate}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col s12 m6 position-relative">
                      <label for="password0" class="active">{{'global.password' | translate}}</label>
                      <input class="validate password" required id="password0" [type]="hidePassword ? 'password' : 'text'" formControlName="password">
                      <span class="material-icons icon-position" (click)="togglePasswordShow()">{{hidePassword ? 'visibility_off' : 'visibility'}}</span>
                      <p *ngFor="let validation of password_validation_messages">
                        <span *ngIf="formGroup.get('password').hasError(validation.type) && (formGroup.get('password').touched)" class="materialize-red-text">{{'validation.'+validation.type | translate}}</span>
                      </p>
                      <p *ngIf="isFieldValid('password')" class="materialize-red-text">{{'validation.writePassword' | translate}}</p>
                      <small *ngIf="formGroup.get('password').hasError('required') && (formGroup.get('password').touched)"><b>{{'validation.passInfo' | translate}}</b></small>
                    </div>
                    <div class="input-field col s12 m6 position-relative">
                      <label for="cpassword0" class="active">{{'global.confirmPassword' | translate}}</label>
                      <input class="validate password" required id="cpassword0" [type]="hideCPassword ? 'password' : 'text'"
                             formControlName="password_confirmation">
                      <span class="material-icons icon-position" (click)="toggleCPasswordShow()">{{hideCPassword ? 'visibility_off' : 'visibility'}}</span>
                      <!-- <p *ngIf="isFieldValid('password_confirmation')" class="materialize-red-text">{{'validation.confirmPassword' | translate}}</p> -->
                      <p *ngIf="isPassConfirmFieldValid('password_confirmation')" class="materialize-red-text">{{'validation.repeatPassword' | translate}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col s12">
                      <p>
                        <label>
                          <input required class="validate" id="terms" type="checkbox"
                                 (change)="isChecked($event)" formControlName="terms">
                          <span [innerHtml]="'registerContact.description' | translate"></span>
                        </label>
                      </p>
                      <br/>
                      <div class="input-field" *ngIf="!termsChecked">
                        <label class="active">{{'registerContact.termsAndConditions' | translate}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="row">
                      <div class="input-field col s12">
                        <button class="btn btn-large waves-effect waves-light ml-1" type="submit"
                                name="action" (click)="saveHandler()"
                                [disabled]="!formGroup.valid">{{'registerContact.registerAndShare' | translate}}
                          <i class="material-icons notranslate right">send</i>
                        </button>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
<!--          <div class="col s12 mb-8">-->
<!--            <div class="card card-default contacts">-->
<!--              <div class="card-content">-->
<!--                <div class="row">-->
<!--                  <div class="col s12 mb-2">-->
<!--                    <h4 class="card-title">{{'registerContact.hannahAppDownload.title' | translate}}</h4>-->
<!--                  </div>-->
<!--                  <div class="col s12">-->
<!--                    <p class="mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod-->
<!--                      tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam-->
<!--                      et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem-->
<!--                      ipsum dolor sit amet. </p>-->
<!--                    <div class="actions">-->
<!--                      <a class="waves-effect waves-light btn" href="#">{{'registerContact.hannahAppDownload.buttonTitle' | translate}}</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Page Main-->
</div>
