import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SensorsService {
  constructor(private http: HttpClient) {

  }

  getSensors() {
    const data: any = [];

    const householdId = localStorage.getItem('household_id');
    return this.http.get(`${environment.api}get-sensor-data/${householdId}`).pipe(map((response: any) => {
      for (const x in response.result) {
        // response.result[x].sensor_art = response.result[x].sensor_art.replace(/[_]+/g,' ')
        data.push(response.result[x]);
      }
      return response.result;
    }));
  }
}
