import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UploadService {
  constructor(private http: HttpClient) {

  }

  postFile(fileToUpload: File, item): Observable<any> {
    console.log('data after saving', fileToUpload);
    const endpoint = `${environment.api}edit-user`;
    const formData: FormData = new FormData();
    if (fileToUpload !== null) {
      formData.append('avatar_img', fileToUpload, fileToUpload.name);
    }
    formData.append('id', item.id);
    // formData.append('name', item.name);
    // formData.append('surname', item.surname);
    // formData.append('mobile_number', item.mobile_number);
    // formData.append('gender', item.gender);
    // formData.append('email', item.email);
    formData.append('household_id', item.household_id);
    formData.append('user_type',item.user_type)
    // formData.append('housingnumber', item.housingnumber);
    // formData.append('townname', item.townname);
    // formData.append('streetname', item.streetname);
    // formData.append('zipcode', item.zipcode)
    return this.http
      .post(endpoint, formData)
      .pipe(map(response => {
        return response;
      }))
  }
}
