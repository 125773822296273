import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  faqs = [
    {
      question:'Was bedeutet das LED- Licht auf dem mobilen Alarmsender?',
      answers:[
        'Die blaue LED zeigt die aktive GPS-Ortung an, so dass im Falle eines Notfalls der Standort des mobilen Alarmsenders angezeigt werden kann.',
        'Die grüne LED zeigt die Verbindung zum Mobilfunknetz an.'
      ]
    },
    
    {
      question:'Ich habe eine Alarmbenachrichtigung auf dem Handy erhalten, sehe den Alarm nach öffnen der App jedoch nicht?',
      answers:[
        'Unter Umständen kann es passieren, dass der Alarm nicht automatisch in der App angezeigt wird. Sie können den Alarm dennoch annehmen, wenn Sie auf der Startseite der App auf die rote Schrift „Alarm aktiv“ klicken. Dann erscheint die gewohnte Seite zur Annahme des Alarms'
      ]
    },

    {
      question:'Ich verpasse öfters den Alarm auf der App?',
      answers:[
        'Stellen Sie Ihr Handy auf laut, um einen Warnton bei einem aktiven Alarm zu erhalten.',
        'Ist Ihr Handy auf stumm geschaltet, erhalten Sie keinen Warnton im Falle eines Alarmes.',
        'Ist Ihr Handy auf Vibration geschaltet, erhalte Sie ein kurzes Vibrationssignal ähnlich wie bei dem Erhalt einer einfachen Nachricht. Dadurch ist es sehr einfach einen Alarm zu verpassen.'
      ]
    },

    {
      question:'Wie stelle ich die Lautstärke ein?',
      answers:[
        'Die Lautstärke lässt sich oben rechts auf Ihrer HANNAH über das Symbol „Lautstärke“ per Touch/ Klick verändern. Ziehen Sie nach links für leiser und nach rechts für lauter.'
      ]
    },

    {
      question:'Warum ist meine HANNAH ausgeschaltet (der Bildschirm ist schwarz)?',
      answers:[
        'Stecken Sie das Stromkabel ein. Der Akku lädt sich bei gestecktem Stromkabel selbstständig auf.'
      ]
    },

    {
      question:'Meine Hannah funktioniert nicht, was soll ich tun?\n(ich kann nicht anrufen, ich kann keinen Notruf absetzen, o.ä. )? ',
      additionalInfo:'Melden Sie sich im Zweifel bei Hannah & Swenn. Wenn Sie es sich zutrauen, führen Sie folgende Schritte durch:',
      answers:[
        'Überprüfen Sie die Stromzufuhr bei einem schwarzen Bildschirm.',
        'Überprüfen Sie die Internetverbindung. Wenn Sie keine Internetverbindung haben, sind viele Funktionen nur eingeschränkt nutzbar.',
        'Ist der Bildschirm eingefroren und reagiert nicht auf Berührungen aber das System hat eine Internetverbindung, dann trennen sie einmal kurz die Stromzufuhr. Das System startet automatisch neu.',
        'Sollten immer noch Probleme auftreten, melden Sie sich bei Hannah & Swenn.'
      ]
    },

    {
      question:'Warum kann ich die HANNAH telefonisch nicht erreichen?',
      answers:[
        'Die HANNAH Basisstation kann nur im Falle eines Alarms via Telefon angerufen werden.',
        'Außerhalb einer Alarmsituation nutzen Sie bitte die Videotelefonie',
        'Sollte die Videotelefonie nicht funktionieren, überprüfen Sie die Internetverbindung und führen Sie ggf. die Schritte unter FAQ Punkt 5 durch.'
      ]
    },

    {
      question:'Ist die HANNAH wasserdicht?',
      answers:[
        'Der Kontakt mit Wasser oder anderen Flüssigkeiten, sollte bei der HANNAH vermieden werden. Stellen Sie daher die HANNAH nicht in das Bad.'
      ]
    },
    
    {
      question:'Ist der mobile Handsender wasserdicht?',
      answers:[
        'Ja, der Handsender ist wasserdicht. Sie können ihn mit unter die Dusche oder sogar mit in die Badewanne nehmen bzw. auf dem Badewannenrand ablegen.'
      ]
    },

    {
      question:'Wo soll ich die HANNAH am besten aufstellen?',
      answers:[
        'Idealerweise an einen zentralen Standort in Ihrer Wohnung, z.B. in Ihrer Diele oder Ihrem Wohnzimmer. Um sich nachts, während Sie schlafen, sicher zu fühlen, empfehlen wir Ihnen, den mobilen Handsender mit an Ihr Bett zu nehmen. Sie können den Handsender auf Ihrem Nachttisch ablegen und in einer Notsituation darüber einen Alarm auslösen.'
      ]
    },

    {
      question:'Warum fragt HANNAH mich „Geht es dir gut?“',
      answers:[
        'Ihr persönlicher PASSIVSCHUTZ hat sich eingeschaltet und eine Unregelmäßigkeit in Ihrem täglichen bzw. regelmäßigen Tagesablauf festgestellt.',
        'Wenn Sie die Frage unbeantwortet lassen, gehen wir davon aus, dass etwas mit Ihnen nicht in Ordnung ist und Ihr Notfallkontakt bekommt eine Benachrichtigung, um Ihnen helfen zu können.',
        'Geht es Ihnen gut, so antworten Sie einfach mit „Ja“.'
      ]
    },

    {
      question:'Was ist der große, rote Knopf auf der HANNAH?',
      answers:[
        'Der große, rote Knopf auf der HANNAH ist Ihr persönlicher NOTRUF. Per Touch bzw. Klick auf den Knopf, lösen Sie einen Alarm aus und Ihr Notfallkontakt wird informiert.'
      ]
    },

    {
      question:'Was mache ich, wenn die HANNAH gar nicht mehr funktioniert und ich auch nicht mehr weiß, was ich tun soll?',
      answers:[
        'In dem Fall, erreichen Sie unsere Servicehotline telefonisch oder per Mail:',
        'Ansprechpartner: Jennifer Rosch',
        'Rufnummer: +49 (0) 160 188 3785',
        'E- Mail- Adresse: info@hannahundswenn.de',
        'Hotlinezeiten:  Montag bis Donnerstag, jeweils von 08:30 Uhr bis 13:30 Uhr'
      ]
    },

    {
      question:'Was kann ich tun, wenn die APP nicht mehr funktioniert?',
      answers:[
        'Wenn die APP gar nicht mehr reagiert, sollten Sie die APP löschen und neu installieren. In den meisten Fällen sollte sie dann wieder einwandfrei laufen.'
      ]
    },

    {
      question:'Warum erhalte ich keine Push- Benachrichtigung auf meinem Smartphone, wenn ein Alarm ausgelöst wird?',
      answers:[
        'Vermutlich haben Sie die die Einstellung „Mitteilungen erlauben“ nicht aktiviert.',
        'In Ihren Einstellungen auf Ihrem Smartphone können Sie diese Funktion aktivieren, damit sie im Notfall den Alarm direkt als Push- Up- Mitteilung erhalten.',
        'Für eine optimale Funktion der Push-Benachrichtigungen lassen Sie die HANNAH App im Hintergrund offen.'
      ]
    },

    {
      question:'Was mache ich, wenn ich das Passwort zur Webseite vergessen habe?',
      answers:[
        'Sie haben auf der Login- Seite die Möglichkeit, auf das Feld „Passwort vergessen“ zu gehen und dort ein neues Passwort anzufordern.',
        'Wenn Sie Passwort und E-Mailadresse vergessen haben, melden Sie sich bei Hannah & Swenn.'
      ]
    },

    {
      question:'Warum bin ich nicht mehr in der Alarmkette/ Eventkette hinterlegt?',
      answers:[
        'Um als Notfallkontakt in der Alarm- bzw. Eventkette hinterlegt zu sein, müssen Sie auch als Notfallkontakt der Alarm- bzw. Eventkette hinzugefügt werden. Diese Einstellung können Sie auf der Webseite unter „Alarmkette/ Eventkette bearbeiten“ vornehmen.'
      ]
    },

    {
      question:'Was muss ich tun, wenn ich kein Notfallkontakt mehr sein möchte?',
      answers:[
        'Sie haben 3 Möglichkeiten den eigenen Status für einen Haushalt zu ändern:',
      ],
      subAnswers:[
        'Kontaktieren Sie den Nutzer der HANNAH Basisstation, als Nutzer können Änderungen auf der Hannah Homepage vorgenommen werden.',
        'Kontaktieren Sie einen Admin für den Haushalt, auch dieser kann Änderungen vornehmen.',
        'Wenn Sie selber Admin für den Haushalt sind, können Sie unter „Alarmkette bearbeiten“ Änderungen auf der Homepage vornehmen.'
      ]
    },

    {
      question:'Welches Handy brauche ich, um Notfallkontakt sein zu können?',
      answers:[
        'Sie benötigen ein Smartphone mit Android- oder iOS- Betriebssystem, das nicht älter als 4 Jahre sein sollte.'
      ]
    },

    {
      question:'Wie viele Notfallkontakte können insgesamt hinterlegt werden?',
      answers:[
        'Sie können bis zu drei Notfallkontakte in der Alarm- und in der Eventkette hinterlegen.'
      ]
    },

    {
      question:'Was passiert, wenn keiner der hinterlegten Notfallkontakte erreichbar ist bzw. nicht handeln kann?',
      answers:[
        'In der ersten Testphase wird der letzte Notfallkontakt in der Alarmkette kontinuierlich an den bestehenden Alarm erinnert, bis die Notfallsituation geklärt ist.'
      ]
    }
  ]

  constructor(private http: HttpClient) { }

  getFAQs(){
    return of (this.faqs)
  }

  sendEmail(data){
    return this.http.post(`${environment.api}contact-form`, data)
  }
}
