import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PassingDataService {
  userData;
  userState = new Subject();
  //sharedUserData = this.userState.asObservable();
  constructor() {
  }

  nextAlarmState(data: any) {
    this.userState.next(data);
  }

  shareUserDataState(type) {
    this.userData = type;
    this.nextAlarmState(this.userData);
  }
}
