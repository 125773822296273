<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'dashboard.alarmHistory.title' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a></li>
              <li class="breadcrumb-item">{{'tabs.alarmStatus' | translate}}</li>
              <li class="breadcrumb-item active">{{'dashboard.alarmHistory.title' | translate}}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">
        <div class="row mt-1">
          <div class="col s12 mb-1">
            <div class="card card card-default contacts">
              <div class="card-content">
                <div class="row">
                  <div class="col s12">
                    <ul class="tabs mb-2">
                      <li class="tab col m3"><a [ngClass]="alarmHistory ? 'active active-history-tabs' : ''"
                                                (click)="setHistoryType(0)">{{'history.tabs.activeAlarm' | translate}}</a>
                      </li>
                      <li class="tab col m3"><a [ngClass]="!alarmHistory ? 'active active-history-tabs' : ''"
                                                (click)="setHistoryType(1)">{{'history.tabs.passiveAlarm' | translate}}</a>
                      </li>
                    </ul>
                    <div id="responsive-table-desktop" class="card card card-default contacts hide-on-small-and-down">
                      <div id="alarm-active" class="col s12">
                        <p class="mb-1"><b>{{'global.filterOptions' | translate}}</b></p>
                        <div class="row filter-options">
                          <div class="input-field col m3 l2 s12">
                            <input type="text" placeholder="Datum von" (ngModelChange)="fetchHistory(1, $event)"
                                   [(ngModel)]="fromDate" class="inputtypes form-control" [bsConfig]="datePickerConfig"
                                   [maxDate]="maxDate" bsDatepicker>
                            <label class="active">{{'global.dateFrom' | translate}}</label>
                          </div>
                          <div class="input-field col m3 l2 s12">
                            <input type="text" placeholder="Datum bis" class="inputtypes"
                                   (ngModelChange)="fetchHistory(2, $event)" [(ngModel)]="toDate"
                                   [bsConfig]="datePickerConfig" [maxDate]="maxDate" bsDatepicker>
                            <label class="active">{{'global.dateTo' | translate}}</label>
                          </div>
                          <!--                        <div class="input-field col m3 l2 s12">-->
                          <!--                          <label class="active">Uhrzeit von</label>-->
                          <!--                          <li class="btn-group " dropdown>-->
                          <!--                            &lt;!&ndash;                            <label class="active">Uhrzeit von</label>&ndash;&gt;-->
                          <!--                            &lt;!&ndash;                                 (ngModelChange)="fetchHistory(2, $event)" [(ngModel)]="toDate"&ndash;&gt;-->
                          <!--                            &lt;!&ndash;                                 [bsConfig]="datePickerConfig" [maxDate]="maxDate" bsDatepicker>&ndash;&gt;-->
                          <!--                            &lt;!&ndash;                            <label class="active">Uhrzeit bis</label>&ndash;&gt;-->
                          <!--                            <a id="button-animated-1" class=" dropdown-toggle" dropdownToggle-->
                          <!--                               data-target="profile-dropdown" aria-controls="dropdown-animated-1">-->
                          <!--                              <input [value]="fromHour" class="inputtypes form-control" type="text"-->
                          <!--                                     placeholder="Uhrzeit von"-->
                          <!--                                     [(ngModel)]="fromHour" type="text"/>-->
                          <!--                            </a>-->
                          <!--                            <ul *dropdownMenu-->
                          <!--                                class="dropdown-menu dropdown-left-manual custom-menu-dropdown-profile  custom-menu-dropdown"-->
                          <!--                                id="dropdown-animated-1"-->
                          <!--                                role="menu" aria-labelledby="button-animated-1">-->
                          <!--                              <li role="menuitem" *ngFor="let item of times" (click)="passFromHour(item)">-->
                          <!--                                <a class="dropdown-item grey-text text-darken-1">-->
                          <!--                                  &lt;!&ndash;                    <mat-icon>person_outline</mat-icon>&ndash;&gt;-->

                          <!--                                  <span class="">{{item}} Uhr</span>-->
                          <!--                                </a>-->
                          <!--                              </li>-->
                          <!--                            </ul>-->
                          <!--                          </li>-->

                          <!--                          &lt;!&ndash;                                 (ngModelChange)="fetchHistory(2, $event)" [(ngModel)]="toDate"&ndash;&gt;-->
                          <!--                          &lt;!&ndash;                                 [bsConfig]="datePickerConfig" [maxDate]="maxDate" bsDatepicker>&ndash;&gt;-->
                          <!--                        </div>-->
                          <!--                        <div class="input-field col m3 l2 s12">-->
                          <!--                          <label class="active">Uhrzeit bis</label>-->
                          <!--                          <li class="btn-group " dropdown>-->
                          <!--                            &lt;!&ndash;                                 (ngModelChange)="fetchHistory(2, $event)" [(ngModel)]="toDate"&ndash;&gt;-->
                          <!--                            &lt;!&ndash;                                 [bsConfig]="datePickerConfig" [maxDate]="maxDate" bsDatepicker>&ndash;&gt;-->
                          <!--                            &lt;!&ndash;                            <label class="active">Uhrzeit bis</label>&ndash;&gt;-->
                          <!--                            <a id="button-animated-2" class=" dropdown-toggle" dropdownToggle-->
                          <!--                               data-target="profile-dropdown" aria-controls="dropdown-animated-2">-->
                          <!--                              <input [value]="toHour" class="inputtypes form-control" type="" placeholder="Uhrzeit bis"-->
                          <!--                                     [(ngModel)]="toHour" type="text"/>-->
                          <!--                            </a>-->
                          <!--                            <ul *dropdownMenu-->
                          <!--                                class="dropdown-menu dropdown-left-manual custom-menu-dropdown-profile  custom-menu-dropdown"-->
                          <!--                                id="dropdown-animated-2"-->
                          <!--                                role="menu" aria-labelledby="button-animated-2">-->
                          <!--                              <li role="menuitem" *ngFor="let item of times" (click)="passToHour(item)">-->
                          <!--                                <a class="dropdown-item grey-text text-darken-1">-->
                          <!--                                  &lt;!&ndash;                    <mat-icon>person_outline</mat-icon>&ndash;&gt;-->

                          <!--                                  <span class="">{{item}} Uhr</span>-->
                          <!--                                </a>-->
                          <!--                              </li>-->
                          <!--                            </ul>-->
                          <!--                          </li>-->
                          <!--                        </div>-->
                        </div>
                        <h6>{{alarmMessage}}</h6>
                        <table *ngIf="alarmHistory && histories.length > 0">
                          <thead>
                          <tr>
                            <th data-field="avatar"></th>
                            <th data-field="name">{{'global.name' | translate}}</th>
                            <th data-field="event">{{'global.event' | translate}}</th>
                            <th data-field="date">{{'global.date' | translate}}</th>
                            <th data-field="actions" class="right-align"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <ng-container *ngFor="let h of histories">
                            <!--                          <tr *ngIf="h === null">-->
                            <!--                            <td *ngIf="householdAvatar == null || householdAvatar === undefined"><img-->
                            <!--                              class="avatar user-icon" src="assets/images/avatar/no-image.png" alt="avatar"-->
                            <!--                              class="avatar user-icon"/></td>-->
                            <!--                            <td *ngIf="householdAvatar !== null && householdAvatar !== undefined"><img-->
                            <!--                              onError="this.src='../../assets/images/avatar/no-image.png'" class="avatar user-icon"-->
                            <!--                              src="{{url + h?.avatar_img}}" alt="avatar" class="avatar user-icon"/>-->
                            <!--                            </td>-->
                            <!--                            <td>{{householdName}}</td>-->
                            <!--                            <td *ngIf="h.alarm_status === 'Alarm Started'">Alarm gestartet</td>-->
                            <!--                            <td-->
                            <!--                              *ngIf="h.alarm_status == 'Called Person' || h.alarm_status == 'Alarm Handled' || h.alarm_status == 'Called Emergency'">-->
                            <!--                              Alarm bestätigt-->
                            <!--                            </td>-->
                            <!--                            <td *ngIf="h.alarm_status === 'Alarm Forwarded'">Alarm weitergeleitet</td>-->
                            <!--                            <td *ngIf="h.alarm_status === 'Alarm Ended'">Alarm beendet</td>-->
                            <!--                            <td>{{h.obs_timestamp}} Uhr</td>-->
                            <!--                            <td class="right-align"><i-->
                            <!--                              *ngIf="h.alarm_status == 'Called Person' || h.alarm_status == 'Alarm Handled' || h.alarm_status == 'Called Emergency'"-->
                            <!--                              class="material-icons notranslate green-text">call</i>-->
                            <!--                              <i *ngIf="h.alarm_status == 'Alarm Started'" class="material-icons notranslate red-text">alarm</i>-->
                            <!--                              <i *ngIf="h.alarm_status == 'Alarm Forwarded'" class="material-icons notranslate cyan-text">call_missed_outgoing</i>-->
                            <!--                              <i *ngIf="h.alarm_status == 'Alarm Ended'" class="material-icons notranslate red-text">call</i>-->
                            <!--                            </td>-->
                            <!--                          </tr>-->
                            <tr *ngIf="h !== null">
                              <td *ngIf="h?.avatar_img == null || h?.avatar_img === undefined"><img
                                class="avatar user-icon"
                                src="assets/images/avatar/no-image.png"
                                alt="avatar"/></td>
                                <td *ngIf="h?.avatar_img == 'female-avatar'"><img
                                class="avatar user-icon"
                                src="assets/images/avatar/female-avatar.png"
                                alt="avatar"/></td>
                              <td *ngIf="h?.avatar_img !== null && h?.avatar_img !== undefined && h?.avatar_img !== 'female-avatar'"><img
                                onError="this.src='../../assets/images/avatar/no-image.png'" class="avatar user-icon"
                                src="{{url + h?.avatar_img}}" alt="avatar"
                                class="avatar user-icon"/>
                              </td>
                              <td>{{h?.name}} {{h?.surname}}</td>
                              <td *ngIf="h.alarm_status === 'Alarm Started'" class="medium-small">{{'global.alarmStarted' | translate}}</td>
                              <td
                                *ngIf="h.alarm_status == 'Called Person'
                              || h.alarm_status == 'Alarm Handled'
                              || h.alarm_status == 'Called Emergency'">
                                {{'global.alarmHandled' | translate}}
                              </td>
                              <td *ngIf="h.alarm_status === 'Alarm Forwarded'">{{'global.alarmForwarded' | translate}}</td>
                              <td *ngIf="h.alarm_status === 'Alarm Ended'">{{'global.alarmEnded' | translate}}</td>
                              <td
                                *ngIf="h.alarm_status != 'Called Person'
                              && h.alarm_status != 'Alarm Handled'
                              && h.alarm_status != 'Called Emergency'
                              && h.alarm_status != 'Alarm Ended'
                              && h.alarm_status != 'Alarm Forwarded'
                              && h.alarm_status != 'Alarm Started'">
                                {{h.alarm_status}}
                              </td>
                              <td>{{h.obs_timestamp}} {{'global.hour' | translate}}</td>
                              <td class="right-align"><i
                                *ngIf="h.alarm_status == 'Called Person'
                              || h.alarm_status == 'Alarm Handled'
                              || h.alarm_status == 'Called Emergency'"
                                class="material-icons notranslate green-text">call</i>
                                <i *ngIf="h.alarm_status == 'Alarm Started'" class="material-icons notranslate red-text">alarm</i>

                                <i *ngIf="h.alarm_status == 'Alarm Forwarded'" class="material-icons notranslate cyan-text">call_missed_outgoing</i>
                                <i *ngIf="h.alarm_status == 'Alarm Ended'" class="material-icons notranslate red-text">call</i>
                              </td>
                            </tr>
                            <div style="display: flex; justify-content: space-evenly;">

                              <div class="seperate"></div>
                              <div class="seperate"></div>
                              <div class="seperate"></div>

                              <div class="seperate"></div>
                            </div>

                          </ng-container>

                          </tbody>
                        </table>
                        <table *ngIf="!alarmHistory && histories?.length > 0">
                          <thead>
                          <tr>
                            <th data-field="avatar"></th>
                            <th data-field="name">{{'global.name' | translate}}</th>
                            <th data-field="event">{{'global.event' | translate}}</th>
                            <th data-field="date">{{'global.date' | translate}}</th>
                            <th data-field="actions" class="right-align"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <ng-container *ngFor="let hist of histories">
                            <!--                          <tr *ngIf="hist === null">-->
                            <!--                            <td *ngIf="householdAvatar == null || householdAvatar === undefined">-->
                            <!--                              <img-->
                            <!--                                class="avatar user-icon" src="assets/images/avatar/no-image.png" alt="avatar"/></td>-->
                            <!--                            <td *ngIf="householdAvatar !== null && householdAvatar !== undefined">-->
                            <!--                              <img-->
                            <!--                                onError="this.src='../../assets/images/avatar/no-image.png'" class="avatar user-icon"-->
                            <!--                                src="assets/images/avatar/{{householdAvatar}}.png" alt="avatar"/>-->
                            <!--                            </td>-->
                            <!--                            <td>{{householdName}}</td>-->
                            <!--                            <td *ngIf="hist.event_status === 'Event Started'" class="medium-small">Ereignis gestartet-->
                            <!--                            </td>-->
                            <!--                            <td-->
                            <!--                              *ngIf="hist.event_status == 'Called Person' || hist.event_status == 'Event Handled' || hist.event_status == 'Called Emergency'">-->
                            <!--                              Ereignis bestätigt-->
                            <!--                            </td>-->
                            <!--                            <td *ngIf="hist.event_status === 'Event Forwarded'">Ereignis weitergeleitet</td>-->
                            <!--                            <td *ngIf="hist.event_status === 'Event Ended'">Ereignis beendet</td>-->
                            <!--                            <td>{{hist.obs_timestamp}} Uhr</td>-->
                            <!--                            <td class="right-align"><i-->
                            <!--                              *ngIf="hist.event_status == 'Called Person' || hist.event_status == 'Event Handled' || hist.event_status == 'Called Emergency'"-->
                            <!--                              class="material-icons notranslate green-text">call</i>-->
                            <!--                              <i *ngIf="hist.event_status == 'Event Started'" class="material-icons notranslate red-text">alarm</i>-->

                            <!--                              <i *ngIf="hist.event_status == 'Event Forwarded'" class="material-icons notranslate cyan-text">call_missed_outgoing</i>-->
                            <!--                              <i *ngIf="hist.event_status == 'Event Ended'" class="material-icons notranslate red-text">call</i>-->
                            <!--                            </td>-->
                            <!--                          </tr>-->
                            <tr *ngIf="hist !== null">
                              <td *ngIf="hist.avatar_img == null || hist.avatar_img === undefined">
                                <img
                                  class="avatar user-icon" src="assets/images/avatar/no-image.png" alt="avatar"/></td>
                                  <td *ngIf="hist.avatar_img =='female-avatar'">
                                    <img
                                      class="avatar user-icon" src="assets/images/avatar/female-avatar.png" alt="avatar"/></td>
                              <td *ngIf="hist.avatar_img !== null && hist.avatar_img !== undefined && hist.avatar_img !== 'female-avatar'">
                                <img
                                  onError="this.src='../../assets/images/avatar/no-image.png'" class="avatar user-icon"
                                  src="{{url + hist?.avatar_img}}" alt="avatar"/>
                              </td>
                              <!--                            <td *ngIf="hist.avatar_img == null || hist.avatar_img === undefined"><img-->
                              <!--                              class="avatar user-icon" src="assets/images/avatar/no-image.png" alt="avatar"-->
                              <!--                              class="avatar user-icon"/></td>-->
                              <!--                            <td *ngIf="hist?.avatar_img !== null && hist?.avatar_img !== undefined"><img-->
                              <!--                              onError="this.src='../../assets/images/avatar/no-image.png'" class="avatar user-icon"-->
                              <!--                              src="assets/images/avatar/{{hist?.avatar_img}}.png" alt="avatar"-->
                              <!--                              class="avatar user-icon"/>-->
                              <!--                            </td>-->
                              <td>{{hist?.name}} {{hist?.surname}}</td>
                              <td *ngIf="hist.event_status === 'Event Started'" class="medium-small">{{'global.eventStarted' | translate}}
                              </td>
                              <td
                                *ngIf="hist.event_status == 'Called Person'
                                || hist.event_status == 'Event Handled'
                                || hist.event_status == 'Called Emergency'">
                                {{'global.eventHandled' | translate}}
                              </td>
                              <td *ngIf="hist.event_status === 'Event Forwarded'">{{'global.eventForwarded' | translate}}</td>
                              <td *ngIf="hist.event_status === 'Event Canceled'">{{'global.eventCanceled' | translate}}</td>
                              <td *ngIf="hist.event_status === 'Event Ended'">{{'global.eventEnded' | translate}}</td>
                              <td
                                *ngIf="hist.event_status != 'Called Person'
                              && hist.event_status != 'Event Handled'
                              && hist.event_status != 'Called Emergency'
                              && hist.event_status != 'Event Ended'
                              && hist.event_status != 'Event Forwarded'
                              && hist.event_status != 'Event Started'
                              && hist.event_status != 'Event Canceled'">
                                {{hist.event_status}}
                              </td>
                              <td>{{hist.obs_timestamp}} {{'global.hour' | translate}}</td>
                              <td class="right-align">
                                <i
                                  *ngIf="hist.event_status == 'Called Person'
                                  || hist.event_status == 'Event Handled'
                                  || hist.event_status == 'Called Emergency'"
                                  class="material-icons notranslate green-text">call</i>
                                <i *ngIf="hist.event_status == 'Event Started'"
                                   class="material-icons notranslate red-text">alarm</i>

                                <i *ngIf="hist.event_status == 'Event Forwarded'" class="material-icons notranslate cyan-text">call_missed_outgoing</i>
                                <i *ngIf="hist.event_status == 'Event Ended'" class="material-icons notranslate red-text">call</i>
                              </td>
                            </tr>
                            <div style="display: flex; justify-content: space-evenly;">

                              <div class="seperate"></div>
                              <div class="seperate"></div>
                              <div class="seperate"></div>

                              <div class="seperate"></div>
                            </div>

                          </ng-container>

                          </tbody>
                        </table>
                        <div class="row" *ngIf="histories?.length > 0">
                          <div class="col-md-4"><a *ngIf="currentPage > 0"
                                                   (click)="goToPreviousPage()">{{'global.previousPage' | translate}}</a>
                          </div>
                          <div class="col-md-4"></div>
                          <!--                        <div class="col mt-1 s12 m6">Einträge 1-{{totalShown}} von {{total}}</div>-->
                          <div class="col-md-4 right-align"><a *ngIf="!hideNext"
                                                               (click)="goToNextPage()">{{'global.nextPage' | translate}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="responsive-table-mobile" class="hide-on-med-and-up">
          <div class="card recent-buyers-card mb-3">
            <div class="card-content">
              <h4 class="card-title mb-0">{{'dashboard.alarmHistory.title' | translate}}</h4>
              <p class="pt-2">{{'dashboard.alarmHistory.description' | translate}}</p>
              <div *ngIf="alarmHistory && histories.length > 0">
                <ul class="collection mb-0" *ngFor="let item of histories; let i = index">
                  <li class="collection-item avatar">
                    <!--                      <img *ngIf="householdAvatar !== null" src="{{url + item.user?.avatar_x}}"-->
                    <!--                           alt="" class="circle"/>-->
                    <img onError="this.src='../../assets/images/avatar/no-image.png'"
                         *ngIf="item.avatar_img === undefined || item.avatar_img === null"
                         src="assets/images/avatar/no-image.png" alt="" class="circle"/>
                    <img onError="this.src='../../assets/images/avatar/no-image.png'"
                          *ngIf="item.avatar_img === 'female-avatar'"
                          src="assets/images/avatar/female-avatar.png" alt="" class="circle"/>
                    <img
                      onError="this.src='../../assets/images/avatar/no-image.png'"
                      *ngIf="item.avatar_img !== undefined && item.avatar_img !== null && item.avatar_img !== 'female-avatar'"
                      src="{{url + item.avatar_img}}" alt="" class="circle"/>
                    <p
                      *ngIf="item.name === null || item.name === undefined && item.surname === null || item.surname === undefined"
                      class="font-weight-600">{{'global.unknown' | translate}}</p>
                    <p class="font-weight-600">{{item.name}} {{item.surname}}</p>
                    <p *ngIf="item.alarm_status === 'Alarm Started'" class="medium-small">
                      <b>{{'global.alarmStarted' | translate}}</b>
                      {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}</p>
                    <p
                      *ngIf="item.alarm_status == 'Called Person'
                        || item.alarm_status == 'Alarm Handled'
                        || item.alarm_status == 'Called Emergency'"
                      class="medium-small">
                      <b>{{'global.alarmHandled' | translate}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                    </p>
                    <p *ngIf="item.alarm_status === 'Alarm Forwarded'" class="medium-small">
                      <b>{{'global.alarmForwarded' | translate}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                    </p>
                    <p class="medium-small"
                      *ngIf="item.alarm_status != 'Called Person'
                              && item.alarm_status != 'Alarm Handled'
                              && item.alarm_status != 'Called Emergency'
                              && item.alarm_status != 'Alarm Ended'
                              && item.alarm_status != 'Alarm Forwarded'
                              && item.alarm_status != 'Alarm Started'">
                      <b>{{item.alarm_status}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                    </p>
                    <p *ngIf="item.alarm_status === 'Alarm Ended'" class="medium-small">
                      <b>{{'global.alarmEnded' | translate}}</b>
                      {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}</p>
                    <span *ngIf="item.alarm_status === 'Alarm Started'" class="secondary-content"><i
                      class="material-icons notranslate red-text">alarm</i></span>
                    <span *ngIf="item.alarm_status === 'Alarm Handled'" class="secondary-content"><i
                      class="material-icons notranslate green-text">call</i></span>
                    <span *ngIf="item.alarm_status === 'Alarm Forwarded'" class="secondary-content"><i
                      class="material-icons notranslate cyan-text">call_missed_outgoing</i></span>
                    <span *ngIf="item.alarm_status === 'Alarm Ended'" class="secondary-content"><i
                      class="material-icons notranslate red-text">call</i></span>
                    <span *ngIf="item.alarm_status === 'Alarm Ended'" class="secondary-content"><i
                      class="material-icons notranslate red-text">call</i></span>
                  </li>
                </ul>
              </div>
              <h6>{{alarmMessage}}</h6>
              <div *ngIf="!alarmHistory && histories.length > 0">
                <ul class="collection mb-0" *ngFor="let item of histories; let i = index">
                  <li class="collection-item avatar">
                    <!--                      <img *ngIf="householdAvatar !== null" src="{{url + item.user?.avatar_x}}"-->
                    <!--                           alt="" class="circle"/>-->
                    <img onError="this.src='../../assets/images/avatar/no-image.png'"
                         *ngIf="item.avatar_img === undefined || item.avatar_img === null"
                         src="assets/images/avatar/no-image.png" alt="" class="circle"/>
                    <img onError="this.src='../../assets/images/avatar/no-image.png'"
                         *ngIf="item.avatar_img ==='female-avatar'"
                         src="assets/images/avatar/female-avatar.png" alt="" class="circle"/>
                    <img
                      onError="this.src='../../assets/images/avatar/no-image.png'"
                      *ngIf="item.avatar_img !== undefined && item.avatar_img !== null && item.avatar_img !== 'female-avatar'"
                      src="{{url + item.avatar_img}}" alt="" class="circle"/>
                    <p
                      *ngIf="item.name === null || item.name === undefined && item.surname === null || item.surname === undefined"
                      class="font-weight-600">{{'global.unknown' | translate}}</p>
                    <p class="font-weight-600">{{item.name}} {{item.surname}}</p>
                    <p *ngIf="item.event_status === 'Event Started'" class="medium-small">
                      <b>{{'global.eventStarted' | translate}}</b>
                      {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}</p>
                    <p
                      *ngIf="item.event_status == 'Called Person'
                                      || item.event_status == 'Event Handled'
                                      || item.event_status == 'Called Emergency'"
                      class="medium-small">
                      <b>{{'global.eventHandled' | translate}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                    </p>

                    <p *ngIf="item.event_status === 'Event Forwarded'" class="medium-small">
                      <b>{{'global.eventForwarded' | translate}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                    </p>
                    <p class="medium-small"
                      *ngIf="item.event_status != 'Called Person'
                              && item.event_status != 'Event Handled'
                              && item.event_status != 'Called Emergency'
                              && item.event_status != 'Event Ended'
                              && item.event_status != 'Event Forwarded'
                              && item.event_status != 'Event Started'
                              && item.event_status != 'Event Canceled'">
                      <b>{{item.event_status}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                    </p>
                    <p *ngIf="item.event_status === 'Event Ended'" class="medium-small">
                      <b>{{'global.eventEnded' | translate}}</b>
                      {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}</p>
                    <p *ngIf="item.event_status === 'Event Canceled'" class="medium-small">
                      <b>{{'global.eventCanceled' | translate}}</b>
                      {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}</p>
                    <span *ngIf="item.event_status === 'Event Started'" class="secondary-content"><i
                      class="material-icons notranslate red-text">alarm</i></span>
                    <span *ngIf="item.event_status === 'Event Handled'" class="secondary-content"><i
                      class="material-icons notranslate green-text">call</i></span>
                    <span *ngIf="item.event_status === 'Event Forwarded'" class="secondary-content"><i
                      class="material-icons notranslate cyan-text">call_missed_outgoing</i></span>
                    <span *ngIf="item.event_status === 'Event Ended'" class="secondary-content"><i
                      class="material-icons notranslate red-text">call</i></span>
                  </li>
                </ul>
              </div>
              <div class="row" *ngIf="histories?.length > 0">
                <div class="col-md-4"><a *ngIf="currentPage > 0"
                                         (click)="goToPreviousPage()">{{'global.previousPage' | translate}}</a>
                </div>
                <div class="col-md-4"></div>
                <!--                        <div class="col mt-1 s12 m6">Einträge 1-{{totalShown}} von {{total}}</div>-->
                <div class="col-md-4 right-align"><a *ngIf="!hideNext"
                                                     (click)="goToNextPage()">{{'global.nextPage' | translate}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<!-- END: Page Main-->
