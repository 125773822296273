<div class="horizontal-layout page-header-light horizontal-menu preload-transitions 1-column blank-page blank-page"
     data-open="click" data-menu="horizontal-menu" data-col="1-column">
  <div class="row">
    <div class="col s12">
      <div class="container">
        <div id="login-page" class="row">
          <div class="col s12 m8 l8 z-depth-4 card-panel login-card bg-opacity-8">
            <form class="forgot-password-form" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
              <div class="row">
                <div class="input-field mt-4 col s12">
                  <h5 class="ml-4">{{'authentication.createNewPassword' | translate}}</h5>
                </div>
              </div>
              <div class="row margin">
                <div class="input-field col s12">
                  <i class="material-icons prefix pt-2 notranslate">lock_outline</i>
                  <input id="new_password" type="password" (keydown)="resetConfirm()" placeholder="{{'global.newPassword' | translate}}" id="password" formControlName="password" required>
<!--                  <label for="new_password" class="">Neues Passwort</label>-->
                      <p *ngFor="let validation of password_validation_messages">
                        <span *ngIf="resetPasswordForm.get('password').hasError(validation.type) && (resetPasswordForm.get('password').touched)" class="materialize-red-text">{{'validation.'+validation.type | translate}}</span>
                      </p>
                      <small *ngIf="resetPasswordForm.get('password').hasError('required') && (resetPasswordForm.get('password').touched)"><b>{{'validation.passInfo' | translate}}</b></small>
                </div>
              </div>
              <div class="row margin">
                <div class="input-field col s12">
                  <i class="material-icons prefix pt-2 notranslate">lock_outline</i>
                  <input id="confirm_new_password" type="password" placeholder="{{'global.confirmNewPassword' | translate}}" id="passwordConfirm" formControlName="passwordConfirm" required>
                  <p *ngIf="isPassConfirmFieldValid('passwordConfirm')" class="materialize-red-text">{{'validation.repeatPassword' | translate}}</p>
<!--                  <label for="confirm_new_password" class="">Neues Passwort bestätigen</label>-->
                </div>
              </div>
              <div class="row mb-3">
                <div class="input-field col s12">
                  <button type="submit"
                          [disabled]="!resetPasswordForm.valid" href="#"
                          class="btn waves-effect waves-light border-round red col s12">{{'authentication.resetPassword' | translate}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="content-overlay"></div>
    </div>
  </div>
</div>
