import {Component, ElementRef, NgZone, OnInit} from '@angular/core';
import {AuthService} from '../../auth/services/auth.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {HouseholdService} from '../../services/household.service';
import {SharedService} from '../../services/shared.service';
import {environment} from '../../../environments/environment';
import {Services} from '../../services';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  host: {
    "(document:click)": "onClick($event)",
  },
})

export class SidebarComponent implements OnInit {
  collapse = false;
  currentHousehold;
  households;
  url = '';
  isLoggedIn = false;
  userId;
  householdId;
  userAvatar;

  minutes:any = '04'
  seconds:any = '59'
  countDown=false;
  countDownInterval

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private router: Router,
              private householdService: HouseholdService,
              private zone: NgZone, private sharedService: SharedService,
              private services: Services,
              private _eref: ElementRef) {

                this.sharedService.invokeEvent.subscribe(value =>{
                  if(value === '5minLeft'){
                    if(!this.countDown){
                      this.startCountdown()
                    }
                  }
                  if(value === 'userisActive'){
                    this.stopCountDown()
                  }
                })
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
      console.log('is logged in triggered', this.authService.isLoggedIn());
      this.getData();
      this.householdService.getHouseholdData().subscribe(response => {
        console.log('response', response);
        this.households = response;
        this.currentHousehold = this.householdService.householdData;
      });
      this.url = environment.imagesApi;
      this.householdService.getHouseholdData().subscribe(response => {
        console.log('response', response);
        this.households = response;
        this.currentHousehold = this.householdService.householdData;
      });
      this.householdService.household.subscribe(response => {
        this.zone.run(() => { // <== added
          console.log('response', response);
          this.currentHousehold = response;
        });
      });
      this.sharedService.getUpdatedData().subscribe(() => {
        this.getData();
      });
    }
  }

  // public _opened: boolean = false;
  //
  // private _toggleSidebar() {
  //   this._opened = !this._opened;
  // }

  public _opened: boolean = false;

  private _toggleSidebar() {
    this._opened = !this._opened;
  }

  startCountdown(){
    this.countDown = true
    let endOfCount = new Date().setMinutes(new Date().getMinutes()+5)
    this.countDownInterval = setInterval(()=>{
      const now = new Date().getTime();
      const timeleft = endOfCount - now;

      this.minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
      this.minutes<10?this.minutes='0'+this.minutes:this.minutes
      this.seconds<10?this.seconds='0'+this.seconds:this.seconds

      if(timeleft<=0){
        //this.authService.logout(true)
        //setTimeout(()=>localStorage.setItem('logOut_dueTo_Inactivity', 'true'), 1000)
        this.stopCountDown()
      }
    },1000)
  }
  stopCountDown(){
    this.countDown = false
    clearInterval(this.countDownInterval)
    this.minutes = '04'
    this.seconds = '59'

  }
  collapseMenu() {
    this.collapse = !this.collapse;
    this.sharedService.toggleSidebar();
  }

  changeHousehold(household) {
    console.log('household in sidebar', household);
    this.householdService.nextHouseholdState(household);
    this.sharedService.sendUpdatedHousehold();
    if (!this.householdService.isAuthorized()) {
      this.router.navigateByUrl('/meine-data');
    }
    // if (localStorage.getItem('user'))
  }

  logout() {
    // localStorage.clear();
    // console.log(localStorage);
    this.authService.logout();
    //this.router.navigate(['/login']);
  }

  getData() {
    this.householdId = localStorage.getItem('household_id');
    // this.userId = localStorage.getItem('user_id');
    this.userId = this.authService.getId();
    this.services.getMyData({id: this.userId, household_id: this.householdId}).subscribe((data: any) => {
      this.userAvatar = data.user.avatar_path;
      console.log('this.useravatar', this.userAvatar);
      console.log(data);
      console.log('users data', data);
    }, (error) => {
      console.log(error);
    });
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) || event.target.className.includes('myNav') || event.target.className.includes('navigate')){
      if(this.collapse  && !event.target.className.includes('material-icons float-right notranslate')){
        this.collapse = !this.collapse;
        this.sharedService.toggleSidebar();
      }
    }
  }
}
