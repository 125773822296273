<div class="confirm-box">
    <div class="edit-content">
      <div class="col-md-12">
        <h4 *ngIf="type ==='event'">{{"confirmChain.changesEventChain" | translate}}</h4>
        <h4 *ngIf="type ==='alarm'">{{"confirmChain.changesAlarmChain" | translate}}</h4>
      </div>
      <div class="col-md-12">
        <h5>{{"confirmChain.confirmChange" | translate}}</h5>
      </div>
      <div class="col-md-12">
        <div class="row mt-3 mb-3">
            <div class="col-sm-6 text-center">
                <button class="btn btn-large" style="font-size: 24px;" (click)="approveChanges()">{{"global.save" | translate}}</button>
            </div>
            <div class="col-sm-6 text-center">
                <button class="btn btn-large" style="background-color: #949494 !important; font-size: 24px;" (click)="closeModal()">{{"global.cancel" | translate}}</button>
            </div>
        </div>
      </div>
    </div>
</div>
  