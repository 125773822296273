import {Component, OnDestroy, OnInit} from '@angular/core';
import {HouseholdService} from '../../services/household.service';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-household-select',
  templateUrl: './household-select.component.html',
  styleUrls: ['./household-select.component.scss']
})
export class HouseholdSelectComponent implements OnInit, OnDestroy {
  households;
  disabled = true;
  currentHousehold = null;
  currentHouseholdData = null;
  userSelected = false;
  private subscriptions:Subscription;

  constructor(private householdService: HouseholdService, private router: Router, private authService: AuthService) {
    this.subscriptions = new Subscription()
  }

  ngOnInit(): void {
    const houseHoldData = this.householdService.getHouseholdData().subscribe(response => {
      console.log('response in household select: ', response);
      response.forEach(household => {
        if(household.role == 'owner'){
          localStorage.setItem('ownsHousehold','true')
        }
      })
      this.households = response;
      this.disabled = false;
      this.currentHouseholdData = this.households[0];

      let returnUrl = localStorage.getItem('wifi-config-redirect');

      if(returnUrl){
        let param_household_id = returnUrl.split('?')[0].replace('/hannah-config/', "");
        this.households.forEach(household => {
          if(household.id == param_household_id){
            this.currentHouseholdData = household;
            this.currentHousehold = household.name + ' ' + household.surname;
            this.goToConfigScreen(returnUrl);
          }
        });
      }

      if (this.currentHouseholdData) {
        this.currentHousehold = this.households[0].name + ' ' + this.households[0].surname;
        this.householdService.nextHouseholdState(this.currentHouseholdData);
        console.log('current household data', this.currentHousehold);
      }
    }, error => {
      this.disabled = true;
    });
    this.subscriptions.add(houseHoldData)
    const routerEvents = this.router.events.subscribe((event: any) => {
      console.log('event', event);
      if (!this.householdService.isHouseholdSelected()) {
        this.authService.logout();
      }
      // if (event.url.include('dashboard')) {
      //   localStorage.clear();
      // }
    });
    this.subscriptions.add(routerEvents)
    if (!this.householdService.isAdmin()) {
      console.log('hello from heere');
      this.router.navigateByUrl('/meine-daten')
    }
  }

  selectHousehold(e: any) {
    console.log('selected', e);
    this.currentHouseholdData = e;
    this.currentHousehold = e.name + ' ' + e.surname
    this.householdService.nextHouseholdState(this.currentHouseholdData);
  }

  goToDashboard() {
    this.householdService.nextHouseholdState(this.currentHouseholdData);
    this.householdService.setHouseholdSelect(true);
    this.router.navigateByUrl('/dashboard');
  }

  goToConfigScreen(url){
    this.householdService.nextHouseholdState(this.currentHouseholdData);
    this.router.navigateByUrl(url);
    localStorage.removeItem('wifi-config-redirect');

  }

  householdSelected() {
    if (this.currentHousehold !== null) {

    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
