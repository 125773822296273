<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">
    <div class="col s12">
      <div class="container">
        <div class="section">
          <div class="row">
            <div class="col s12 l7">
              <!--              ALARM ON              -->
              <div *ngIf="alarmStateData === 'alarm'">
                <div class="card padding-4 mb-3 border-red">
                  <div class="row">
                    <div class="col s7 m7">
                      <h4 class="mb-0 mt-0"><b>{{'dashboard.alarmStatus.alarm.title' | translate}}</b></h4>
                      <p class="no-margin">{{'dashboard.alarmStatus.alarm.description' | translate}}</p>
                    </div>
                    <div class="col s5 m5 right-align">
                      <img src="assets/images/icon/icon-active.svg" width="80" height="80" alt="icon-active"/>
                    </div>
                  </div>
                </div>
              </div>
              <!--              EVENT ON            -->
              <div *ngIf="alarmStateData === 'event'">
                <div class="card padding-4 mb-3 border-red">
                  <div class="row">
                    <div class="col s7 m7">
                      <h4 class="mb-0 mt-0"><b>{{'dashboard.alarmStatus.event.title' | translate}}</b></h4>
                      <p class="no-margin">{{'dashboard.alarmStatus.event.description' | translate}}</p>
                    </div>
                    <div class="col s5 m5 right-align">
                      <img src="assets/images/icon/icon-active.svg" width="80" height="80" alt="icon-active"/>
                    </div>
                  </div>
                </div>
              </div>
              <!--              ALARM ENDED       -->
              <div
                *ngIf="alarmStateData === 'end' || alarmStateData === 'endEvent' || alarmStateData === '' || alarmStateData === undefined">
                <div class="card padding-4 mb-3 border-black">
                  <div class="row">
                    <div class="col s7 m7">
                      <h4 class="mb-0 mt-0"><b>{{'dashboard.alarmStatus.noAlarm.title' | translate}}</b></h4>
                      <p class="no-margin"></p>
                    </div>
                    <div class="col s5 m5 right-align">
                      <img src="assets/images/icon/icon-inaktiver-alarm.svg" width="80" height="80"
                           alt="icon-inactive"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card recent-buyers-card mb-3">
                <div class="card-content">
                  <h4 class="card-title mb-0">{{'dashboard.alarmHistory.title' | translate}}</h4>
                  <p class="pt-2">{{'dashboard.alarmHistory.description' | translate}}</p>
                  <ul class="collection mb-0" *ngFor="let item of latestAlarmHistoryData; let i = index">
                    <li class="collection-item avatar">
                      <img

                        *ngIf="item.avatar_path !== undefined && item.avatar_path !== null"
                        src="{{item.avatar_path}}" alt="" class="circle cover-mode"/>
                      <p
                        *ngIf="item.name === null || item.name === undefined && item.surname === null || item.surname === undefined"
                        class="font-weight-600">{{'global.unknown' | translate}}</p>
                      <p class="font-weight-600">{{item.name}} {{item.surname}}</p>
                      <p *ngIf="item.alarm_status === 'Alarm Started'" class="medium-small">
                        <b>{{'global.alarmStarted' | translate}}</b>
                        vor {{item.obs_timestamp | dateAgo}}</p>
                      <p
                        *ngIf="item.alarm_status === 'Called Person'
                        || item.alarm_status === 'Alarm Handled'
                        || item.alarm_status === 'Called Emergency'"
                        class="medium-small">
                        <b>{{'global.alarmHandled' | translate}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                      </p>
                      <p *ngIf="item.alarm_status === 'Alarm Forwarded'" class="medium-small">
                        <b>{{'global.alarmForwarded' | translate}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                      </p>
                      <p *ngIf="item.alarm_status !== 'Called Person'
                              && item.alarm_status !== 'Alarm Handled'
                              && item.alarm_status !== 'Called Emergency'
                              && item.alarm_status !== 'Alarm Ended'
                              && item.alarm_status !== 'Alarm Forwarded'
                              && item.alarm_status !== 'Alarm Started'" class="medium-small">
                        <b>{{item.alarm_status}}</b> {{'global.ago' | translate}} {{item.obs_timestamp | dateAgo}}
                      </p>
                      <p *ngIf="item.alarm_status === 'Alarm Ended'" class="medium-small">
                        <b>{{'global.alarmEnded' | translate}}</b>
                        vor {{item.obs_timestamp | dateAgo}}</p>
                      <span *ngIf="item.alarm_status === 'Alarm Started'" class="secondary-content"><i
                        class="material-icons notranslate red-text">alarm</i></span>
                      <span *ngIf="item.alarm_status === 'Alarm Handled'" class="secondary-content"><i
                        class="material-icons notranslate green-text">call</i></span>
                      <span *ngIf="item.alarm_status === 'Alarm Forwarded'" class="secondary-content"><i
                        class="material-icons notranslate cyan-text">call_missed_outgoing</i></span>
                      <span *ngIf="item.alarm_status === 'Alarm Ended'" class="secondary-content"><i
                        class="material-icons notranslate red-text">call</i></span>
                    </li>
                  </ul>
                </div>
                <div class="card-action border-non center">
                  <a class="waves-effect waves-light btn mb-1"
                     routerLink="../alarmierungs-historie">{{'dashboard.alarmHistory.buttonText' | translate}}</a>
                </div>
              </div>
              <!--  hidden temporary -->
              <!--              <div class="card mb-3">-->
              <!--                <div class="card-content">-->
              <!--                  <h4 class="mb-0 mt-0 display-flex justify-content-between">Ereignisse</h4>-->
              <!--                  <p class="no-margin">Übersicht vom aktuellen Haushaltsstatus</p>-->
              <!--                  &lt;!&ndash;                  Event Gauge                 &ndash;&gt;-->
              <!--                  &lt;!&ndash;                  <div class="consumption-null"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                       *ngIf="consumptionValue < 0 || consumptionValue === null || consumptionValue == undefined">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <img class="" src="../../../../assets/images/verbrauch-null.png">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--                  <div class="activity-top-img">-->
              <!--                    <img class="img-top-1" src="assets/images/gauge/meter.png">-->
              <!--                  </div>-->
              <!--                  <div class="container-g">-->
              <!--                    <rg-gauge-chart class="meter-alarm"-->
              <!--                                    [canvasWidth]="canvasWidth"-->
              <!--                                    [needleValue]="needleValue"-->
              <!--                                    [centralLabel]="centralLabel"-->
              <!--                                    [options]="options"-->
              <!--                                    [name]="name"-->
              <!--                                    [bottomLabel]="bottomLabel">-->
              <!--                    </rg-gauge-chart>-->
              <!--                    <div class="activity-bottom-img">-->
              <!--                      <img onError="src='assets/images/gauge/defaultIcon.png'" class="img-bottom-1" src="assets/images/gauge/{{consumptionIcon}}.png">-->
              <!--                    </div>-->

              <!--                  </div>-->
              <!--                  &lt;!&ndash;                  Event Gauge  END               &ndash;&gt;-->

              <!--                  &lt;!&ndash;                  <div class="state-container">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <div class="circle"><img src="assets/images/controller-home-icon.svg" class="home-icon" alt=""/></div>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <div class="triangle" style="transform: rotate(-48deg);"></div>&ndash;&gt;-->

              <!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->

              <!--                </div>-->
              <!--                <p class="center-align">{{scoreTitle}}</p>-->
              <!--                <h5 class="center-align mt-1 mb-1">{{scoreSubtitle}}</h5>-->
              <!--                <div class="card-action border-non center" routerLink="../statistiken">-->
              <!--                  <a class="waves-effect waves-light btn mb-1">Statistiken anzeigen</a>-->
              <!--                </div>-->
              <!--              </div>-->

              <!-- to hereee hidden temporary -->
            </div>
            <div class="col s12 l5">
              <div class="card padding-4 mb-4" *ngIf="availabilityData?.status === 'online'">
                <div class="row">
                  <div class="col s9 m9">
                    <h4 class="mb-0 mt-0">{{'dashboard.hannahAvailable.title' | translate}}</h4>
                    <p class="no-margin">{{'dashboard.hannahAvailable.description' | translate}}</p>
                  </div>
                  <div class="col s3 m3 right-align">
                    <i class="notranslate material-icons background-round mt-5 mb-5 green white-text">done</i>
                  </div>
                </div>
              </div>

              <div class="card padding-4 mb-4"
                   *ngIf="availabilityData?.status === 'offline'">
                <div class="row">
                  <div class="col s9 m9">
                    <h4 class="mb-0 mt-0">{{'dashboard.hannahNotAvailable.title' | translate}}</h4>
                    <p class="no-margin">{{'dashboard.hannahNotAvailable.description1' | translate}}</p>
                    <p class="no-margin" [innerHtml]="'dashboard.hannahNotAvailable.description2' | translate"></p>
                  </div>
                  <div class="col s3 m3 right-align">
                    <i class="notranslate material-icons background-round mt-5 mb-5 red white-text">close</i>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-content">
                  <h4 class="card-title mb-0">{{'dashboard.yourDevices.title' | translate}}</h4>
                  <p class="pt-2">{{'dashboard.yourDevices.description' | translate}}</p>
                  <table class="subscription-table highlight">
                    <thead>
                    <tr>
                      <th>{{'dashboard.yourDevices.device' | translate}}</th>
                      <th class="right-align">{{'dashboard.yourDevices.status' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngFor="let sensor of sensorData">
                    <tr>
                      <td>{{'dashboard.yourDevices.' + sensor?.sensor_art | translate}}</td>
                      <td class="right-align">
                        <i [ngClass]="sensor?.status === 'active' ? 'green-text' : 'red-text'"
                           class="material-icons notranslate">{{sensor?.status === 'active' ? 'check' : 'close'}}</i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->
