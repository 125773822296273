import {Component, Input, NgZone, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {OwlDateTimeIntl} from 'ng-date-and-time-picker';
import {DatePipe} from '@angular/common';
import {SharedService} from '../../../services/shared.service';
import {CalendarService} from '../../../services/calendar.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../auth/services/auth.service';
import {FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
  selectedSerialAppointment;
  startDate = new Date();
  endDate = new Date();
  todayDate = new Date();
  selectedView;
  selectedSeries;
  recurrenceCount;
  recurrenceDate;
  title;
  description;
  seriesId;
  householdId;
  showSeries = false;
  isOwnerHome = 1;
  specialCharactersAllowedTitle = false;
  specialCharactersAllowedDescription = false;
  eventCreated = false;
  specialCharactersDetected = {
    title: false,
    description: false
  }
  resourcesList = [
    {
      id: 0,
      name: this.translate.instant('calendar.on'),
      checked: true
    },
    {
      id: 1,
      name: this.translate.instant('calendar.after'),
      checked: false
    }
  ];
  selectedResource = this.resourcesList[0].id;
  serialAppointment: any[] = [
    {
      id: 0, name: this.translate.instant('global.yes'),
    },
    {
      id: 1, name: this.translate.instant('global.no'),
    }
  ];
  viewAs: any[] = [
    {
      id: 0, name: this.translate.instant('calendar.absent'), state: false
    },
    {
      id: 1, name: this.translate.instant('calendar.atHome'), state: false
    },

  ];
  series: any[] = [
    {
      id: 0, name: this.translate.instant('calendar.daily'), state: false
    },
    {
      id: 1, name: this.translate.instant('calendar.weekly'), state: false
    },
    {
      id: 2, name: this.translate.instant('calendar.monthly'), state: false
    },
    {
      id: 3, name: this.translate.instant('calendar.yearly'), state: false
    },
  ];

  constructor(private CEModalRef: BsModalRef,
              private dateTimeIntl: OwlDateTimeIntl,
              private datePipe: DatePipe,
              private sharedService: SharedService,
              private calendarService: CalendarService,
              private authService: AuthService,
              private translate: TranslateService,
              private toasterService: ToastrService) {
    this.dateTimeIntl.cancelBtnLabel = this.translate.instant('global.cancel');
    this.dateTimeIntl.setBtnLabel = this.translate.instant('global.confirm');
  }

  ngOnInit(): void {
    this.householdId = localStorage.getItem('household_id');
    this.recurrenceCount = 0;
    this.selectedSerialAppointment = this.serialAppointment[1].name;
    this.selectedView = this.viewAs[0].name;
    this.selectedSeries = this.series[0].name;
    // this.seriesId = 0;
  }

  // charValidator(event, input) {
  //   console.log('something is typed', event);
  //   const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  //   if (event.data && nameRegexp.test(event.data)) {
  //     if (input == 'title') {
  //       this.specialCharactersAllowedTitle = true;
  //     } else if (input == 'description') {
  //       this.specialCharactersAllowedDescription = true;
  //     }
  //   } else {
  //     if (input == 'title') {
  //       this.specialCharactersAllowedTitle = false;
  //     } else if (input == 'description') {
  //       this.specialCharactersAllowedDescription = false;
  //     }
  //   }
  // }

  showError() {
    this.toasterService.error('Eingabe enthält nicht erlaubte Sonderzeichen');
  }

  checkForSpecialCharacters(value, inputName) {
    console.log('value: ', value, ' input name: ', inputName);
    const regex = /[ -~äöüÄÖÜß]/
    const isValid = regex.test(value);
    if (!isValid) {
      this.showError();
      console.log('title value: ', this.specialCharactersDetected[inputName], '\tdescription value: ', this.specialCharactersDetected.description);
    } else {
      this.specialCharactersDetected[inputName] = false;
    }
    return isValid;
  }

  selectSerialAppointment(id) {
    console.log('selected serial id: ', id);
    if (id == 0) {
      this.seriesId = 0
    } else {
      this.seriesId = undefined;
    }

    this.selectedSerialAppointment = this.serialAppointment[id].name;
    if (id == 0) {
      this.selectedSeries = this.series[0].name;
      this.showSeries = true;
      console.log('show series: ', this.showSeries);
    } else {
      this.selectedSeries = 'none';
      console.log('show series: ', this.showSeries);
      this.showSeries = false;
    }
  }

  selectView(id) {
    console.log('selected view id: ', id);
    this.selectedView = this.viewAs[id].name;
  }

  selectSeries(id) {
    console.log('selected series id: ', id);
    this.selectedSeries = this.series[id].name;
    this.seriesId = id;
  }

  setAppointment() {
    if(!this.eventCreated){
      this.eventCreated = true;
      this.title ? this.title = this.title.trim() : this.title
      this.description ? this.description = this.description.trim(): this.description
      if (this.checkForSpecialCharacters(this.title, 'title') && this.checkForSpecialCharacters(this.description, 'description')) {
        // const userId = localStorage.getItem('user_id');
        console.log('in else')
        const userId = this.authService.getId();
        if (this.seriesId === 0) {
          this.selectedSeries = 'daily'
        } else if (this.seriesId === 1) {
          this.selectedSeries = 'weekly';
        } else if (this.seriesId === 2) {
          this.selectedSeries = 'monthly';
        } else if (this.seriesId === 3) {
          this.selectedSeries = 'yearly'
        } else {
          this.selectedSeries = 'none';
        }

        let timebetweenStartEnd = Math.abs(new Date(this.endDate).getTime() - new Date(this.startDate).getTime())
        let minutes:any = timebetweenStartEnd/(1000*60)
        const hours = Math.floor(minutes/60)
        const endDateHour = new Date(this.endDate).getHours()
        const endDateMinutes = new Date(this.endDate).getMinutes()
        if(this.selectedSeries == 'daily'){
          if(hours>=24){
            let endDate = new Date(this.startDate).setHours(endDateHour)
            endDate = new Date(endDate).setMinutes(endDateMinutes)
            this.endDate = new Date(endDate)

          }
        }else if(this.selectedSeries == 'weekly'){
          if(hours/24>=7){
            let endDate = new Date(this.startDate).getTime() + 6*24*60*60*1000
            endDate = new Date(endDate).setMinutes(endDateMinutes)
            this.endDate = new Date(endDate)
          }
        }else if(this.selectedSeries == 'monthly'){
          if(hours/24>=30){
            let endDate = new Date(this.startDate).getTime() + 29*24*60*60*1000
            endDate = new Date(endDate).setMinutes(endDateMinutes)
            this.endDate = new Date(endDate)
          }
        }else if(this.selectedSeries == 'yearly'){
          if(hours/24>=365){
            let endDate = new Date(this.startDate).getTime() + 364*24*60*60*1000
            endDate = new Date(endDate).setMinutes(endDateMinutes)
            this.endDate = new Date(endDate)
          }
        }

        if (this.selectedView !== this.translate.instant('calendar.atHome')) {
          this.isOwnerHome = 0;
        }
        let rruleStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[0]
        let rruleStartTime = this.datePipe.transform(this.startDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
        let rruleEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[0]
        let rruleEndTime = this.datePipe.transform(this.endDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
        if(this.selectedSeries != 'none'){
          if(this.recurrenceCount!=0){
            this.recurrenceDate = new Date(this.endDate)
            if(this.selectedSeries == 'daily'){
              this.recurrenceDate.setDate(this.recurrenceDate.getDate()+this.recurrenceCount-1)
            }else if(this.selectedSeries == 'weekly'){
              this.recurrenceDate.setDate(this.recurrenceDate.getDate()+7*(this.recurrenceCount-1))
            }else if(this.selectedSeries == 'monthly'){
              this.recurrenceDate.setMonth(this.recurrenceDate.getMonth()+this.recurrenceCount-1)
            }else if(this.selectedSeries == 'yearly'){
              this.recurrenceDate.setFullYear(this.recurrenceDate.getFullYear()+this.recurrenceCount-1)
            }
            rruleEndTime = this.datePipe.transform(this.recurrenceDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
          }
          rruleEndDate = this.datePipe.transform(this.recurrenceDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[0]
          rruleEndTime = this.datePipe.transform(this.recurrenceDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
        }

        const data = {
          title: this.title == null ? this.translate.instant('global.noDescription') : this.title,
          description: this.description == null ? this.translate.instant('global.noDescription') : this.description,
          obs_intervall_start: this.datePipe.transform(this.startDate, 'yyyy-MM-dd HH:mm'),
          obs_intervall_end: this.datePipe.transform(this.endDate, 'yyyy-MM-dd HH:mm'),
          recurrence: this.selectedSeries,
          recurrence_count: this.recurrenceCount,
          location: 1,
          art: 1,
          all_day_flag: 1,
          event_at_home_flag: 1,
          user_id: userId,
          household_id: this.householdId,
          is_owner_home: this.isOwnerHome,
          recurrence_date: this.datePipe.transform(this.recurrenceDate, 'yyyy-MM-dd HH:mm'),
          recurrence_rule: (`DTSTART:${rruleStartDate}T${rruleStartTime}00Z\nRRULE:FREQ=${this.selectedSeries=='none'?'daily':this.selectedSeries};INTERVAL=1;UNTIL=${rruleEndDate}T${rruleStartTime}00Z`)
        };

        this.calendarService.postCalendarData(data).subscribe(response => {
          this.sendUpdateData(data);
          this.eventCreated = true;
          console.log('response', response);
        }, (error:any)=> {
          this.eventCreated = false;
        });

        this.calendarService.shareCalendarData(data);
        console.log('appointment data: ', data);
        this.closeModal();
        console.log('in if')
      } else {

      }
    }
  }

  checkSelectedResource(item) {
    this.selectedResource = item;
  }

  closeModal() {
    this.CEModalRef.hide();
  }

  sendUpdateData(data) {
    this.sharedService.sendUpdatedCalendarData(data);
  }

  changeEndDate(event) {
    this.endDate = event;
  }
}
