import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AlarmLatestHistoryService {
  householdId;
  latestHistoryData;
  latestHistoryState = new Subject();
  //sharedLatestHistoryData = this.latestHistoryState.asObservable();

  constructor(private http: HttpClient) {
  }

  nextAlarmState(data: any) {
    this.latestHistoryState.next(data);
  }

  shareLatestHistoryState(data) {
    this.latestHistoryData = data;
    this.nextAlarmState(this.latestHistoryData);
  }


  getLatestAlarmHistory(householdId) {
    const householdID = localStorage.getItem('household_id');
    this.http.get(`${environment.api}get-latest-alarm-history/${householdID}`).subscribe((res: any) => {
      this.shareLatestHistoryState(res);
    });
  }
  getLatestHistory(householdId) {
    const householdID = localStorage.getItem('household_id');
    return this.http.get(`${environment.api}get-latest-alarm-history/${householdID}`).pipe(map((res: any) => {
      // console.log('response in latest alarm history: ', res);
      const result = [];
      for (const x of res.result) {
        console.log('x', x);
        if (x.alarm_status.includes('title')) {
          x.alarm_status = JSON.parse(x.alarm_status).title + ' | ' + JSON.parse(x.alarm_status).desc;
        }
        result.push(x);
      }
      return result;
      // return res.result;
      // const histories = res.result;
      // for (const x in histories) {
      //   console.log('x in history', x);
      //   histories[x].forEach(element => {
      //     console.log('res result', histories[x]);
      //     if (element.alarm_status.includes('title')) {
      //       element.alarm_status = JSON.parse(element.alarm_status).title + ' | ' + JSON.parse(element.alarm_status).desc
      //     }
      //     console.log('response in latest alarm history: ', histories);
      //     histories.push(element);
      //   });
      // }
      // return histories;
    }));
  }
}
