import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AuthModule} from './auth/auth.module';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './shared/footer/footer.component';
import {StatistikenComponent} from './components/statistiken/statistiken.component';
import {KontakteVerwaltenComponent} from './components/kontakte-verwalten/kontakte-verwalten/kontakte-verwalten.component';
import {AlarmierungsHistorieComponent} from './components/alarmierungs-historie/alarmierungs-historie.component';
import {AlarmketteBearbeitenComponent} from './components/alarmkette-bearbeiten/alarmkette-bearbeiten.component';
import {EreignisketteBearbeitenComponent} from './components/ereigniskette-bearbeiten/ereigniskette-bearbeiten.component';
import {MeineDatenComponent} from './components/meine-daten/meine-daten.component';
import {ServicesBuchenComponent} from './components/services-buchen/services-buchen.component';
import {RechnungenComponent} from './components/rechnungen/rechnungen.component';
import {DokumenteComponent} from './components/dokumente/dokumente.component';
import {HilfeComponent} from './components/hilfe/hilfe.component';
import {NotfallkontaktAnlegenComponent} from './components/notfallkontakt-anlegen/notfallkontakt-anlegen.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {HttpClient, HttpClientModule} from '@angular/common/http';
//import {NgChartsModule} from 'ng2-charts';
import {HighchartsChartModule} from 'highcharts-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
//import {GaugeChartModule} from 'angular-gauge-chart';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {CreateContactComponent} from './components/kontakte-verwalten/create-contact/create-contact.component';
import {EditContactComponent} from './components/kontakte-verwalten/edit-contact/edit-contact.component';
import {TabsComponent} from './shared/tabs/tabs.component';
import {AsyncPipe, DatePipe} from '@angular/common';
import {MessagingService} from './services/messaging.service';
import {AlarmStateService} from './services/alarm-state.service';
import {DateAgoPipe} from './shared/pipes/date-ago.pipe';
import {HannahAvailabilityService} from './services/hannah-availability.service';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
//import * as moment from 'moment';
import {CalendarComponent} from './components/calendar/calendar/calendar.component';
import {CreateEventComponent} from './components/calendar/create-event/create-event.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-date-and-time-picker';
import {OWL_DATE_TIME_LOCALE} from 'ng-date-and-time-picker';
import {EditEventComponent} from './components/calendar/edit-event/edit-event.component';
import {MatDialogModule} from '@angular/material/dialog';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {registerLocaleData} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {HouseholdService} from './services/household.service';
import { HouseholdSelectComponent } from './components/household-select/household-select.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NotFoundComponent } from './components/not-found/not-found/not-found.component';
import {PositionConfirmedComponent} from './components/position-confirmed/position-confirmed.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
//import { SidebarModule } from 'ng-sidebar'
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RemoveAccountPopupComponent} from './components/meine-daten/remove-account-popup/remove-account-popup.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AdminGuard} from './app-routing.guard';
import {RemoveUserPopupComponent} from './components/kontakte-verwalten/remove-user-popup/remove-user-popup.component';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ConfirmChangesComponent} from './components/confirm-changes/confirm-changes.component'
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { InvalidInvitationComponent } from './components/notfallkontakt-anlegen/invalid-invitation/invalid-invitation.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import rrulePlugin from '@fullcalendar/rrule'
import { WifiConfigComponent } from './components/wifi-config/wifi-config.component';
import { HannahConfigComponent } from './components/hannah-config/hannah-config.component';

registerLocaleData(localeDe, localeDeExtra);

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  rrulePlugin
]);


@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        FooterComponent,
        StatistikenComponent,
        KontakteVerwaltenComponent,
        AlarmierungsHistorieComponent,
        AlarmketteBearbeitenComponent,
        EreignisketteBearbeitenComponent,
        MeineDatenComponent,
        ServicesBuchenComponent,
        RechnungenComponent,
        DokumenteComponent,
        HilfeComponent,
        NotfallkontaktAnlegenComponent,
        PositionConfirmedComponent,
        CreateContactComponent,
        EditContactComponent,
        TabsComponent,
        DateAgoPipe,
        CalendarComponent,
        CreateEventComponent,
        EditEventComponent,
        SidebarComponent,
        HouseholdSelectComponent,
        NotFoundComponent,
        RemoveAccountPopupComponent,
        RemoveUserPopupComponent,
        ConfirmChangesComponent,
        InvalidInvitationComponent,
        WifiConfigComponent,
        HannahConfigComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        AuthModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            //maxOpened: 1,
            preventDuplicates: true,
            timeOut: 1500,
            positionClass: 'toast-bottom-center',
        }),
        //SidebarModule.forRoot(),
        HighchartsChartModule,
        DragDropModule,
        HttpClientModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        //GaugeChartModule,
        //NgChartsModule,
        ModalModule.forRoot(),
        FormsModule,
        OwlDateTimeModule,
        PopoverModule.forRoot(),
        OwlNativeDateTimeModule,
        MatDialogModule,
        MatSlideToggleModule,
        NgxSliderModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AccordionModule.forRoot(),
        FullCalendarModule // register FullCalendar with you app
    ],
    providers: [{ provide: OWL_DATE_TIME_LOCALE, useValue: 'de' }, { provide: LOCALE_ID, useValue: 'de' },
        BsModalRef, AsyncPipe, MessagingService, AlarmStateService, HannahAvailabilityService, DatePipe, AdminGuard, HouseholdService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
