import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserRegisterService} from '../../services/user-register.service';
import {TranslateService} from '@ngx-translate/core';
import {PassingDataService} from '../../services/passing-data.service';

@Component({
  selector: 'app-position-confirmed',
  templateUrl: './position-confirmed.component.html',
  styleUrls: ['./position-confirmed.component.scss']
})
export class PositionConfirmedComponent implements OnInit {
  token: any;
  userData: any;
  description: '';
  withPosition = false;
  userType = '';
  constructor(private route: ActivatedRoute,
              private userRegisterService: UserRegisterService,
              private translate: TranslateService,
              private passingData: PassingDataService
              ) {
  }

  ngOnInit(): void {
    this.findData();
    this.passingData.userState.subscribe(response => {
      console.log('response for user data', response);
      if (response) {
        this.userType = response == 'admin' ? 'Administrator' : response == 'emergency' ? 'Notfallkontakt' : 'Kontakt';
      }
    })
  }

  findData() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.findDatafromToken(this.token);
    });
  }

  findDatafromToken(token) {
    this.userRegisterService.findUserfromToken(token).subscribe((res: any) => {
      console.log('data from token', res);
      this.withPosition = true;
      this.saveUserData(res);
      if(res) {
        this.userType = res.user_type == 'admin' ? 'Administrator' : res.user_type == 'emergency' ? 'Notfallkontakt' : 'Kontakt';
      }
    }, error => {
      this.withPosition = false;
    });
  }

  saveUserData(data) {
    this.userData = data.user;
    this.userData.token = this.token;
    // here save the invitation to backend
    this.userRegisterService.InvitationAccept(this.userData).subscribe(res => {
      console.log('saved user data: ', res);
    }, error => {
    });
  }

}
