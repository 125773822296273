import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Services {
  onRefresh = new Subject<void>();
  private apiEndpoint = environment.api;

  constructor(private http: HttpClient) {
  }

  getAlarmUsers = (householdId) => {
    return this.http.get(this.apiEndpoint + `alarm-users/${householdId}`).pipe(map((response: any) => {
      // const resData = [];
      // for (const x of response.users) {
      //   console.log('x', x);
      //   if (x.user_type === 'admin' || x.user_type === 'emergency') {
      //     resData.push(x);
      //   }
      // }
      // return resData;
      return response;
      // return response.filter((user) => {
      //   return user.user_type === 'admin'
      // });
    }));
  }

  getEventUsers = (householdId) => {
    return this.http.get(this.apiEndpoint + `event-users/${householdId}`);
  }

  updateUsersPositions = (data) => {
    return this.http.patch(this.apiEndpoint + `users-update`, data);
  }

  onCompleteRefresh = () => {
    this.onRefresh.next();
  }

  getAllUsers = (householdId) => {
    return this.http.get(this.apiEndpoint + `get-users/${householdId}`);
  }

  addNewUser = (data) => {
    return this.http.post(this.apiEndpoint + `add-user`, data);
  }

  editUser = (data) => {
    return this.http.patch(this.apiEndpoint + `edit-user`, data);
    console.log('data', data);
  }

  deleteUser = (data) => {
    return this.http.patch(this.apiEndpoint + `delete-user`, data);
  }

  getMyData = (data) => {
    return this.http.post(this.apiEndpoint + `get-my-user`, data);
  }

  editMyData = (data) => {
    return this.http.patch(this.apiEndpoint + `update-my-user`, data);
  }
  changeAvatar = (fileToUpload: File) => {
    const formData: FormData = new FormData();
    if (fileToUpload !== null) {
      formData.append('avatar_img', fileToUpload, fileToUpload.name);
    }
    return this.http
      .post(this.apiEndpoint + `user-avatar`, formData)
      .pipe(map(response => {
        return response;
      }));
  }
  changeMyPassword = (data) => {
    return this.http.patch(this.apiEndpoint + `update-my-password`, data);
  }
}
