// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // production: false,
  //   // api: 'http://127.0.0.1:8000/api/',
  //   // firebase: {
  //   //   apiKey: 'AIzaSyCiECgGv-2Nx1yiN86Mnppwycm7URJk2kY',
  //   //   authDomain: 'hannah-a56b1.firebaseapp.com',
  //   //   databaseURL: 'https://hannah-a56b1.firebaseio.com/',
  //   //   projectId: 'hannah-a56b1',
  //   //   storageBucket: 'hannah-a56b1.appspot.com',
  //   //   messagingSenderId: '749572633114',
  //   //   appId: '1:749572633114:web:a10070a8dd615f8a7317a3',
  //   //   measurementId: 'G-MFRSQT4L46'
  //   // },
  //   // rpiUrl: 'http://10.9.0.46:3100/api/',
  //   // hotspotUrl: 'http://10.42.0.1:3100/api/'


  production: false,
  name: 'test',
  api: 'https://be-test.xvalue-services.de/api/',
  imagesApi: 'https://be-test.xvalue-services.de/storage/images/',
  firebase: {
    apiKey: 'AIzaSyCiECgGv-2Nx1yiN86Mnppwycm7URJk2kY',
    authDomain: 'hannah-a56b1.firebaseapp.com',
    databaseURL: 'https://hannah-a56b1.firebaseio.com/',
    projectId: 'hannah-a56b1',
    storageBucket: 'hannah-a56b1.appspot.com',
    messagingSenderId: '749572633114',
    appId: '1:749572633114:web:a10070a8dd615f8a7317a3',
    measurementId: 'G-MFRSQT4L46'
  },
  socketURL: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
