import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {pipe, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class HotlineService {

  private data = new Subject<boolean>();
  public data$ = this.data.asObservable();


  constructor(private http: HttpClient) {
  }

  sendHotlineStatus(value) {
    console.log('hotline status in hotline service: ', value);
    this.data.next(value);
  }

  get hotlineStatus() {
    const householdId = localStorage.getItem('household_id');
    return this.http.get(`${environment.api}hotline/is-hotline-booked/${householdId}`).pipe(map((response: any) => {
      return response;
    }));
  }
}
