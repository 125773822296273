import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, mapTo, tap} from 'rxjs/operators';
import {Token} from '../models/token';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly JWT_TOKEN_REFRESH = 'JWT_TOKEN_REFRESH';
  private loggedUser: string;
  private isLogged = false;
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private refreshTokenTimeout;
  private tokenTimeout;

  constructor(private http: HttpClient) {
    console.log('Don baba ksaaajdee')
    console.log(this.tokenTimeout)
    const hh_fullname = localStorage.getItem('household_fullName')
    const hh_id = localStorage.getItem('household_id')
    const jwt_token = localStorage.getItem('JWT_TOKEN')
    const jwt_token_refresh = localStorage.getItem('JWT_TOKEN_REFRESH')
    if(hh_fullname && hh_id && jwt_token && jwt_token_refresh){
      if(!this.tokenTimeout){
        this.loginUser()
      }
    }
  }

  login(user: { email: string, password: string, web }): Observable<boolean> {
    let body = new URLSearchParams()
    body.append('email', user.email);
    body.append('password', user.password);
    console.log('body to string: ', body.toString());
    return this.http.post<any>(`https://auth-${environment.name}.xvalue-services.de:4111/v1/user_login`, body.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
      .pipe(
        tap(data => this.doLoginUser(data)),
        mapTo(true),
        catchError(error => {
          return of(false);
        })
      );
  }

  getLoggedState() {
    return this.isLogged;
  }

  setLoggedState(isLogged) {
    this.isLogged = isLogged;
  };

  // logout(): Observable<boolean> {
  //   // this.doLogoutUser();
  //   return this.http.post(`${environment.api}logout`, {
  //     token: this.getJwtToken(),
  //     // fcm_token: localStorage.getItem('fcm_token'),
  //     // user_id: localStorage.getIt
  //     em('user_id')
  //   }).pipe(
  //     tap(() => this.doLogoutUser()),
  //     mapTo(true),
  //     catchError(error => {
  //       this.refreshToken();
  //       console.log(error.error);
  //       return of(false);
  //     })
  //   );
  // }
  logout(inactive=false) {
    this.http.post(`${environment.api}logout`, {
      token: this.getJwtToken()
    }).subscribe({
      next: (res)=>{
        this.doLogoutUser(inactive)
      },
      error: (err)=>{
        this.doLogoutUser(inactive)
      }
    })
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshTokenHEH() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem(this.JWT_TOKEN_REFRESH)
    });
    const options = {headers};
    this.http.post(`https://auth-${environment.name}.xvalue-services.de:4111/v1/user_refresh`, {}).subscribe((response: any) => {
      console.log('refresh token here', response.access_token);
      this.storeJwtToken(response.access_token);
    });
  }

  // refreshFcmToken(data) {
  //   console.log('fcm refresh', data);
  //   return this.http.post(`${environment.api}refreshtoken`, data).pipe();
  // }
  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  refreshJwtToken() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem(this.JWT_TOKEN_REFRESH)
    });
    const options = {headers};
    this.http.post(`https://auth-${environment.name}.xvalue-services.de:4111/v1/user_refresh`, {}).subscribe((response: any) => {
      console.log('refresh token here', response.access_token);
      this.storeJwtToken(response.access_token);
    }, error => {
      this.doLogoutUser();
    });
  }

  loginUser() {
    const jwtToken = this.getDecodedAccessToken(localStorage.getItem(this.JWT_TOKEN));
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (1000*30);
    this.tokenTimeout = setTimeout(() =>
      this.refreshTokenHah().subscribe((response: any) => {
        console.log('response token in start refresh token timer: in authentication: ', response);
        this.storeJwtToken(response.access_token);
        localStorage.setItem('JWT_TOKEN', response.access_token);
        this.loginUser();
        this.setLoggedState(true);
        this.getLoginInfo().subscribe((res: any) => {
          this.loggedUser = res.user.id;
          const informationToResolve = {
            token: localStorage.getItem('JWT_TOKEN'),
            userId: res.user.id
          };
        });
      }, error => {
        console.log('error unauthenticated: ', error);
        this.doLogoutUser();
        this.stopTokenTimer();
      }), timeout);
  }

  private doLoginUser(data) {
    console.log('data at login', data);
    // this.user.role = data.user.user_type;
    // console.log('this user role', this.user.role);
    this.storeToken(data.access_token);
    localStorage.setItem(this.JWT_TOKEN, data.access_token);
    localStorage.setItem('JWT_TOKEN_REFRESH', data.refresh_token);
    // const jwtToken = this.getDecodedAccessToken(localStorage.getItem(this.JWT_TOKEN));
    // // set a timeout to refresh the token a minute before it expires
    // const expires = new Date(jwtToken.exp * 1000);
    // const timeout = expires.getTime() - Date.now() - (10 * 1000);
    // console.log('expires and timeout', jwtToken, 'timeout: ', timeout);
    this.loginUser();
    // this.tokenTimeout = setTimeout(() =>
    //   this.refreshTokenHah().subscribe(((response: any) => {
    //     console.log('response token in start refresh token timer: in auhtentication ', response);
    //     localStorage.setItem('JWT_TOKEN', response.access_token);
    //     this.getLoginInfo().subscribe((res: any) => {
    //       this.loggedUser = res.user.id;
    //       const informationToResolve = {
    //         token: localStorage.getItem('JWT_TOKEN'),
    //         userId: res.user.id
    //       };
    //     });
    //   })), timeout);

    // this.http.get(`${environment.api}get-login-info`).subscribe((response: any) => {
    //   console.log('response in get login info', response);
    //   this.loggedUser = response.user.id;
    //   localStorage.setItem('user_id', response.user.id);
    //   const informationToResolve = {
    //     token: localStorage.getItem('fcm_token'),
    //     userId: response.user.id
    //   };
    // })

    this.setLoggedState(true);
    // this.refreshFcmToken(informationToResolve).subscribe(result => {
    //   console.log('information to resolve', result);
    // });
  }

  getLoginInfo() {
    return this.http.get(`${environment.api}get-login-info`).pipe(map(response => {
      return response;
    }));
  }

  private doLogoutUser(inactive=false) {
    this.loggedUser = null;
    this.setLoggedState(false);
    this.removeToken();
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.clear();
    if(inactive){
      localStorage.setItem('logOut_dueTo_Inactivity', 'true')
    }
    //window.location.reload();
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private refreshTokenHah() {
    this.stopTokenTimer();
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem('JWT_TOKEN_REFRESH')
    });
    const options = {headers};
    return this.http.post(`https://auth-${environment.name}.xvalue-services.de:4111/v1/user_refresh`, options).pipe(map(response => {
      return response;
    }));
  }

  getId() {
    this.http.get(`${environment.api}get-login-info`).subscribe((response: any) => {
      console.log('response in get login info', response);
      return response.user.id;
    });
    // const token = localStorage.getItem('JWT_TOKEN');
    // const jwtData = token.split('.')[1];
    // const decodedJwtJsonData = window.atob(jwtData)
    // const decodedJwtData = JSON.parse(decodedJwtJsonData)
    //
    // return decodedJwtData.sub;
    // console.log('jwtData: ' + jwtData)
    // console.log('decodedJwtJsonData: ' + decodedJwtJsonData)
    // console.log('decodedJwtData: ' + decodedJwtData)
  }

  private storeToken(token) {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  private removeToken() {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private startRefreshTokenTimer() {
    this.stopTokenTimer();
    // parse json object from base64 encoded jwt token
    const jwtToken = this.getDecodedAccessToken(localStorage.getItem('JWT_TOKEN_REFRESH'));
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (1000);
    console.log('jwt token', jwtToken, '\n timeout: ', timeout, '\n expires: ', expires);
    this.refreshTokenTimeout = setTimeout(() => {
      console.log('token expired redirect to login');
      this.stopRefreshTokenTimer();
      this.logout();
      // localStorage.setItem('JWT_TOKEN_REFRESH', response.refresh_token);
    }, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private stopTokenTimer() {
    clearTimeout(this.tokenTimeout);
  }
}
