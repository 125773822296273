import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {count} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AlarmStateService {
  alarmData;
  data;
  type;
  alarmType;
  interval;
  alarmState = new Subject();
  //sharedAlarmData = this.alarmState.asObservable();
  householdId;

  constructor(private http: HttpClient) {
  }

  nextAlarmState(data: any) {
    this.alarmState.next(data);
  }

  shareAlarmState(type) {
    this.alarmData = type;
    this.nextAlarmState(this.alarmData);
  }

  doubleCheckAlarm(householdId) {
    clearInterval(this.interval);
    let countInterval = 0;
    this.interval = setInterval(() => {
      console.log('checking alarm state');
      this.checkAlarmState(householdId);
      countInterval++;
      if (countInterval === 1) {
        setTimeout(() => {
          console.log('clearing interval');
          clearInterval(this.interval);
          countInterval = 0;
        }, 30000);
      }
    }, 10000);
  }

  checkAlarmState(householdId) {
    this.http.get(`${environment.api}alarm-type/${householdId}`).subscribe(res => {
      this.alarmType = res;
      console.log('alarm TYPEEEEEEE', res);
      if (this.alarmType.result === 'alarm') {
        this.shareAlarmState('alarm');
      } else if (this.alarmType.result === 'event') {
        this.shareAlarmState('event');
      } else if (this.alarmType.result === 'end' || this.alarmType.result === 'endEvent') {
        this.shareAlarmState('end');
      } else if (this.alarmType.result === 'No active event or alarm for this household!') {
        this.shareAlarmState('end');
      }
    });
  }
  checkUserActiveAlarm(): Observable<any>{
    return this.http.get(`${environment.api}alarm-active-user`)
  }
  checkAlarmState2(householdId): Observable<any>{
    return this.http.get(`${environment.api}alarm-type/${householdId}`)
  }
}
