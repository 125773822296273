import {Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Services} from '../../services';
import {first} from 'rxjs/operators';
import {HouseholdService} from '../../services/household.service';
import {SharedService} from '../../services/shared.service';
import {environment} from '../../../environments/environment';
import {HotlineService} from '../../services/hotline.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AlarmStateService} from '../../services/alarm-state.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alarmkette-bearbeiten',
  templateUrl: './alarmkette-bearbeiten.component.html',
  styleUrls: ['./alarmkette-bearbeiten.component.css']
})
export class AlarmketteBearbeitenComponent implements OnInit, OnDestroy {
  empty = [];
  householdId;
  alarmUsers: any;
  usersWithPosition1: any = [];
  usersWithoutPosition1: any = [];
  usersWithPosition: any = [];
  usersWithoutPosition: any = [];
  usersPosition1: any = [];
  usersPosition2: any = [];
  usersPosition: any = [];
  alarmUsersPosition: any;
  secondArray: any = [];
  firstArray: any = [];
  isHotlineBooked = false;
  url;
  hotlineBookedSubs;
  isBooked;
  subscribedValue = {
    MIN_VALUE: 1,
    MAX_VALUE: 10
  }
  alarmStateData;
  subscriptions: Subscription;
  @ViewChild('template') template

  constructor(private services: Services,
              private householdService: HouseholdService,
              private zone: NgZone,
              private sharedService: SharedService,
              private hotlineService: HotlineService,
              private translateService: TranslateService,
              private toasterService: ToastrService,
              public alarmStateService: AlarmStateService,
              private modalService: BsModalService,
              public bsModalRef: BsModalRef,
  ) {
    this.subscriptions = new Subscription
    this.sharedService.invokeEvent.subscribe(value => {
      if(value === 'close-alarmChain-modal'){
        this.getAlarmUsers(); 
        this.bsModalRef.hide(); 
      }
      if(value ==='accept-alarmChain-changes'){
        this.updateUserPositions()
        this.bsModalRef.hide(); 
      }
    });
  }

  ngOnInit(): void {
    this.householdId = localStorage.getItem('household_id');
    this.alarmStateService.checkAlarmState(this.householdId);

    const sharedAlarmData = this.alarmStateService.alarmState.subscribe(response => {
      this.zone.run(() => {
        this.alarmStateData = response;
      });
    });
    this.subscriptions.add(sharedAlarmData)
    console.log('is Booked value', this.isBooked);

    const updatedHousehold = this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.getAlarmUsers();
    });
    this.subscriptions.add(updatedHousehold)

    this.getAlarmUsers();

    this.hotlineBookedSubs = this.hotlineService.data$.subscribe(response => {
      console.log('hotline subs etc');
    });
    this.subscriptions.add(this.hotlineBookedSubs)
  }

  getAlarmUsers() {
    
    this.householdId = localStorage.getItem('household_id');
    this.url = environment.imagesApi;

    const getAlarmUsers = this.services.getAlarmUsers(this.householdId).subscribe((data: any) => {
      this.usersWithoutPosition1.length = 0;
      this.usersWithPosition1.length = 0;
      this.usersWithPosition.length = 0;
      this.usersWithoutPosition.length = 0;
     
      for (let i = 0; i < data.users.length; i++) {
        if (data.users[i].pivot.position !== 100
          && (data.users[i].user_type === 'admin'
            || data.users[i].user_type === 'emergency'
            || data.users[i].user_type === 'owner')) {
          this.usersWithPosition1.push(data.users[i]);
        } else if ((data.users[i].user_type === 'admin'
          || data.users[i].user_type === 'emergency'
          || data.users[i].user_type === 'owner')
          && data.users[i].pivot.position === 100) {
          this.usersWithoutPosition1.push(data.users[i]);
        }
      }
      this.usersWithPosition = this.usersWithPosition1;
      this.usersWithoutPosition = this.usersWithoutPosition1;
      console.log('user ', this.usersWithoutPosition);
      this.usersWithPosition.sort((a, b) => a.pivot.position - b.pivot.position);
    });
    this.subscriptions.add(getAlarmUsers)

    const hotlineStatus = this.hotlineService.hotlineStatus.subscribe(response => {
      console.log('response from hotline subsc: ', response);
      if (response.result) {
        this.isHotlineBooked = response.result.is_booked;
      } else {
        this.isHotlineBooked = response.is_booked;
      }
      console.log('is hotline booisHotlineBookedked: ', this.isHotlineBooked);
      if (this.isHotlineBooked) {
        this.subscribedValue.MIN_VALUE = 0
        this.subscribedValue.MAX_VALUE = 1

      } else {
        this.subscribedValue.MIN_VALUE = 1
        this.subscribedValue.MAX_VALUE = 10
      }
    })
    this.subscriptions.add(hotlineStatus)
  }

  drop(event: CdkDragDrop<any[]>, state) {
    this.alarmStateService.checkAlarmState(this.householdId);
    if(this.alarmStateData == 'alarm' || this.alarmStateData == 'event'){
      this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'));
      return false
    }
    console.log('event', event);
    if (state === 'right') {
      if (this.usersWithPosition.length == this.subscribedValue.MIN_VALUE) {
        this.toasterService.warning(this.translateService.instant('toasterMessages.alarmMinimumUsersWarning'));
      } else {
        if (event.previousContainer === event.container) {
          console.log('DROP ON IF 1 EVENT CONTAINER DATA', event.container.data);
          console.log('DROP ON IF 1 EVENT PREVIOUS INDEX', event.previousIndex);
          console.log('DROP ON IF 1 EVENT CURRENT INDEX', event.currentIndex);
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          this.usersPosition1.length = 0;
          for (let i = 0; i < event.container.data.length; i++) {
            event.container.data[i].pivot.position = i + 1;
            this.usersPosition1[i] = {
              id: event.container.data[i].id,
              household_id: event.container.data[i].pivot.household_id,
              user_id: event.container.data[i].pivot.user_id,
              position: '100'
            };
          }
          this.usersPosition.push(this.usersPosition1);

        } else {
          // if (event.container.id === 'cdk-drop-list-1') {
          console.log('left to right EVENT PREVIOUS CONTAINER DATA', event.previousContainer.data);
          console.log('left to right EVENT CONTAINER DATA', event.container.data);
          console.log('left to right EVENT PREVIOUS INDEX', event.previousIndex);
          console.log('left to right EVENT CURRENT INDEX', event.currentIndex);
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
          this.usersPosition1.length = 0;
          this.usersPosition2.length = 0;
          for (let i = 0; i < event.container.data.length; i++) {
            console.log('event container data ', i, ': ', event.container.data[i].pivot.position);
            event.container.data[i].pivot.position = i + 1;
            this.usersPosition1[i] = {
              id: event.container.data[i].id,
              household_id: event.container.data[i].pivot.household_id,
              user_id: event.container.data[i].pivot.user_id,
              position: '100',
            };
          }
          this.usersPosition = this.usersPosition1;
          for (let i = 0; i < event.previousContainer.data.length; i++) {
            console.log('event container data ', i, ': ', event.previousContainer.data[i].pivot.position);
            event.previousContainer.data[i].pivot.position = i + 1;
            this.usersPosition2[i] = {
              id: event.previousContainer.data[i].id,
              household_id: event.previousContainer.data[i].pivot.household_id,
              user_id: event.previousContainer.data[i].pivot.user_id,
              position: event.previousContainer.data[i].pivot.position
            };
          }
          this.usersPosition = this.usersPosition.concat(this.usersPosition2);
          console.log('first array', this.firstArray);
          console.log('second array', this.secondArray);
          console.log('user position array', this.usersPosition);
          
          console.log('this.userposition', this.usersPosition);

          this.alarmUsersPosition = JSON.stringify(this.usersPosition);
 
          console.log('this.alarmUsersPosition', this.alarmUsersPosition);

          this.confirmChangesModal(this.template)

          // const updateUserPosition = this.services.updateUsersPositions({type: 'alarm', pivot: this.alarmUsersPosition})
          //   .pipe(first())
          //   .subscribe((data: any) => {
          //     this.usersPosition.length = 0;
          //     this.usersPosition1.length = 0;
          //     this.usersPosition2.length = 0;
          //     this.alarmUsersPosition = '';
          //     // this.alarmUsersPosition;
          //     console.log('update data', data);
          //   });
          //   this.subscriptions.add(updateUserPosition)
        }
      }

    } else {
      console.log('users with position: ', this.usersWithPosition.length, 'max value: ', this.subscribedValue.MAX_VALUE)
      if (this.usersWithPosition.length < this.subscribedValue.MAX_VALUE) {
        console.log('subscribed value', this.subscribedValue.MAX_VALUE)
        if (event.previousContainer === event.container) {
          if(event.previousIndex == event.currentIndex){
            return false
          }
          console.log('move item in left side EVENT CONTAINER DATA', event.container.data);
          console.log('move item in left side 1 PREVIOUS INDEX', event.previousIndex);
          console.log('move item in left side 1 CURRENT INDEX', event.currentIndex);
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          this.usersPosition1.length = 0;
          for (let i = 0; i < event.container.data.length; i++) {
            event.container.data[i].pivot.position = i + 1;
            this.usersPosition1[i] = {
              id: event.container.data[i].id,
              household_id: event.container.data[i].pivot.household_id,
              user_id: event.container.data[i].pivot.user_id,
              position: event.container.data[i].pivot.position,
            };
          }
          this.usersPosition = this.usersPosition1;

        } else {
          console.log('right to left EVENT PREVIOUS CONTAINER DATA', event.previousContainer.data);
          console.log('right to left ELSE 1 EVENT CONTAINER DATA', event.container.data);
          console.log('right to left ELSE 1 EVENT PREVIOUS INDEX', event.previousIndex);
          console.log('right to left ELSE 1 EVENT CURRENT INDEX', event.currentIndex);
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
          this.usersPosition1.length = 0;
          for (let i = 0; i < event.container.data.length; i++) {
            event.container.data[i].pivot.position = i + 1;
            this.usersPosition1[i] = {
              id: event.container.data[i].id,
              household_id: event.container.data[i].pivot.household_id,
              user_id: event.container.data[i].pivot.user_id,
              position: event.container.data[i].pivot.position,
            };
          }
          this.usersPosition = this.usersPosition1;

        }
        this.alarmUsersPosition = JSON.stringify(this.usersPosition);

        console.log('this.alarmUsersPosition', this.alarmUsersPosition);

        this.confirmChangesModal(this.template)

        // const updateUserPosition = this.services.updateUsersPositions({type: 'alarm', pivot: this.alarmUsersPosition})
        //   .pipe(first())
        //   .subscribe((data: any) => {
        //     this.usersPosition.length = 0;
        //     this.usersPosition1.length = 0;
        //     this.usersPosition2.length = 0;
        //     this.alarmUsersPosition = '';
        //     // this.alarmUsersPosition.length = 0;
        //     console.log('update data', data);
        //   });
        //   this.subscriptions.add(updateUserPosition)
      } else {
        if (this.isHotlineBooked) {
          this.toasterService.warning(this.translateService.instant('toasterMessages.alarmMaximumUsersHotlineBooked'));
        } else {
          this.toasterService.warning(this.translateService.instant('toasterMessages.alarmMaximumUsersWarning'));
        }
      }

    }

  }

  updateUserPositions(){
    this.alarmStateService.checkAlarmState(this.householdId)
    const alarmState = this.alarmStateService.checkAlarmState2(this.householdId).subscribe({
      next:(response) => {
        if(response.result == 'alarm' || response.result == 'event'){
          this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'));
          this.getAlarmUsers()
          return false
        }
        const updateUserPosition = this.services.updateUsersPositions({type: 'alarm', pivot: this.alarmUsersPosition})
          .pipe(first())
          .subscribe((data: any) => {
            this.usersPosition.length = 0;
            this.usersPosition1.length = 0;
            this.usersPosition2.length = 0;
            this.alarmUsersPosition = '';
            // this.alarmUsersPosition.length = 0;
            console.log('update data', data);
          });
          this.subscriptions.add(updateUserPosition)
      }
    })
    this.subscriptions.add(alarmState)
  }

  nAToaster(){
    this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'));
  }

  confirmChangesModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template, {backdrop: 'static',  keyboard: false});
  }

  ngOnDestroy() {
    this.hotlineBookedSubs.unsubscribe();
    this.subscriptions.unsubscribe()
  }
}
