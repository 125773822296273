import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AlarmService} from 'src/app/services/alarm.service';
import {DatePipe} from '@angular/common';
import {SharedService} from '../../services/shared.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alarmierungs-historie',
  templateUrl: './alarmierungs-historie.component.html',
  styleUrls: ['./alarmierungs-historie.component.css']
})
export class AlarmierungsHistorieComponent implements OnInit, OnDestroy {
  date = new Date();
  householdName;
  householdAvatar;
  // fromHour = '10:00';
  // toHour = '11:00';
  convertedFromDate;
  convertedToDate;
  countPrevious = 0;
  hideNext = false;
  yesterdayDate = new Date();
  todayDate = new Date();
  fromDate;
  toDate;
  householdId;
  nextPage;
  previousValue = false;
  previousPage;
  fromType = false;
  refreshValue = false;
  prevId;
  histories = [];
  resIds = null;
  alarmHistory = true;
  maxDate = new Date();
  alarmType = 'alarm';
  total = 0;
  times = [];
  url;
  totalShown = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  last_page;
  fromTime;
  toTime;
  currentPage = 0;
  // eventHistory;
  datePickerConfig = {
    dateInputFormat: 'DD-MM-YYYY',
    containerClass: 'theme-blue',
    isAnimated: true,
    adaptivePosition: true,
    showTodayButton: true,
  };
  alarmMessage = '';
  bsValue = new Date();

  subscriptions:Subscription

  constructor(private alarmService: AlarmService, private sharedService: SharedService, private zone: NgZone, private translate: TranslateService) {
    this.maxDate.setDate(this.maxDate.getDate());
    this.subscriptions = new Subscription
  }

  ngOnInit() {
    this.url = environment.imagesApi;
    this.householdName = localStorage.getItem('household_fullName');
    this.householdAvatar = localStorage.getItem('household_avatar');
    this.householdId = localStorage.getItem('household_id');

    const updatedHousehold = this.sharedService.getUpdatedHousehold().subscribe(res => {
      this.householdName = localStorage.getItem('household_fullName');
      this.householdAvatar = localStorage.getItem('household_avatar');
      this.householdId = localStorage.getItem('household_id');
      this.url = environment.imagesApi;
      this.setHistoryType(0);
    });
    this.subscriptions.add(updatedHousehold)

    const quarterHours = ['00', '15', '30', '45'];
    for (let i = 19; i < 22; i++) {
      for (let j = 0; j < 4; j++) {
        let time = i + ':' + quarterHours[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.times.push(time);
      }
    }
    // eslint-disable-next-line new-parens
    this.yesterdayDate = (d => new Date(d.setDate(d.getDate() - 10)))(new Date);
    this.setHistoryType(0);
  }

  setHistoryType(type) {
    console.log('typeeeeeeeee', type);
    this.refreshHistory();
    this.fromType = true;
    if (type) {
      this.alarmHistory = false;
      this.alarmType = 'event';
    } else {
      this.alarmHistory = true;
      this.alarmType = 'alarm';
    }
    this.fromDate = this.yesterdayDate;
    this.toDate = this.todayDate;
    
    console.log(this.fromDate);
    const data = {
      householdId: this.householdId,
      fromDate: this.formatDate(this.fromDate),
      toDate: this.formatDate(this.toDate),
      type: this.alarmType,
      nextPage: this.nextPage,
      ids: []
    };
    this.getHistory(data);
  }

  fetchHistory(date, e) {
    this.refreshHistory();
    console.log(date, 'e', e);
    if (date == 1) {
      this.fromDate = e;
    } else if (date == 2) {
      this.toDate = e;
    } else {
      this.fromDate = this.fromDate;
      this.toDate = this.toDate;
      console.log('from to', this.fromDate, this.toDate);
    }
    if (this.fromDate && this.toDate) {
      const data = {
        householdId: this.householdId,
        fromDate: this.formatDate(this.fromDate),
        toDate: this.formatDate(this.toDate),
        type: this.alarmType,
        nextPage: this.nextPage,
        ids: []
      };
      this.getHistory(data);
    }
  }

  getHistory(data) {
    if (!this.previousValue) {
      this.previousPage = 0;
    }
    const getHistory = this.alarmService.getHistory(this.householdId, data.fromDate, data.toDate,
      this.currentPage, data.type, this.previousPage, this.nextPage,
      this.resIds).subscribe((response: any) => {
      if (response.message) {
        this.alarmMessage = this.translate.instant('dashboard.alarmHistory.noAlarmDescription');
      } else {
        this.alarmMessage = '';
      }
      this.histories = [];
      if (response.latest_id == 'no more records') {
        this.hideNext = true;
      } else {
        this.nextPage = response.latest_id;
        this.hideNext = false;
      }
      if (!this.previousValue) {
        this.resIds = response.ids;
      }
      if (this.previousValue) {
        this.resIds.pop();
      }
      if (this.currentPage > 0) {
        this.previousPage = this.resIds[this.currentPage - 1][0];
      }

      this.totalShown = 0;
      this.total = response.histories;
      this.last_page = response.last_page;
      
      if (this.alarmType == 'alarm' && response.histories) {
        for (let i = 0; i < response.histories.length; i++) {
          response.histories[i].alarm_history.forEach(element => {
            this.totalShown++;
            if (element.alarm_status.includes('title')) {
              element.alarm_status = JSON.parse(element.alarm_status).title + ' | ' + JSON.parse(element.alarm_status).desc
            }
            this.histories.push(element);
          });
        }

      } else if (this.alarmType == 'event' && response.histories) {
        for (let i = 0; i < response.histories.length; i++) {
          response.histories[i].event_history.forEach(element => {
            this.totalShown++;
            if (element.event_status.includes('title')) {
              element.event_status = JSON.parse(element.event_status).title + ' | ' + JSON.parse(element.event_status).desc
            }
            this.histories.push(element);
          });
        }
      }
      this.histories.sort((a,b)=>{
        return new Date(b.obs_timestamp).getTime() - new Date(a.obs_timestamp).getTime();
      })
    }, error => {
      this.refreshHistory();
      this.setHistoryType(0);
    });
    this.subscriptions.add(getHistory)
  }


  goToNextPage() {
    this.fromType = false;
    this.previousValue = false;
    this.currentPage += 1;
    const data = {
      householdId: this.householdId,
      fromDate: this.formatDate(this.fromDate),
      toDate: this.formatDate(this.toDate),
      type: this.alarmType,
      nextPage: this.nextPage,
      previousPage: 0,
    };
    this.getHistory(data);
  }

  goToPreviousPage() {
    this.fromType = false;
    this.previousValue = true;
    this.currentPage -= 1;
    if (this.currentPage < 0) {
      this.refreshHistory();
    }
    // need to do when user goes to -1 page to refresh resId
    const data = {
      householdId: this.householdId,
      fromDate: this.formatDate(this.fromDate),
      toDate: this.formatDate(this.toDate),
      type: this.alarmType,
      nextPage: this.nextPage,
      previousPage: this.previousPage,
    };
    this.getHistory(data);
  }

  formatDate(d) {
    const date = new Date(d);
    let dd: any = date.getDate();
    let mm: any = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return d = yyyy + '-' + mm + '-' + dd;
  }

  refreshHistory() {
    if (this.resIds != null || this.resIds != undefined) {
      this.currentPage = 0;
      this.nextPage = 0;
      this.previousPage = 0;
      this.resIds.length = 0;
    }
    this.refreshValue = false;
  }

  getData() {
    this.refreshHistory();
    if (this.fromDate && this.toDate) {
      const data = {
        householdId: this.householdId,
        fromDate: this.formatDate(this.fromDate),
        toDate: this.formatDate(this.toDate),
        type: this.alarmType,
        nextPage: this.nextPage,
        ids: []
      };
      this.getHistory(data);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
