import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AlarmStateService} from './alarm-state.service';

@Injectable({
  providedIn: 'root'
})

export class EventScoreService {
  scoreData;
  scoreTotal = 0;
  interval;
  scoreTitle = 'Aktueller Status';
  scoreSubtitle = 'Keine nennenswerten Auffälligkeiten';
  gaugeIcon = 'defaultIcon'
  score = new Subject();
  //sharedScoreData = this.score.asObservable();
  householdId;
  private eventSubject = new Subject<void>();

  constructor(private alarmStateService: AlarmStateService) {
  }

  sendUpdatedEvent() {
    this.eventSubject.next();
  }

  getUpdatedEvent(): Observable<any> {
    return this.eventSubject.asObservable();
  }

  nextAlarmState(data: any) {
    console.log('score data in next', data);
    this.score.next(data);
  }

  shareScoreData(data) {
    console.log('share score data', data);
    this.scoreData = data;
    this.nextAlarmState(this.scoreData);
  }

  calculateScore(score) {
    const data = {
      scoreTotal: this.scoreTotal,
      title: 'Aktueller Status',
      subtitle: 'Keine nennenswerten Auffälligkeiten'
    }

    this.scoreData = JSON.parse(score);
    const tableData = [];
    this.scoreData.all_scores.filter((result): any => {
      if (result.score !== 0 && result.table_name !== 'events_scores_aggregation') {
        tableData.push(result.table_name);
      }
    });
    if (tableData.length === 1) {
      this.gaugeIcon = tableData[0];
      localStorage.setItem('gaugeIcon', tableData[0]);
      console.log('only one ', tableData[0]);
    } else {
      console.log('more than one', tableData);
      this.gaugeIcon = 'defaultIcon';
      localStorage.setItem('gaugeIcon', 'defaultIcon');
    }
    // const tableData = [];
    // this.scoreData.all_scores.map(({table_name}) => {
    //   if (table_name !== 'events_scores_aggregation') {
    //     tableData.push(table_name);
    //   }
    // });
    // if (tableData.length === 1) {
    //   this.gaugeIcon = tableData[0];
    //   localStorage.setItem('gaugeIcon', tableData[0]);
    // } else {
    //   this.gaugeIcon = 'defaultIcon';
    //   localStorage.setItem('consumptionIcon', 'defaultIcon');
    // }
    console.log('score data', this.scoreData);
    if (this.scoreData.event_level == 'minor') {
      this.scoreTotal = 30;
      this.scoreTitle = 'Aktives Ereignis';
      this.scoreSubtitle = 'Ereignis erkannt';
    } else if (this.scoreData.event_level == 'major') {
      this.scoreTotal = 70;
      this.scoreTitle = 'Aktives Ereignis';
      this.scoreSubtitle = 'Schwerwiegendes Ereignis erkannt';
    } else if (this.scoreData.event_level == 'critical') {
      this.scoreTotal = 100;
      this.scoreTitle = 'Aktives Ereignis';
      this.scoreSubtitle = 'Kritisches Ereignis erkannt';
    } else {
      this.scoreTotal = 0;
      this.scoreTitle = 'Aktueller Status';
      this.scoreSubtitle = 'Keine nennenswerten Auffälligkeiten'
    }
    localStorage.setItem('scoreTotal', this.scoreTotal.toString());
    this.sendUpdatedEvent();
  }

  doubleCheckEvent(householdId) {
    clearInterval(this.interval);
    let countInterval = 0;
    this.interval = setInterval(() => {
      console.log('event double check running');
      this.alarmStateService.checkAlarmState(householdId);
      this.scoreTotal = 0;
      this.gaugeIcon = 'defaultIcon';
      this.scoreTitle = 'Aktueller Status';
      this.scoreSubtitle = 'Keine nennenswerten Auffälligkeiten'
      localStorage.removeItem('scoreTotal');
      localStorage.removeItem('gaugeIcon');
      this.shareScoreData(this.scoreTitle);
      countInterval++;
      if (countInterval === 1) {
        setTimeout(() => {
          console.log('clearing interval');
          clearInterval(this.interval);
          countInterval = 0;
        }, 120000);
      }
    }, 10000);
  }

  resetScore() {
    this.scoreTotal = 0;
    this.gaugeIcon = 'defaultIcon';
    this.scoreTitle = 'Aktueller Status';
    this.scoreSubtitle = 'Keine nennenswerten Auffälligkeiten'
    localStorage.removeItem('scoreTotal');
    localStorage.removeItem('gaugeIcon');
    this.sendUpdatedEvent();
    this.shareScoreData(this.scoreTitle);
  }
}
