import {Component, NgZone, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DatePipe} from '@angular/common';
import {CalendarService} from '../../../services/calendar.service';
import {SharedService} from '../../../services/shared.service';
import {HouseholdService} from '../../../services/household.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../auth/services/auth.service';
import {OwlDateTimeIntl} from 'ng-date-and-time-picker';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {
  householdId;
  isOwnerHome = 1;
  series: any[] = [
    {
      id: 0, name: this.translate.instant('calendar.daily'), state: false
    },
    {
      id: 1, name: this.translate.instant('calendar.weekly'), state: false
    },
    {
      id: 2, name: this.translate.instant('calendar.monthly'), state: false
    },
    {
      id: 3, name: this.translate.instant('calendar.yearly'), state: false
    },
  ];
  viewAs: any[] = [
    {
      id: 0, name: this.translate.instant('calendar.absent'), state: false
    },
    {
      id: 1, name: this.translate.instant('calendar.atHome'), state: false
    },
  ];

  resourcesList = [
    {
      id: 0,
      name: this.translate.instant('calendar.on'),
      checked: true
    },
    {
      id: 1,
      name: this.translate.instant('calendar.after'),
      checked: false
    }
  ];
  selectedResource = this.resourcesList[0].id;

  data;
  type;
  selectedView;
  editEventForm;
  selectedSeries;
  recurrenceCount;
  seriesId;
  recurrence;

  constructor(private modalRefEditEvent: BsModalRef,
              private datePipe: DatePipe,
              private dateTimeIntl: OwlDateTimeIntl,
              private calendarService: CalendarService,
              private sharedService: SharedService,
              private translate: TranslateService,
              private authService: AuthService,
              private http: HttpClient,
  ) {
    this.dateTimeIntl.cancelBtnLabel = this.translate.instant('global.cancel');
    this.dateTimeIntl.setBtnLabel = this.translate.instant('global.confirm');
  }

  ngOnInit(): void {
    this.householdId = localStorage.getItem('household_id');
    console.log('initial state', this.data, 'and type', this.type);
    this.editData(this.data);
    if (this.data.recurrence === 'daily') {
      this.selectedSeries = this.series[0].name;
      this.recurrence = 'daily';
    } else if (this.data.recurrence === 'weekly') {
      this.selectedSeries = this.series[1].name;
      this.recurrence = 'weekly';
    } else if (this.data.recurrence === 'monthly') {
      this.recurrence = 'monthly';
      this.selectedSeries = this.series[2].name;
    } else if (this.data.recurrence === 'yearly') {
      this.recurrence = 'yearly';
      this.selectedSeries = this.series[3].name;
    }
    this.recurrenceCount = this.data.recurrenceCount;
  }
  charValidator(control: FormControl): { [key: string]: boolean } {
    const nameRegexp: RegExp =  /[ -~äöüÄÖÜß]/
    if (control.value && !nameRegexp.test(control.value)) {
      return { invalidName: true };
    }
  }
  editData(eventData) {
    console.log('event data', eventData);
    this.editEventForm = new FormGroup({
      id: new FormControl(eventData.Id, [
        Validators.required
      ]),
      title: new FormControl(eventData.Subject, [
        Validators.required, this.charValidator
      ]),
      description: new FormControl(eventData.description, [
        Validators.required, this.charValidator
      ]),
      is_owner_home: new FormControl(this.viewAs[eventData.isOwnerHome].name, [
        Validators.required
      ]),
      startDate: new FormControl(eventData.StartTime, [
        Validators.required
      ]),
      endDate: new FormControl(eventData.EndTime, [
        Validators.required
      ]),
      recurrenceCount: new FormControl(eventData.recurrenceCount),
      recurrence_date: new FormControl(new Date(eventData.recurrence_date)),
      clicked_date: new FormControl(eventData.clicked_date)
    });
    if(this.editEventForm.get('recurrenceCount').value == 0){
      this.selectedResource = this.resourcesList[0].id;
      this.resourcesList[0].checked = true;
      this.resourcesList[1].checked = false;
    }else{
      this.selectedResource = this.resourcesList[1].id;
      this.resourcesList[0].checked = false;
      this.resourcesList[1].checked = true;
    }
  }

  selectView(id) {
    console.log('selected view id: ', id);
    this.selectedView = this.viewAs[id].name;
  }

  selectSeries(id) {
    console.log('selected series id: ', id);
    this.selectedSeries = this.series[id].name;
    this.seriesId = id;
    if (id === 0) {
      this.recurrence = 'daily';
    } else if (id === 1) {
      this.recurrence = 'weekly';
    } else if (id === 2) {
      this.recurrence = 'monthly';
    } else if (id === 3) {
      this.recurrence = 'yearly';
    }
  }

  saveEventData() {
    if(this.editEventForm.valid){
      let timebetweenStartEnd = Math.abs(new Date(this.editEventForm.get('endDate').value).getTime() - new Date(this.editEventForm.get('startDate').value).getTime())
      let minutes:any = timebetweenStartEnd/(1000*60)
      const hours = Math.floor(minutes/60)
      const endDateHour = new Date(this.editEventForm.get('endDate').value).getHours()
      const endDateMinutes = new Date(this.editEventForm.get('endDate').value).getMinutes()
      if(this.recurrence == 'daily'){
        if(hours>=24){
          let endDate = new Date(this.editEventForm.get('startDate').value).setHours(endDateHour)
          endDate = new Date(endDate).setMinutes(endDateMinutes)
          this.editEventForm.patchValue({
            endDate : new Date(endDate)
          })
        }
      }else if(this.recurrence == 'weekly'){
        if(hours/24>=7){
          let endDate = new Date(this.editEventForm.get('startDate').value).getTime() + 6*24*60*60*1000
          endDate = new Date(endDate).setMinutes(endDateMinutes)
          this.editEventForm.patchValue({
            endDate : new Date(endDate)
          })
        }
      }else if(this.recurrence == 'monthly'){
        if(hours/24>=30){
          let endDate = new Date(this.editEventForm.get('startDate').value).getTime() + 29*24*60*60*1000
          endDate = new Date(endDate).setMinutes(endDateMinutes)
          this.editEventForm.patchValue({
            endDate : new Date(endDate)
          })
        }
      }else if(this.recurrence == 'yearly'){
        if(hours/24>=365){
          let endDate = new Date(this.editEventForm.get('startDate').value).getTime() + 364*24*60*60*1000
          endDate = new Date(endDate).setMinutes(endDateMinutes)
          this.editEventForm.patchValue({
            endDate : new Date(endDate)
          })
        }
      }
      // const userId = localStorage.getItem('user_id');
      const userId = this.authService.getId();
      if (this.selectedView  !== this.translate.instant('calendar.atHome')) {
        this.isOwnerHome = 0;
      }
      let rruleStartDate = this.datePipe.transform(this.editEventForm.value.startDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[0]
      let rruleStartTime = this.datePipe.transform(this.editEventForm.value.startDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
      let rruleEndDate = this.datePipe.transform(this.editEventForm.value.endDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[0]
      let rruleEndTime = this.datePipe.transform(this.editEventForm.value.endDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
      let recurrenceDate
      if(this.selectedResource==0){
        this.recurrenceCount = 0
        recurrenceDate = new Date(this.editEventForm.value.recurrence_date)
        this.editEventForm.patchValue({
          recurrenceCount : 0
        })
      }else{
        recurrenceDate =  new Date(this.editEventForm.value.endDate)
      }
      if(this.recurrence != 'none'){
        if(this.editEventForm.value.recurrenceCount!=0){
          this.recurrenceCount = this.editEventForm.get('recurrenceCount').value
          this.editEventForm.patchValue({
            recurrence_date : recurrenceDate
          })
          if(this.recurrence == 'daily'){
            recurrenceDate.setDate(recurrenceDate.getDate()+(this.recurrenceCount-1))
            this.editEventForm.patchValue({
              recurrence_date : recurrenceDate
            })
          }else if(this.recurrence == 'weekly'){
            this.editEventForm.patchValue({
              recurrence_date : recurrenceDate.setDate(recurrenceDate.getDate()+7*(this.recurrenceCount-1))
            })
          }else if(this.recurrence == 'monthly'){
            this.editEventForm.patchValue({
              recurrence_date : recurrenceDate.setMonth(recurrenceDate.getMonth()+(this.recurrenceCount-1))
            })
          }else if(this.recurrence == 'yearly'){
            this.editEventForm.patchValue({
              recurrence_date : recurrenceDate.setFullYear(recurrenceDate.getFullYear()+(this.recurrenceCount-1))
            })
          }
          console.log(rruleEndTime, recurrenceDate)
          rruleEndTime = this.datePipe.transform(recurrenceDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
        }
        rruleEndDate = this.datePipe.transform(recurrenceDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[0]
        rruleEndTime = this.datePipe.transform(recurrenceDate, 'yyyy-MM-dd HH:mm').replace(/-/g,'').split(' ')[1].replace(/:/g,'')
      }
      
      if (this.type === 'series') {
        const item = {
          title: this.editEventForm.value.title == null ? '' : this.editEventForm.value.title,
          description: this.editEventForm.value.description == null ? '' : this.editEventForm.value.description,
          obs_intervall_start: this.datePipe.transform(this.editEventForm.value.startDate, 'yyyy-MM-dd HH:mm'),
          obs_intervall_end: this.datePipe.transform(this.editEventForm.value.endDate, 'yyyy-MM-dd HH:mm'),
          recurrence: this.recurrence,
          recurrence_count: this.recurrenceCount,
          location: 1,
          art: 1,
          all_day_flag: 1,
          event_at_home_flag: 1,
          user_id: userId,
          household_id: this.householdId,
          is_owner_home: this.isOwnerHome,
          recurrence_date: (this.editEventForm.get('recurrence_date').value && this.editEventForm.get('recurrence_date').value!='Invalid Date') ? this.datePipe.transform(recurrenceDate, 'yyyy-MM-dd HH:mm'): '',
          clicked_date: this.datePipe.transform(this.editEventForm.get('clicked_date').value, 'yyyy-MM-dd HH:mm'),
          recurrence_rule: (`DTSTART:${rruleStartDate}T${rruleStartTime}00Z\nRRULE:FREQ=${this.recurrence=='none'?'daily':this.recurrence};INTERVAL=1;UNTIL=${rruleEndDate}T${rruleStartTime}00Z`)
        };
        this.calendarService.updateCalendarData(this.editEventForm.value.id, item).subscribe(res => { 
          this.sendUpdateData(item);
          console.log('res', res);
        }, err => {
          console.log(err);
        });
      } else {
        const item = {
          title: this.editEventForm.value.title == null ? '' : this.editEventForm.value.title,
          description: this.editEventForm.value.description == null ? '' : this.editEventForm.value.description,
          obs_intervall_start: this.datePipe.transform(this.editEventForm.value.startDate, 'yyyy-MM-dd HH:mm'),
          obs_intervall_end: this.datePipe.transform(this.editEventForm.value.endDate, 'yyyy-MM-dd HH:mm'),
          recurrence: 'none',
          recurrence_count: this.recurrenceCount,
          location: 1,
          art: 1,
          all_day_flag: 1,
          event_at_home_flag: 1,
          user_id: userId,
          household_id: this.householdId,
          is_owner_home: this.isOwnerHome,
          clicked_date: this.datePipe.transform(this.editEventForm.get('clicked_date').value, 'yyyy-MM-dd HH:mm'),
          recurrence_rule: (`DTSTART:${rruleStartDate}T${rruleStartTime}00Z\nRRULE:FREQ=${this.recurrence=='none'?'daily':this.recurrence};INTERVAL=1;UNTIL=${rruleEndDate}T${rruleStartTime}00Z`)
        }
        this.calendarService.updateCalendarData(this.editEventForm.value.id, item).subscribe(res => {
        this.sendUpdateData(item);
          console.log('res', res);
        }, err => {
          console.log(err);
        });
      }


      this.closeModal();
    }
  }

  sendUpdateData(data) {
    this.sharedService.sendUpdatedCalendarData(data);
  }

  deleteAppointment() {
    console.log(this.type)
    if(this.type != 'series'){
      let opt = {
        headers: {},
        params: {
          recurrence: 'none',
          clicked_date: this.datePipe.transform(this.editEventForm.get('clicked_date').value, 'yyyy-MM-dd HH:mm')
        }
      }
      this.http.delete(`${environment.api}calendar-events/${this.data.Id}`, opt).subscribe(res => {
        this.sharedService.sendUpdatedData();
      });
    }else{
      const recurrence = this.recurrence;
      this.http.delete(`${environment.api}calendar-events/${this.data.Id}`, recurrence).subscribe(res => {
      this.sharedService.sendUpdatedData();
    });
    }
    
    this.closeModal();
  }

  closeModal() {
    this.modalRefEditEvent.hide();
  }
  public myFilter = (d: Date): boolean => {
    let endDate = new Date(d);
    const startDate = new Date(this.editEventForm.get('startDate').value)
    const hoursToAdd = startDate.getHours()
    const minutesToAdd = startDate.getMinutes()
    endDate.setHours(hoursToAdd)
    endDate.setMinutes(minutesToAdd)
    return endDate.getTime()>=startDate.getTime()
  }
  validateEndDate(){
    const startDate = new Date(this.editEventForm.get('startDate').value)
    const endDate = new Date(this.editEventForm.get('endDate').value)
    if(startDate > endDate){
      this.editEventForm.patchValue({
        endDate: startDate
      })
    }
  }
  checkSelectedResource(item) {
    this.selectedResource = item;
  }

  
  validateRecurrence(){
    const recurrenceCount = this.editEventForm.get('recurrenceCount').value
    const recurrence_date = this.editEventForm.get('recurrence_date').value
    if(recurrenceCount>0 || (recurrence_date && recurrence_date!='Invalid Date')){
      return true
    }else{
      return false
    }
  }
}
