<div class="create-contact-box">
  <div id="kontaktanlegen" class="container">
    <div id="createevent" class="">
      <div class="">
        <a class="padding-rl-15" (click)="closeModal()" class="modal-action modal-close grey-text"><i
          class="material-icons notranslate">close</i></a>
        <h4>{{'calendar.editAppointment' | translate}}</h4>
        <form [formGroup]="editEventForm">
          <div class="row">
            <div class="input-field col s12">
              <input formControlName="title" id="event_title_create" type="text" placeholder="{{'global.title' | translate}}">
              <span class="red-text" *ngIf="editEventForm.controls['title'].hasError('invalidName')">Special characters not allowed</span>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <input formControlName="description" id="event_description_create" type="text" placeholder="{{'global.description' | translate}}">
              <span class="red-text" *ngIf="editEventForm.controls['description'].hasError('invalidName')">Special characters not allowed</span>
            </div>
          </div>
          <div class="row">
            <div class="input-field col m4 s12">
              <!--              <label>Datum von:</label>-->
              <!--              <input type="text" name="date_from_create" value="" placeholder="Datum von">-->
              <input readonly formControlName="startDate" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" (ngModelChange)="validateEndDate()"
                     placeholder="{{'global.dateFrom' | translate}}">
              <owl-date-time #dt1></owl-date-time>
            </div>
            <div class="input-field col m4 s12">
              <!--              <label>Datum bis:</label>-->
              <input readonly formControlName="endDate" [owlDateTime]="dt2" [min]="editEventForm.get('startDate').value"
                     [owlDateTimeTrigger]="dt2" [owlDateTimeFilter]="myFilter" placeholder="{{'global.dateTo' | translate}}">
              <owl-date-time #dt2></owl-date-time>
              <!--              <input type="text" name="date_till_create" value="" placeholder="Datum bis">-->
            </div>
            <div class="input-field col m4 s12">
              <div class="" dropdown>
                <div class="">
                  <!--                  <label>Anrede:</label>-->
                  <input readonly id="button-disabled-item-1" dropdownToggle formControlName="is_owner_home"
                         aria-controls="dropdown-disabled-item-1" [value]="selectedView"
                         class="form-control dropdown-toggle">
                </div>
                <ul id="dropdown-disabled-item-1" *dropdownMenu
                    class="dropdown-menu custom-option"
                    role="menu" aria-labelledby="button-disabled-item-1">
                  <li [value]="item.id" *ngFor="let item of viewAs" role="menuitem"
                      [ngClass]="item.state ? 'disabled' : 'dropdown-item'" (click)="selectView(item.id)">
                    <a class="dropdown-item">{{item.name}}</a></li>
                </ul>
              </div>
            </div>
            <div class="row" *ngIf="type == 'series'">
              <div class="input-field col m4 s12 select-recurring-date-type">
                <div class="" dropdown>
                  <div class="">
                    <input readonly id="button-disabled-item-3" dropdownToggle type="button" [value]="selectedSeries"
                           aria-controls="dropdown-disabled-item-3" type="text" class="form-control dropdown-toggle">
                  </div>
                  <ul id="dropdown-disabled-item-3" *dropdownMenu
                      class="dropdown-menu custom-option"
                      role="menu" aria-labelledby="button-disabled-item-3">
                    <li [value]="item.id" *ngFor="let item of series" role="menuitem"
                        [ngClass]="item.disabled ? 'disabled' : 'dropdown-item'"
                        (click)="selectSeries(item.id)">
                      <a class="dropdown-item">{{item.name}}</a></li>
                  </ul>
                </div>
              </div>
              
              <div class="col-md-3">
                <div class="row">
                  <label class="radio" *ngFor="let item of resourcesList">
                    <span class="radio__input">
                      <input type="radio" name="radio" (change)="checkSelectedResource(item.id)" [checked]="item.checked">
                      <span class="radio__control"></span>
                    </span>
                    <span class="radio__label">{{item.name}}</span>
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-field" *ngIf="selectedResource == 0">
                  <input formControlName="recurrence_date" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" placeholder="{{'global.dateTo' | translate}}">
                  <owl-date-time #dt3></owl-date-time>
                </div>
                <div class="input-field" *ngIf="selectedResource == 1">
                  <input class="recCountInput" formControlName="recurrenceCount" placeholder="Ocurrencie(s)"
                          type="number" min="1" max="50"/>
                          <span class="ml-2">Terminen</span>
                </div>
              </div>
              
            </div>
          </div>
          
          
          <div class="input-field col s12 d-flex justify-content-between">
            <button [disabled]="!validateRecurrence()"
              class="btn btn-large waves-effect waves-light ml-1"
              type="submit"
              name="action"
              (click)="saveEventData()">
              {{'calendar.updateEvent' | translate}}
            </button>
            <button *ngIf="type!='series'"
              class="btn btn-large waves-effect waves-light ml-1"
              type="submit"
              name="action"
              (click)="deleteAppointment()">
              {{'calendar.deleteAppointment' | translate}}
            </button>
            <button *ngIf="type=='series'"
              class="btn btn-large waves-effect waves-light ml-1"
              type="submit"
              name="action"
              (click)="deleteAppointment()">
              {{'calendar.deleteSeries' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
