<app-sidebar *ngIf="blankUrl || !isCheckoutRoute()"></app-sidebar>
<router-outlet></router-outlet>
<app-footer *ngIf="blankUrl || !isCheckoutRoute()"></app-footer>

<!--    <ng-sidebar-container>-->
<!--        <ng-sidebar  [(opened)]="_opened"-->
<!--        [showBackdrop]="_showBackdrop">-->
<!--            <ul class="sidex" id="slide-out"-->
<!--                data-menu="menu-navigation" data-collapsible="menu-accordion">-->
<!--                <app-tabs></app-tabs>-->
<!--            </ul>-->
<!--        </ng-sidebar>-->
<!--        <div ng-sidebar-content>-->
<!--            <app-sidebar  *ngIf="blankUrl || !isCheckoutRoute()"></app-sidebar>-->
<!--            <router-outlet></router-outlet>-->
<!--        </div>-->
<!--    </ng-sidebar-container>-->
