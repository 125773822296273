import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rechnungen',
  templateUrl: './rechnungen.component.html',
  styleUrls: ['./rechnungen.component.css']
})
export class RechnungenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
