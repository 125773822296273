import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Services} from '../../../services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {EditContactComponent} from '../edit-contact/edit-contact.component';
import {CreateContactComponent} from '../create-contact/create-contact.component';
import {Subscription} from 'rxjs';
import {SharedService} from '../../../services/shared.service';
import {environment} from '../../../../environments/environment';
import {RemoveUserPopupComponent} from '../remove-user-popup/remove-user-popup.component';
import {AlarmStateService} from '../../../services/alarm-state.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-kontakte-verwalten',
  templateUrl: './kontakte-verwalten.component.html',
  styleUrls: ['./kontakte-verwalten.component.css']
})
export class KontakteVerwaltenComponent implements OnInit, OnDestroy {
  UpdatedDataSubscription: Subscription;
  users: any = [];
  formGroup;
  householdId;
  url;
  eventUsers = [];
  alarmUsers = [];
  alarmStateData;
  subscriptions: Subscription;
  imagesApi = 'https://be-test.xvalue-services.de//storage/images/'

  constructor(private services: Services,
              private modalService: BsModalService,
              public bsModalRef: BsModalRef,
              private sharedService: SharedService,
              public alarmStateService: AlarmStateService,
              private zone: NgZone,
              private toasterService: ToastrService,
              private translateService: TranslateService,
  ) {
    this.subscriptions = new Subscription
  }

  ngOnInit(): void {

    this.householdId = localStorage.getItem('household_id');
    this.alarmStateService.checkAlarmState(this.householdId);
    const sharedAlarmData = this.alarmStateService.alarmState.subscribe(response => {
      this.zone.run(() => {
        this.alarmStateData = response;
        console.log('alarm state data', response, this.alarmStateData);
      });
    });
    this.subscriptions.add(sharedAlarmData)

    this.url = environment.imagesApi;
    const updatedHousehold = this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.householdId = localStorage.getItem('household_id');
      this.services.getAllUsers(this.householdId).subscribe((data: any) => {
        this.url = environment.imagesApi;

        this.users = data.users;
        console.log('users', data);
      });
    });
    this.subscriptions.add(updatedHousehold)
    this.householdId = localStorage.getItem('household_id');
    this.UpdatedDataSubscription = this.sharedService.getUpdatedData().subscribe(() => {
      this.services.getAllUsers(this.householdId).subscribe((data: any) => {
        this.users = data.users;
        console.log('users', data);
      });
    });
    this.subscriptions.add(this.UpdatedDataSubscription)

    const getAllUsers = this.services.getAllUsers(this.householdId).subscribe((data: any) => {
      this.users = data.users;
      console.log('users', data);
    });
    this.subscriptions.add(getAllUsers)
    console.log('all users')
    this.addHandler();
  }

  public addHandler() {
    this.formGroup = new FormGroup({
      gender: new FormControl('', [
        // Validators.required
      ]),
      name: new FormControl('', [
        Validators.required
      ]),
      surname: new FormControl('', [
        Validators.required
      ]),
      mobile_number: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      household_id: new FormControl(this.householdId, [
        Validators.required
      ])
    });

  }

  editHandler(dataItem) {
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id, [
        Validators.required
      ]),
      gender: new FormControl(dataItem.gender, [
        // Validators.required
      ]),
      name: new FormControl(dataItem.name, [
        Validators.required
      ]),
      surname: new FormControl(dataItem.surname, [
        Validators.required
      ]),
      mobile_number: new FormControl(dataItem.mobile_number, [
        Validators.required
      ]),
      email: new FormControl(dataItem.email, [
        Validators.required,
        Validators.email
      ]),
      household_id: new FormControl(this.householdId, [
        Validators.required
      ])
    });
  }

  public deleteUser(user) {
    const initialState = {
      user
    };
    this.modalService.show(RemoveUserPopupComponent, {initialState});
  }

  isFieldValid(field: string) {
    return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
  }

  createContactModal() {
    this.modalService.show(CreateContactComponent,{backdrop:'static', keyboard:false});
  }

  editContactModal(user) {
    const initialState = {
      userData: user
    };
    this.bsModalRef = this.modalService.show(EditContactComponent, {initialState});
  }
  nAToaster(){
    this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'));
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    this.toasterService.clear()
  }
}
