<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>Dokumente</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a>
              </li>
              <li class="breadcrumb-item active">Dokumente
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">

        <div class="row mb-1 mt-1">
          <div class="col s12 l6">
            <div class="card mb-4">
              <div class="card-content">
                <h4 class="card-title mb-0">Handbuch</h4>
                <table>
                  <thead>
                  <tr>
                    <th>Gerät</th>
                    <th class="right-align">Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>HANNAH Alarm und Event</td>
                    <td class="right-align"><a href="#"><i class="material-icons notranslate grey-text">file_download</i></a> <a href="#"><i class="material-icons notranslate grey-text">ondemand_video</i></a></td>
                  </tr>
                  <tr>
                    <td>Handsender</td>
                    <td class="right-align"><a href="#"><i class="material-icons notranslate grey-text">file_download</i></a></td>
                  </tr>
                  <tr>
                    <td>App</td>
                    <td class="right-align"><a href="#"><i class="material-icons notranslate grey-text">file_download</i></a> <a href="#"><i class="material-icons notranslate grey-text">ondemand_video</i></a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col s12 l6">
            <div class="card mb-4">
              <div class="card-content">
                <h4 class="card-title mb-0">Anträge</h4>
                <table>
                  <thead>
                  <tr>
                    <th>Gerät</th>
                    <th class="right-align">Aktionen</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Pflegeversicherung</td>
                    <td class="right-align"><a href="#"><i class="material-icons notranslate grey-text">file_download</i></a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col s12 l6">

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->
