<div
  class="horizontal-layout page-header-light horizontal-menu preload-transitions 1-column login-bg   blank-page blank-page">
  <div class="row">
    <div class="col s12">
      <div class="container">
        <div id="login-page" class="row">
          <div class="col s12 m8 l8 z-depth-4 card-panel login-card bg-opacity-8">
            <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
              <div class="row">
                <div class="input-field mt-4 col s6">
                  <h5 class="ml-4">{{'authentication.login' | translate}}</h5>
                </div>
                <div class="input-field mt-4 col s6 right-align">
                  <img src="../../../../assets/images/logo/hannah-logo.svg" class="login-logo" alt="HANNAH"/>
                </div>
              </div>
              <h6 *ngIf="loggedOutDueToInactivity" class="ml-3 inActivityText">{{'global.loggedOutDueToInactivity' | translate}}</h6>
              <!--            <div class="row margin">-->
              <!--              <div class="input-field col s12">-->
              <!--                <i class="material-icons prefix pt-5">person_outline</i>-->
              <!--                <mat-form-field appearance="" style="width: 95%; float:right;">-->
              <!--                  <mat-label>E-Mail-Adresse</mat-label>-->
              <!--                  <input style="border: none !important;" id="email" type="email" formControlName="email" required matInput/>-->
              <!--                </mat-form-field>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="row margin">
                <div class="input-field col s12">
                  <i class="material-icons prefix pt-2 notranslate">{{'person_outline'}}</i>
                  <input (change)="resetErrors()" placeholder="{{'global.email' | translate}}" id="email" type="email"
                         formControlName="email" required>
                  <!--                            <label for="email" class="center-align">E-Mail-Adresse</label>-->
                </div>
              </div>
              <!--            <div class="row margin">-->
              <!--              <div class="input-field col s12">-->
              <!--                <i class="material-icons prefix pt-2">lock_outline</i>-->
              <!--                <mat-form-field appearance="" style="width: 95%; float:right;">-->
              <!--                  <mat-label>Passwort</mat-label>-->
              <!--                  <input style="border: none !important;" id="password" type="password" formControlName="password" required matInput/>-->
              <!--                </mat-form-field>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="row margin">
                <div class="input-field col s12">
                  <i class="material-icons prefix pt-2 notranslate">lock_outline</i>
                  <input (change)="resetErrors()" placeholder="{{'global.password' | translate}}" id="password"
                         type="password" formControlName="password" required>
                  <!--                <label for="password">Passwort</label>-->
                </div>
              </div>
              <div class="input-field col s12">
                <p *ngIf="showError" class="materialize-red-text">{{errorMessage}}.</p>
              </div>
              <!--            <div class="row">-->
              <!--              <div class="col s12 m12 l12 ml-2 mt-1">-->
              <!--                <p>-->
              <!--                  <label>-->
              <!--                    <input type="checkbox"/>-->
              <!--                    <span>Angemeldet bleiben</span>-->
              <!--                  </label>-->
              <!--                </p>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="row">
                <div class="input-field col s12">
                  <button
                    class="btn waves-effect waves-light border-round red col s12"
                    type="submit"
                    [disabled]="!loginForm.valid">
                    {{'authentication.loginNow' | translate}}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s6 m6 l6">
                  <!--                <p class="margin medium-small"><a routerLink="/">Jetzt Kunde werden</a></p>-->
                </div>
                <div class="input-field col s6 m6 l6">
                  <p class="margin right-align medium-small"><a
                    routerLink="../forgot-password">{{'authentication.forgotPassword' | translate}}</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="content-overlay"></div>
    </div>
  </div>
