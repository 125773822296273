<div class="create-contact-box">
  <div id="kontaktanlegen" class="container">
    <div class="">
      <div class="">
        <a class="padding-rl-15" (click)="closeModal()" class="modal-action modal-close grey-text"><i
          class="material-icons notranslate">close</i></a>
        <h4 class="padding-rl-15">{{'manageContacts.createContact.description' | translate}}</h4>
        <p class="padding-rl-15 mb-2 text-contact-normal"
           *ngIf="selected == 1">{{'manageContacts.createContact.normalContactDesc' | translate}}</p>
        <p class="padding-rl-15 mb-2 text-contact-emergency"
           *ngIf="selected == 2">{{'manageContacts.createContact.emergencyContactDesc' | translate}}</p>
        <p class="padding-rl-15 mb-2" *ngIf="selected == 3">{{'manageContacts.createContact.adminDesc' | translate}}</p>
      </div>
      <form [formGroup]="formGroup">
        <!--        <div class="col-md-12">-->
        <!--          <div class="row">-->
        <!--            <div class="col-md-6">-->
        <!--              <mat-form-field>-->
        <!--                <select class="custom-select" id="inputGroupSelect01" matNativeControl required>-->
        <!--                  <option value="normalkontakt">Normalkontakt</option>-->
        <!--                  <option value="notfallkontakt">Notfallkontakt</option>-->
        <!--                  <option value="administrator">Administrator</option>-->
        <!--                </select>-->
        <!--              </mat-form-field>-->
        <!--            </div>-->
        <!--            <div class="col-md-6">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="row">-->
<!--        <label>{{'global.contactType' | translate}}:</label>-->
        <div class="input-field col-md-6 m6 s12 select-contact-type">
          <div class="" dropdown>
            <div class="">
              <input readonly id="button-disabled-item" dropdownToggle [value]="selectedOption"
                     aria-controls="dropdown-disabled-item" class="form-control dropdown-toggle">
            </div>
            <ul id="dropdown-disabled-item" *dropdownMenu
                class="dropdown-menu custom-option"
                role="menu" aria-labelledby="button-disabled-item">
              <li [value]="item.id" *ngFor="let item of contactTypes" role="menuitem"
                  [ngClass]="item.disabled ? 'disabled' : 'dropdown-item'" (click)="selectOption(item.id)">
                <a class="dropdown-item">{{item.name}}</a></li>
            </ul>
          </div>
        </div>
        <div class="input-field col-md-6 m6 s12" *ngIf="selected !== 1">
          <div class="" dropdown>
            <div class="">
<!--              <label>{{'global.gender' | translate}}:</label>-->
              <input readonly id="button-disabled-item-1" dropdownToggle
                     aria-controls="dropdown-disabled-item-1" [value]="selectedGender"
                     class="form-control dropdown-toggle">
            </div>
            <ul id="dropdown-disabled-item-1" *dropdownMenu
                class="dropdown-menu custom-option"
                role="menu" aria-labelledby="button-disabled-item-1">
              <li [value]="item.id" *ngFor="let item of genderArray" role="menuitem"
                  [ngClass]="item.state ? 'disabled' : 'dropdown-item'" (click)="selectGender(item.id)">
                <a class="dropdown-item">{{item.name}}</a></li>
            </ul>
          </div>
        </div>
        <!--        </div>-->
        <!--        <div class="row">-->
        <div class="input-field col-md-6 m6 s12">
          <input placeholder="Vorname" id="first_name_save" type="text" formControlName="name">
          <!--            <label for="first_name_save">Vorname</label>-->
          <p *ngIf="isFieldValid('name')" class="materialize-red-text">{{'validation.name' | translate}}</p>
        </div>
        <div class="input-field col-md-6 m6 s12">
          <input placeholder="Nachname" id="last_name_save" type="text" formControlName="surname">
          <!--            <label for="last_name_save">Nachname</label>-->
          <p *ngIf="isFieldValid('surname')" class="materialize-red-text">{{'validation.surname' | translate}}</p>
        </div>
        <!--        </div>-->
        <!--        <div class="row">-->
        <div class="input-field col-md-6 m6 s12 phone" *ngIf="selected !== 1">
          <input placeholder="Handynummer" id="phone" type="text" formControlName="mobile_number">
          <!--            <label for="phone">Handynummer</label>-->
          <p *ngIf="isFieldValid('mobile_number')"
             class="materialize-red-text">{{'validation.mobileNumber' | translate}}</p>
        </div>
        <div class="input-field col-md-6 m6 s12 email_save">
          <input placeholder="E-Mail-Adresse" id="email" type="email" formControlName="email"
                 (ngModelChange)="showResponseMessage = false">
          <!--            <label for="email">E-Mail-Adresse</label>-->
          <p *ngIf="isFieldValid('email')" class="materialize-red-text">{{'validation.emailAddress' | translate}}</p>
          <p *ngIf="showResponseMessage" class="materialize-red-text">{{responseMessage}}</p>
        </div>
        <!--        </div>-->
        <div class="row">
          <div class="input-field col s12 ">
            <button
              class="btn btn-large waves-effect waves-light button-invite"
              type="submit"
              name="action"
              [disabled]="!formGroup.valid"
              (click)="addUser()">{{'manageContacts.createContact.buttonTitle' | translate}}
              <i class="material-icons notranslate right">send</i>
            </button>
            <!--            <button *ngIf="selected == 1"-->
            <!--                    class="btn btn-large waves-effect waves-light button-save"-->
            <!--                    type="submit"-->
            <!--                    name="action"-->
            <!--                    [disabled]="!formGroup.valid"-->
            <!--                    (click)="addUser()">-->
            <!--              Speichern und zurück-->
            <!--            </button>-->
            <div class="col-md-12">
              <div class="row">
                <span class="" *ngIf="responseError"
                      class="materialize-red-text">{{'validation.somethingWrong' | translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


