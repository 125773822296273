<div class="edit-contact-box">
  <div class="edit-content">
    <div class="col-md-12">
      <a class=" modal-close grey-text" (click)="closeModal()"><i class="notranslate material-icons">close</i></a>
      <h4>{{'manageContacts.editContact.title' | translate}}</h4>
    </div>
<!--    <form [formGroup]="formGroup" enctype="multipart/form-data" *ngIf="showUserFields">-->
<!--      <div class="row">-->
<!--        <div class="col l6 m6 s12">-->
<!--          <div class="input-field pb-2">-->
<!--            <div class="" dropdown>-->
<!--              <div class="">-->
<!--                <label>{{'global.contactType' | translate}}:</label>-->
<!--                <input id="button-disabled-item" dropdownToggle type="button" [value]="selectedOption"-->
<!--                       aria-controls="dropdown-disabled-item" type="text" class="form-control dropdown-toggle">-->
<!--              </div>-->
<!--              <ul id="dropdown-disabled-item" *dropdownMenu-->
<!--                  class="dropdown-menu custom-option"-->
<!--                  role="menu" aria-labelledby="button-disabled-item">-->
<!--                <li [value]="item.id" *ngFor="let item of contactTypes" role="menuitem"-->
<!--                    [ngClass]="item.disabled ? 'disabled' : 'dropdown-item'" (click)="selectOption(item.id)">-->
<!--                  <a class="dropdown-item">{{item.name}}</a></li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="input-field pb-2">-->
<!--            <div class="" dropdown>-->
<!--              <div class="">-->
<!--                <label>{{'global.gender' | translate}}:</label>-->
<!--                <input id="button-disabled-item-1" dropdownToggle type="button" formControlName="gender"-->
<!--                       aria-controls="dropdown-disabled-item-1" type="text" [value]="selectedGender"-->
<!--                       class="form-control dropdown-toggle">-->
<!--              </div>-->
<!--              <ul id="dropdown-disabled-item-1" *dropdownMenu-->
<!--                  class="dropdown-menu custom-option"-->
<!--                  role="menu" aria-labelledby="button-disabled-item-1">-->
<!--                <li [value]="item.id" *ngFor="let item of genderArray" role="menuitem"-->
<!--                    [ngClass]="item.state ? 'disabled' : 'dropdown-item'" (click)="selectGender(item.id)">-->
<!--                  <a class="dropdown-item">{{item.name}}</a></li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--          <p *ngIf="isFieldValid('gender')" class="materialize-red-text">{{'validation.gender' | translate}}</p>-->
<!--          <div class="input-field">-->
<!--            <input id="first_name_edit" type="text" formControlName="name">-->
<!--            <label for="first_name_edit" class="active">{{'global.firstName' | translate}}</label>-->
<!--            <p *ngIf="isFieldValid('name')" class="materialize-red-text">{{'validation.name' | translate}}</p>-->
<!--          </div>-->
<!--          <div class="input-field">-->
<!--            <input id="last_name_edit" type="text" formControlName="surname">-->
<!--            <label for="last_name_edit" class="active">{{'global.lastName' | translate}}</label>-->
<!--            <p *ngIf="isFieldValid('surname')" class="materialize-red-text">{{'validation.surname' | translate}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col offset-l2 l4 m6 s12">-->
<!--          <div class="product" on-mouseover="removeAvatar()" on-mouseout="addAvatar()">-->
<!--            <div class="edit-avatar" *ngIf="avatarExists">-->
<!--              <img (error)="avatarExists = false" (click)="fileInput.click()" [src]="imageSrc || url + avatarImg" class="edit-avatar-img"/>-->
<!--            </div>-->
<!--            <div class="upload-image-content">-->

<!--              <div class="row text-center">-->
<!--                <i class="notranslate material-icons text-center upload-cloud">-->
<!--                  cloud_upload-->
<!--                </i>-->
<!--                <h6 class="upload-text">{{'manageContacts.editContact.profilePictureDesc' | translate}}</h6>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="product-hover" (click)="fileInput.click()">-->
<!--              <input-->
<!--                style="display: none" (change)="handleFileInput($event.target.files)"-->
<!--                type="file"-->
<!--                #fileInput>-->
<!--              <div class="upload-image-box">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;          <button (click)="uploadFileToActivity()">insert</button>&ndash;&gt;-->

<!--          &lt;!&ndash;                      <input type="file" id="change-avatar" data-show-remove="false" data-height="250" class="dropify"&ndash;&gt;-->
<!--          &lt;!&ndash;                             data-default-file=""/>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="input-field col s12 m8">-->
<!--          <input id="street_edit" type="text" value="" formControlName="streetname">-->
<!--          <label for="street_edit" class="active">{{'global.street' | translate}}</label>-->
<!--        </div>-->
<!--        <div class="input-field col s12 m4">-->
<!--          <input id="number_edit" type="text" value="" formControlName="housingnumber">-->
<!--          <label for="number_edit" class="active">{{'global.houseNumber' | translate}}</label>-->
<!--        </div>-->
<!--      </div><div class="row">-->
<!--        <div class="input-field col s12 m8">-->
<!--          <input id="townname_edit" type="text" value="" formControlName="townname">-->
<!--          <label for="townname_edit" class="active">{{'global.townName' | translate}}</label>-->
<!--        </div>-->
<!--        <div class="input-field col s12 m4">-->
<!--          <input id="zipcode_edit" type="text" value="" formControlName="zipcode">-->
<!--          <label for="zipcode_edit" class="active">{{'global.zipCode' | translate}}</label>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col m6 s12">-->
<!--          <div class="input-field">-->
<!--            <input id="phone_edit" type="text" formControlName="mobile_number">-->
<!--            <label for="phone_edit" class="active">{{'global.mobileNumber' | translate}}</label>-->
<!--            <p *ngIf="isFieldValid('mobile_number')" class="materialize-red-text">{{'validation.mobileNumber' | translate}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col m6 s12">-->
<!--          <div class="input-field">-->
<!--            <input id="email_edit" type="email" formControlName="email">-->
<!--            <label for="email_edit" class="active">{{'global.email' | translate}}</label>-->
<!--            <p *ngIf="isFieldValid('email')" class="materialize-red-text">{{'validation.emailAddress' | translate}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="input-field col s12">-->
<!--          <button-->
<!--            class="btn btn-large waves-effect waves-light ml-1"-->
<!--            type="submit"-->
<!--            name="action"-->
<!--            [disabled]="!formGroup.valid"-->
<!--            (click)="saveHandler()">-->
<!--            {{'global.save' | translate}}-->
<!--            <i class="notranslate material-icons right">send</i>-->
<!--          </button>-->
<!--          <div class="col-md-12">-->
<!--            <div class="row">-->
<!--              <span class="" *ngIf="responseError" class="materialize-red-text">{{'validation.somethingWrong' | translate}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </form>-->
    <form [formGroup]="formGroup" enctype="multipart/form-data">
      <div class="row">
        <div class="col l6 m6 s12">
          <div class="input-field pb-2">
            <div class="" dropdown>
              <div class="">
                <label>{{'global.contactType' | translate}}:</label>
                <input readonly id="button1-disabled-item" dropdownToggle  [value]="selectedOption"
                       aria-controls="dropdown1-disabled-item" class="form-control dropdown-toggle">
              </div>
              <ul id="dropdown1-disabled-item" *dropdownMenu
                  class="dropdown-menu custom-option"
                  role="menu" aria-labelledby="button1-disabled-item">
                <li [value]="item.id" *ngFor="let item of contactTypes" role="menuitem"
                    [ngClass]="item.disabled ? 'disabled' : 'dropdown-item'" (click)="selectOption(item.id)">
                  <a class="dropdown-item">{{item.name}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <button
            class="btn btn-large waves-effect waves-light ml-1"
            type="submit"
            name="action"
            [disabled]="!formGroup.valid"
            (click)="saveHandler()">
            {{'global.save' | translate}}
            <i class="notranslate material-icons right">send</i>
          </button>
          <div class="col-md-12">
            <div class="row">
              <span class="" *ngIf="responseError" class="materialize-red-text">{{'validation.somethingWrong' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
