import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {Services} from '../../../services';
import {SharedService} from '../../../services/shared.service';
import {AuthService} from '../../../auth/services/auth.service';

@Component({
  selector: 'app-remove-user-popup',
  templateUrl: './remove-user-popup.component.html',
  styleUrls: ['./remove-user-popup.component.scss']
})
export class RemoveUserPopupComponent implements OnInit {
  householdId;
  user;
  buttonTriggered = false;

  constructor(private refModal: BsModalRef,
              private http: HttpClient,
              private services: Services,
              private sharedService: SharedService, private authService: AuthService) {
  }

  ngOnInit(): void {
    console.log('user', this.user);
    this.householdId = localStorage.getItem('household_id')
  }

  closeModal() {
    this.refModal.hide();
  }

  removeAccount() {
    this.buttonTriggered = true;
    const jwtToken = this.authService.getDecodedAccessToken(localStorage.getItem('JWT_TOKEN'));
    this.services.deleteUser({id: this.user.id, household_id: this.householdId})
      .subscribe(data => {
          console.log('deleted user: ', data, 'user: ', this.user);
          this.buttonTriggered = false;
          this.sharedService.sendUpdatedData();
          this.closeModal();
          if (this.user.email == jwtToken.sub) {
            this.authService.logout();
          }
        },
        error => {
          console.log('error', error);
          this.buttonTriggered = false;
        });
  }
}
