import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { environment } from 'src/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-wifi-config',
  templateUrl: './wifi-config.component.html',
  styleUrls: ['./wifi-config.component.scss']
})
export class WifiConfigComponent implements OnInit {
  loadingAvailableWifis: boolean = true;
  passwordVisibility: boolean = false;
  showInputPassword: boolean = false;
  validInput: boolean = false;

  loadingConnect: boolean = false;
  currentChosenWifi;
  currentChosenIndex;
  currentChosenStatus;

  connectedTo = 'Trudo-Staff';
  userId;
  @ViewChild('divToScroll') divToScroll: ElementRef;

  constructor(private router: Router,
    private socketService: WebSocketService,
    private authService: AuthService,
    private http: HttpClient) { }

  availableWifis = [];
  householdID;

  ngOnInit(): void {
    this.householdID = localStorage.getItem('household_id');

    this.getAvailableWifis();
  }

  inputPassword(chosenWifi){
    this.validInput = false;

    if(this.currentChosenWifi && !this.currentChosenStatus){
      document.getElementById(`${this.currentChosenWifi}-loader`).classList.add('hidden');
      document.getElementById(`${this.currentChosenWifi}-visibility`).classList.remove('hidden');
    }

    this.passwordVisibility = false;
    this.currentChosenWifi = chosenWifi.name;
    this.currentChosenIndex = chosenWifi.id;
    this.currentChosenStatus = chosenWifi.connected;

    let input = this.getCurrentInput();
    if(input && input.type && input.value){
      input.type = 'password';
      input.value = '';
    }
  }

  connectToWifi(){
    document.getElementById(`${this.currentChosenWifi}-visibility`).classList.add('hidden');
    document.getElementById(`${this.currentChosenWifi}-loader`).classList.remove('hidden');

    let passwordInput = document.getElementById(`${this.currentChosenWifi}-input`) as HTMLInputElement;
    this.loadingConnect = true;

    let emitData = {
      id: this.userId,
      target: this.householdID,
      reason: 'connect-to-wifi',
      ssid: this.currentChosenWifi,
      password: passwordInput.value,
      room: this.householdID + 'wifiConfig',
    }
    this.socketService.emit('wifiConfig', emitData);
  }

  toggleVisibility(){
    this.passwordVisibility = !this.passwordVisibility;
    let input = this.getCurrentInput();
    input.type == 'password' ? input.type = 'text' : input.type = 'password';
  }

  getCurrentInput(){
    return document.getElementById(`${this.currentChosenWifi}-input`) as HTMLInputElement;
  }

  inputChanged(event){
    let newValue = event.target.value;
    if(newValue != '' && newValue != undefined && newValue != null){
      this.validInput = true;
    }else{
      this.validInput = false;
    }
  }

  goToContacts(){
    this.router.navigateByUrl('/kontakte-verwalten');
  }

  getAvailableWifis(){
    this.http.get(`${environment.api}get-login-info`).subscribe((response: any) => {

      this.userId = response.user.id;

      let emitData = {
        id: response.user.id,
        target: this.householdID,
        reason: 'request-available-wifis',
        room: this.householdID + 'wifiConfig',
      }

      this.socketService.emit('wifiConfig', emitData);

      console.log('listening on ', response.user.id);
      this.socketService.listen(response.user.id).subscribe((data:any) => {
        console.log('AVAILABLE WIFIS COMING FROM SOCKET', data);
        this.availableWifis = data.availableWifis;

        this.modifyAvailableWifis();
        this.listenForConnectionStatus(response.user.id);
      });
    });
  }

  listenForConnectionStatus(user){
    this.socketService.listen(user + '-connection-status').subscribe((data:any) => {
      console.log(data, 'from connection status')
      document.getElementById(`${this.currentChosenWifi}-loader`).classList.add('hidden');

      if(data.status == 'successful'){
        document.getElementById(`${this.currentChosenWifi}-connected`).classList.remove('hidden');
        document.getElementById(`${this.currentChosenWifi}-input`).style.color = '#00c853';

        this.availableWifis.forEach((wifi,index) => {
          this.availableWifis[index].connected = false;
        });

        //shift the most recent connection to the top and change UI
        this.availableWifis[this.currentChosenIndex].connected = true;
        this.availableWifis.unshift(this.availableWifis.splice(this.currentChosenIndex, 1)[0]);
        this.divToScroll.nativeElement.scrollTop = 0;
      }else if(data.status == 'unsuccessful'){
        document.getElementById(`${this.currentChosenWifi}-connectionError`).classList.remove('hidden');
        document.getElementById(`${this.currentChosenWifi}-input`).style.color = '#dd3333';
      }

      this.validInput = false;
      this.loadingConnect = false;
    });
  }

  modifyAvailableWifis(){
    let temporaryWifiList = this.availableWifis;

    this.availableWifis = [];
    let connectedWifi;
    let counter = 0;

    temporaryWifiList.forEach((wifi,index) => {
      if(wifi.startsWith("*")){
        connectedWifi = wifi.split("       ")[1];
        this.availableWifis.push({
          'name': connectedWifi,
          'id': counter,
          'connected': true
        });

        counter++;
      }else{
        if(wifi !== connectedWifi){
          this.availableWifis.push({
            'name': wifi,
            'id': counter,
            'connected': false
          });
          counter++;
        }
      }
    });

    this.loadingAvailableWifis = false;
  }

}
