import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  token: any;
  resetPasswordForm: FormGroup;
  password_validation_messages = [
    { 
      type: 'required',
      message: 'Password is required'
    },
    {
      type: 'minlength', 
      message: 'Password must be at least 8 characters long' 
    },
    {
      type: 'maxlength', 
      message: 'Password must be at most 25 characters long' 
    },
    {
      type: 'pattern', 
      message: 'Your password must contain at least one uppercase, one lowercase, one number, and one special character' 
    }
  ]

  constructor(private http: HttpClient,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private toastrService: ToastrService,
              private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.findData();
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.required,
        this.noWhitespaceValidator,
        Validators.pattern(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.\\[@$!\\]&;§%<>:*=\\\\,+_\\-"'#|~?&(){}\\/])[a-zA-Z0-9.@$!%*"'#\\[,§(+)\\]<=>;\\-_&{:\\\\~}|?&\\/]+$`) // includes UpperCase, LoweCase, Number and Special Character
      ])],
      passwordConfirm: ['', [Validators.required, this.noWhitespaceValidator]],
    });
  }

  findData() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  showSuccess(message, title) {
    this.toastrService.success(message, title)
  }

  showError(message, title) {
    this.toastrService.error(message, title)
  }

  showInfo(message, title) {
    this.toastrService.info(message, title)
  }

  showWarning(message, title) {
    this.toastrService.warning(message, title)
  }

  resetPassword() {
    console.log('resetting password', this.resetPasswordForm.value.password.trim(), this.resetPasswordForm.value.passwordConfirm.trim());
    if (this.resetPasswordForm.value.password === this.resetPasswordForm.value.passwordConfirm) {
      this.http.post(`${environment.api}reset-password`, {
        key: this.token,
        password: this.resetPasswordForm.value.password
      }).subscribe((response: any) => {
        console.log('response', response);
        if (response.status !== 200) {
          this.showError(response.message, 'Error')
        } else {
          this.showSuccess(this.translate.instant('toasterMessages.resetPasswordSuccess'), this.translate.instant('toasterMessages.success'))
          this.router.navigateByUrl('login');
        }
      }, error => {
        if (error.status == '404') {
          this.showError(this.translate.instant('toasterMessages.resetPasswordError'), this.translate.instant('toasterMessages.error'))
          console.log('error', error);
        }

      })
    } else {
      this.showInfo(this.translate.instant('toasterMessages.resetPasswordWarning'), this.translate.instant('toasterMessages.warning'))
    }
  }

  resetConfirm(){
    console.log('luejta')
    this.resetPasswordForm.patchValue({
      passwordConfirm: ''
    })
  }

  isPassConfirmFieldValid(field: string) {
    
    const pass = this.resetPasswordForm.get('password').value;
    const confirmPass = this.resetPasswordForm.get(field).value;
    const match:boolean = pass === confirmPass
    return (!this.resetPasswordForm.get(field).valid && this.resetPasswordForm.get(field).touched) || (!match && this.resetPasswordForm.get(field).touched);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {whitespace: true};
  }
}
