import {Component, ElementRef, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CdkDragDrop, CdkDragEnd, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Services} from '../../services';
import {first} from 'rxjs/operators';
import {HouseholdService} from '../../services/household.service';
import {Options} from '@angular-slider/ngx-slider';
import {SharedService} from '../../services/shared.service';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../services/api.service';
import {DatePipe} from '@angular/common';
import {HotlineService} from '../../services/hotline.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AlarmStateService} from '../../services/alarm-state.service';
import { Subscription } from 'rxjs';
import {ConfirmChangesComponent } from '../confirm-changes/confirm-changes.component'
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ereigniskette-bearbeiten',
  templateUrl: './ereigniskette-bearbeiten.component.html',
  styleUrls: ['./ereigniskette-bearbeiten.component.scss']
})
export class EreignisketteBearbeitenComponent implements OnInit, OnDestroy {
  alarmUsers: any;
  isChecked = false;
  householdId;
  usersWithPosition1: any = [];
  usersWithoutPosition1: any = [];
  usersWithPosition: any = [];
  usersWithoutPosition: any = [];
  usersPosition1: any = [];
  usersPosition: any = [];
  alarmUsersPosition: any;
  secondArray: any = [];
  firstArray: any = [];
  usersPosition2: any = [];
  sensitivity = null;
  fromHour;
  times = [];
  toTimes = [];
  toHour;
  fromHourSelected = false;
  fromDate;
  toDate;
  today = new Date();
  tomorrow = new Date(this.today);
  url;
  isBooked = false;
  startEndTime;
  hotlineBookedSubs;
  isHotlineBooked: boolean;
  sensitivityNumbers = [
    {id: 1},
    {id: 2},
    {id: 3},
    {id: 4},
    {id: 5},
  ]
  minValue = 1;
  maxValue = 5;
  options: Options = {
    floor: 1,
    ceil: 5,
    showTicksValues: true,
    tickStep: 1,
    showSelectionBar: true
  };
  subscribedValue = {
    MIN_VALUE: 1,
    MAX_VALUE: 10
  }
  alarmStateData;
  private subscriptions: Subscription
  @ViewChild('template') template

  constructor(private services: Services,
              private householdService: HouseholdService,
              private sharedService: SharedService,
              private apiService: ApiService,
              private datePipe: DatePipe,
              private hotlineService: HotlineService,
              private translateService: TranslateService,
              private toasterService: ToastrService,
              public alarmStateService: AlarmStateService,
              private zone: NgZone,
              private modalService: BsModalService,
              public bsModalRef: BsModalRef,
  ) {
    this.subscriptions = new Subscription;
    this.sharedService.invokeEvent.subscribe(value => {
      if(value === 'close-eventChain-modal'){
        this.getEventUsers(); 
        this.bsModalRef.hide(); 
      }
      if(value ==='accept-eventChain-changes'){
        this.updateUserPositions()
        this.bsModalRef.hide(); 
      }
    });
  }

  ngOnInit(): void {
    this.householdId = localStorage.getItem('household_id');
    this.alarmStateService.checkAlarmState(this.householdId);
    const alarmState = this.alarmStateService.alarmState.subscribe(response => {
      this.zone.run(() => {
        this.alarmStateData = response;
      });
    });
    this.subscriptions.add(alarmState)

    this.fromHourSelected = true;
    this.hotlineBookedSubs = this.hotlineService.data$.subscribe(response => {
      console.log('response from subscriber in event chain: ', response);
    });
    this.subscriptions.add(this.hotlineBookedSubs)

    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    const householdData = this.householdService.getHouseholdData().subscribe(response => {
      this.sensitivity = this.getSensitivity(response);
    });
    this.subscriptions.add(householdData)
    const updatedHouseHold = this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.householdService.getHouseholdData().subscribe(response => {
        this.sensitivity = this.getSensitivity(response);
      });
      this.getEventUsers();
    });
    this.subscriptions.add(updatedHouseHold)
    this.getEventUsers();
    const halfHours = ['00', '30'];
    for (let i = 18; i < 24; i++
    ) {
      for (let j = 0; j < 2; j++) {
        let time = i + ':' + halfHours[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.times.push(time);
      }
    }
    for (let i = 0; i <= 10; i++) {
      for (let j = 0; j < 2; j++) {
        let time = i + ':' + halfHours[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.toTimes.push(time);
      }
    }
    const currentTime = this.datePipe.transform(new Date(), 'HH');
    const convertedTime = Number(currentTime);
    console.log('current time', typeof convertedTime);

  }
  updateUserPositions(){
    this.alarmStateService.checkAlarmState(this.householdId)
    const alarmState = this.alarmStateService.checkAlarmState2(this.householdId).subscribe({
      next:(response) => {
        if(response.result == 'alarm' || response.result == 'event'){
          this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'));
          this.getEventUsers()
          return false
        }
        const updateUserPositions = this.services.updateUsersPositions({type: 'event', pivot: this.alarmUsersPosition})
            .pipe(first())
            .subscribe((data: any) => {
              this.usersPosition.length = 0;
              this.usersPosition1.length = 0;
              this.usersPosition2.length = 0;
              this.alarmUsersPosition = '';
              // this.alarmUsersPosition.length = 0;
              console.log('update data', data);
            });
            this.subscriptions.add(updateUserPositions)     
      }
    })
    this.subscriptions.add(alarmState)
  }
  getEventUsers() {
    this.householdId = localStorage.getItem('household_id')
    
    this.url = environment.imagesApi;
    const eventUsers = this.services.getEventUsers(this.householdId).subscribe((data: any) => {
      this.usersWithoutPosition1.length = 0;
      this.usersWithPosition1.length = 0;
      this.usersWithPosition.length = 0;
      this.usersWithoutPosition.length = 0;
      for (let i = 0; i < data.users.length; i++) {
        if (data.users[i].pivot.position !== 100 && ((data.users[i].user_type === 'admin'
          || data.users[i].user_type === 'emergency'
          || data.users[i].user_type === 'owner'))) {
          this.usersWithPosition1.push(data.users[i]);
        } else if (data.users[i].pivot.position === 100 && ((data.users[i].user_type === 'admin'
          || data.users[i].user_type === 'emergency'
          || data.users[i].user_type === 'owner'))) {
          this.usersWithoutPosition1.push(data.users[i]);
        }
      }
      this.usersWithPosition = this.usersWithPosition1;
      this.usersWithoutPosition = this.usersWithoutPosition1;
      this.usersWithPosition.sort((a, b) => a.pivot.position - b.pivot.position);
    });
    this.subscriptions.add(eventUsers)

    const nightMode = this.apiService.getNightMode().subscribe(response => {
      console.log('response night mode', response);
      if (response.is_night_mode_active == 0) {
        console.log('empty');
        this.isChecked = false;
      } else {
        console.log('not empty')
        this.isChecked = true;
        this.fromHour = this.datePipe.transform(response.night_switch_start, 'HH:mm');
        this.toHour = this.datePipe.transform(response.night_switch_end, 'HH:mm');
        console.log('fromHour: ', this.fromHour, '\n toHour: ', this.toHour);
      }
    });
    this.subscriptions.add(nightMode)

    const hotlineStatus = this.hotlineService.hotlineStatus.subscribe(response => {
      if (response.result) {
        console.log('response from hotline subsc: ', response);
        this.isHotlineBooked = response.result.is_booked;
      } else {
        console.log('response from hotline subsc: ', response);
        this.isHotlineBooked = response.is_booked;
      }
    });
    this.subscriptions.add(hotlineStatus)
  }

  drop(event
         :
         CdkDragDrop<any[]>, state
  ) {
    this.alarmStateService.checkAlarmState(this.householdId)
    if(this.alarmStateData == 'alarm' || this.alarmStateData == 'event'){
      this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'));
      return false
    }

    if (state === 'right') {
      if (event.previousContainer === event.container) {
        console.log('right side navigation 1 EVENT CONTAINER DATA', event.container.data);
        console.log('right side navigation 1 EVENT PREVIOUS INDEX', event.previousIndex);
        console.log('right side navigation 1 EVENT CURRENT INDEX', event.currentIndex);
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.usersPosition1.length = 0;
        for (let i = 0; i < event.container.data.length; i++) {
          // event.container.data[i].pivot.position = i;
          // this.usersPosition1.push(event.container.data[i]);
          // console.log( event.container.data[i].pivot.position )
          event.container.data[i].pivot.position = i + 1;
          this.usersPosition1[i] = {
            id: event.container.data[i].id,
            household_id: event.container.data[i].pivot.household_id,
            user_id: event.container.data[i].pivot.user_id,
            position: '100'
          };
        }
        this.usersPosition.push(this.usersPosition1);

      } else {
        if (this.usersWithPosition.length == this.subscribedValue.MIN_VALUE) {
          this.toasterService.warning(this.translateService.instant('toasterMessages.eventMinimumUsersWarning'));
        } else {
          console.log('left to right EVENT PREVIOUS CONTAINER DATA', event.previousContainer.data);
          console.log('left to right EVENT CONTAINER DATA', event.container.data);
          console.log('left to right EVENT PREVIOUS INDEX', event.previousIndex);
          console.log('left to right EVENT CURRENT INDEX', event.currentIndex);
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
          this.usersPosition1.length = 0;
          this.usersPosition2.length = 0;
          for (let i = 0; i < event.container.data.length; i++) {
            // console.log('event container data ', i, ': ', event.container.data[i].pivot.position);
            event.container.data[i].pivot.position = i + 1;
            this.usersPosition1[i] = {
              id: event.container.data[i].id,
              household_id: event.container.data[i].pivot.household_id,
              user_id: event.container.data[i].pivot.user_id,
              position: '100',
            };
          }
          this.usersPosition = this.usersPosition1;
          for (let i = 0; i < event.previousContainer.data.length; i++) {
            event.previousContainer.data[i].pivot.position = i + 1;
            this.usersPosition2[i] = {
              id: event.previousContainer.data[i].id,
              household_id: event.previousContainer.data[i].pivot.household_id,
              user_id: event.previousContainer.data[i].pivot.user_id,
              position: event.previousContainer.data[i].pivot.position
            };
          }

          this.usersPosition = this.usersPosition.concat(this.usersPosition2);
          console.log('first array', this.firstArray);
          console.log('second array', this.secondArray);
          console.log('user position array', this.usersPosition);
          console.log('this.userposition', this.usersPosition);

          this.alarmUsersPosition = JSON.stringify(this.usersPosition);

          console.log('this.alarmUsersPosition', this.alarmUsersPosition);
          this.confirmChangesModal(this.template)
          // this.services.updateUsersPositions({type: 'event', pivot: this.alarmUsersPosition})
          //   .pipe(first())
          //   .subscribe((data: any) => {
          //     this.usersPosition.length = 0;
          //     this.usersPosition1.length = 0;
          //     this.usersPosition2.length = 0;
          //     this.alarmUsersPosition = '';
          //     // this.alarmUsersPosition;
          //     console.log('update data', data);
          //   });
        }
        // if (event.container.id === 'cdk-drop-list-1') {

      }
    } else {
      console.log('users in left side: ', this.usersWithPosition.length);

      if (this.usersWithPosition.length !== this.subscribedValue.MAX_VALUE) {
        console.log('users inside left side: ', this.usersWithPosition);
        if (event.previousContainer === event.container) {
          if(event.previousIndex == event.currentIndex){
            return false
          }
          console.log('move item in left side EVENT CONTAINER DATA', event.container.data);
          console.log('move item in left side 1 PREVIOUS INDEX', event.previousIndex);
          console.log('move item in left side 1 CURRENT INDEX', event.currentIndex);
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          this.usersPosition1.length = 0;
          for (let i = 0; i < event.container.data.length; i++) {
            // event.container.data[i].pivot.position = i;
            // this.usersPosition1.push(event.container.data[i]);
            // console.log( event.container.data[i].pivot.position )
            event.container.data[i].pivot.position = i + 1;
            this.usersPosition1[i] = {
              id: event.container.data[i].id,
              household_id: event.container.data[i].pivot.household_id,
              user_id: event.container.data[i].pivot.user_id,
              position: event.container.data[i].pivot.position,
            };
          }
          this.usersPosition = this.usersPosition1;

        } else {
          console.log('right to left EVENT PREVIOUS CONTAINER DATA', event.previousContainer.data);
          console.log('right to left ELSE 1 EVENT CONTAINER DATA', event.container.data);
          console.log('right to left ELSE 1 EVENT PREVIOUS INDEX', event.previousIndex);
          console.log('right to left ELSE 1 EVENT CURRENT INDEX', event.currentIndex);
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
          this.usersPosition1.length = 0;
          for (let i = 0; i < event.container.data.length; i++) {
            event.container.data[i].pivot.position = i + 1;
            this.usersPosition1[i] = {
              id: event.container.data[i].id,
              household_id: event.container.data[i].pivot.household_id,
              user_id: event.container.data[i].pivot.user_id,
              position: event.container.data[i].pivot.position,
            };
          }
          this.usersPosition = this.usersPosition1;

        }
        this.alarmUsersPosition = JSON.stringify(this.usersPosition);

        console.log('this.alarmUsersPosition', this.alarmUsersPosition);
        this.confirmChangesModal(this.template)
        // this.services.updateUsersPositions({type: 'event', pivot: this.alarmUsersPosition})
        //   .pipe(first())
        //   .subscribe((data: any) => {
        //     this.usersPosition.length = 0;
        //     this.usersPosition1.length = 0;
        //     this.usersPosition2.length = 0;
        //     this.alarmUsersPosition = '';
        //     // this.alarmUsersPosition.length = 0;
        //     console.log('update data', data);
        //   });
      } else {
        this.toasterService.warning(this.translateService.instant('toasterMessages.eventMaximumUsersWarning'));
      }
    }
  }

  pushSensitivity() {
    console.log('sensitivity', this.sensitivity);
    this.householdService.pushSensitivity(this.sensitivity).subscribe(response => {
      this.householdService.getHouseholdData().subscribe(res => {
        console.log('household data', res);
        this.sensitivity = this.getSensitivity(res);
      });
      console.log('response', response);
    });
  }

  getSensitivity(data) {
    const householdId = localStorage.getItem('household_id');
    for (const x in data) {
      if (data[x].id == householdId) {
        console.log('sensitivity', this.sensitivity);
        return data[x].sensitivity;
      }
    }
  }

  passFromHour(value) {
    if (value == undefined) {
      this.fromHour = '';
    }
    this.fromHourSelected = true;
    this.fromHour = value;
    console.log('from hour', this.fromHour);
    this.startEndTime = Number(this.fromHour.substring(0, this.fromHour.indexOf(':')));
    console.log('start end time', this.startEndTime);
    const halfHours = ['00', '30'];
    this.toTimes.length = 0;
    for (let i = this.startEndTime + 1; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        let time = i + ':' + halfHours[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.toTimes.push(time);
      }
    }
    for (let i = 0; i <= 10; i++) {
      for (let j = 0; j < 2; j++) {
        let time = i + ':' + halfHours[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.toTimes.push(time);
      }
    }
  }

  passToHour(value) {
    if (value == undefined) {
      this.toHour = '';
    }
    this.toHour = value;
    const toTime = Number(this.toHour.substring(0, this.toHour.indexOf(':')));
    console.log('tomorrow', this.tomorrow);

    const data = {
      householdId: this.householdId,
      night_switch_start: `${this.datePipe.transform(this.today, 'yyyy-MM-dd')} ${this.fromHour}`,
      night_switch_end: `${this.datePipe.transform(this.today, 'yyyy-MM-dd')} ${this.toHour}`,
      active: 1
    }
    if (toTime < 18) {
      console.log('tomorrow');
      data.night_switch_end = `${this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd')} ${this.toHour}`;
    } else {
      // data.night_switch_end = `${this.datePipe.transform(this.today, 'yyyy-MM-dd')} ${this.toHour}`;
      console.log('today');
    }
    console.log('data before sending to night mode', data);
    this.apiService.postNightMode(data).subscribe(res => {
      console.log('res', res);
    })
  }

  nightModeSwitch(e) {
    if (e.checked === false) {
      this.fromHour = '';
      this.toHour = '';
      const data = {
        householdId: this.householdId,
        night_switch_start: `0000:00:00 00:00`,
        night_switch_end: `0000:00:00 00:00`,
        active: 0
      }
      this.apiService.postNightMode(data).subscribe(res => {
        console.log('res', res);
      });
      if (e.checked === true) {
        this.fromHourSelected = true;
      }
     
    }
  }

  nAToaster(){
    this.toasterService.warning(this.translateService.instant('toasterMessages.NAContactEditDueToAlarmDesc'), this.translateService.instant('toasterMessages.NAContactEditDueToAlarm'));
  }

  // onDragEnded(event: CdkDragEnd): void{
  //   console.log('DOOOOOOOOOOOOOONNNNNNIIIIIIIIIII')
  //   this.alarmStateService.checkAlarmState2(this.householdId).subscribe({
  //     next:(x) => {
  //       console.log('DOOOOOOONIIII2',x)
  //       this.confirmChangesModal()
  //     }
  //   })
  // }

  confirmChangesModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template, {backdrop: 'static',  keyboard: false});
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    this.toasterService.clear()
  }
}
