<div id="responsive-table-desktop" class="custom-popup hide-on-med-and-down">
  <div class="ra-wrapper">
    <div class="ra-header">
      <div class="ra-header-content">
        <p *ngIf="!removeFully" class="ra-header-text">{{'myData.removeAccountFromHouseholdTitle' | translate}}</p>
        <p *ngIf="removeFully" class="ra-header-text">{{'myData.removeAccountFully' | translate}}</p>
      </div>
    </div>
  </div>
  <div class="ra-body">
    <div class="ra-content">
      <p *ngIf="!removeFully" class="ra-content-text text-center">{{'myData.removeAccountFromHouseholdDescription' | translate}}</p>
      <p *ngIf="removeFully" class="ra-content-text text-center">{{'myData.removeAccountFullyDescription' | translate}}</p>
    </div>
    <div class="ra-footer mt-5">
      <div class="ra-footer-content">
        <button *ngIf="!removeFully" [disabled]="buttonTriggered" (click)="removeAccount()" class="btn btn-confirm-ra ra-button-text">{{'global.yes' | translate | uppercase}}</button>
        <button *ngIf="removeFully" [disabled]="buttonTriggered" (click)="removeAccountFully()" style="width: auto !important;" class="btn btn-confirm-ra ra-button-text">{{'myData.deleteUser' | translate | uppercase}}</button>
        <button *ngIf="!removeFully" (click)="closeModal()" class="btn btn-close-ra ra-button-text">{{'global.no' | translate | uppercase}}</button>
        <button *ngIf="removeFully" (click)="closeModal()" class="btn btn-close-ra ra-button-text">{{'global.cancel' | translate | uppercase}}</button>
      </div>
    </div>
    <!--    <div class="custom-popup-header">-->
    <!--      <div class="row justify-content-center">-->
    <!--        <p class="ra-header-text">Kontakt entfernen</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="custom-popup-content">-->
    <!--      <p class="ra-content-text">Bist dusicher, das du aus diesen Haushalt entefernen mochtest?</p>-->
    <!--    </div>-->
    <!--    <div class="custom-popup-footer">-->
    <!--      <div class="custom-popup-footer-left">-->
    <!--        <button (click)="removeAccount()" class="btn btn-confirm-ra">JA</button>-->
    <!--      </div>-->
    <!--      <div class="custom-popup-footer-right">-->
    <!--        <button (click)="closeModal()" class="btn btn-close-ra">NEIN</button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
  </div>
</div>

<div id="responsive-table-mobile" class="custom-popup-mobile hide-on-large-only hide-on-extra-large-only">
  <div class="ra-wrapper-mobile">
    <div class="ra-header-mobile">
      <div class="ra-header-content-mobile">
        <p class="ra-header-text">{{'myData.removeAccountFromHouseholdTitle' | translate}}</p>
      </div>
    </div>
  </div>
  <div class="ra-body-mobile">
    <div class="ra-content">
      <p class="ra-content-text-mobile text-center">{{'myData.removeAccountFromHouseholdDescription' | translate}}</p>
    </div>
    <div class="ra-footer">
      <div class="ra-footer-content">
        <button [disabled]="buttonTriggered" (click)="removeAccount()" class="btn mb-3 mr-2 btn-confirm-ra-mobile ra-button-text">{{'global.yes' | translate | uppercase}}</button>
        <button (click)="closeModal()" class="btn mb-3 mr-2 btn-close-ra ra-button-text">{{'global.no' | translate | uppercase}}</button>
      </div>
    </div>
  </div>
</div>
