import {Component, NgZone, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Services} from '../../../services';
import {first} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {SharedService} from '../../../services/shared.service';
import {HouseholdService} from '../../../services/household.service';
import {AuthService} from '../../../auth/services/auth.service';

@Component({
  selector: 'app-create-contact',
  templateUrl: './create-contact.component.html',
  styleUrls: ['./create-contact.component.css']
})
export class CreateContactComponent implements OnInit {
  householdId;
  users;
  asd;
  formGroup;
  selectedOption;
  selectedGender;
  contactField;
  responseError;
  genderValue = 1;
  userId;
  responseMessage = '';
  showResponseMessage = false;
  contactTypes: any[] = [
    {
      id: 1, name: 'Normalkontakt',
    },
    {
      id: 2, name: 'Notfallkontakt',
    },
    {
      id: 3, name: 'Administrator',
    },
  ];
  genderArray: any[] = [
    {
      id: 0, name: 'Bitte wählen', state: true
    },
    {
      id: 1, name: 'Frau', state: false
    },
    {
      id: 2, name: 'Herr', state: false
    },
  ];
  selected = 1;
  user_type = 1;

  constructor(private services: Services,
              public CCRef: BsModalRef,
              private sharedService: SharedService,
              private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.householdId = localStorage.getItem('household_id');
    // this.userId = localStorage.getItem('user_id');
    this.userId = this.authService.getId();
    this.addHandler();
    this.selectedOption = this.contactTypes[0].name;
    this.selectedGender = this.genderArray[1].name;
  }

  public addHandler() {
    console.log('adding handler', this.selectedGender);
    this.formGroup = new FormGroup({
      user_type: new FormControl((this.selected == 1)
        ? 'normal' : (this.selected == 2
          ? 'emergency' : (this.selected == 3
            ? 'admin' : ''))),
      // user_type: new FormControl(('')),
      // gender: new FormControl((this.selectedGender == 1)
      //   ? 'Frau' : (this.selectedGender == 2
      //       ? 'Herr' : '')),
      // // gender: new FormControl(''),
      // user_type: new FormControl(''),
      id: new FormControl(this.userId),
      gender: new FormControl(''),
      name: new FormControl('', [
        Validators.required
      ]),
      surname: new FormControl('', [
        Validators.required
      ]),
      mobile_number: new FormControl('', [
        Validators.required, Validators.pattern('((^[+][0-9]{10,19}))')
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      household_id: new FormControl(this.householdId, [
        Validators.required
      ])
    });

  }

  updateUsers() {
    this.sharedService.sendUpdatedData();
  }

  public addUser() {
    // if (this.selectedGender == 'Frau') {
    //   this.selectedGender = 'female';
    // } else if (this.selectedGender == 'Herr') {
    //   this.selectedGender = 'male';
    // }
    this.formGroup.patchValue({
      gender: this.selectedGender === 'Frau' ? 'female' : this.selectedGender = 'male',
    });
    // this.formGroup.patchValue({
    //   type: this.selectedOption
    // });

    this.services.addNewUser(this.formGroup.value)
      .pipe(first())
      .subscribe((data: any) => {
          console.log('data', data);
          if (data.message == 'User with that email address already in this household') {
            this.responseMessage = 'E-Mail wird bereits verwendet.';
            this.showResponseMessage = true;
          } else {
            this.showResponseMessage = false;
            this.updateUsers();
            this.closeModal();
          }
        },
        error => {
        this.showResponseMessage = false;
          console.log('error', error);
          this.responseError = error;
        });
  }

  isFieldValid(field: string) {
    // return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
    //
    if (this.selected === 1) {
      // this.formGroup.get('mobile_number').setValidators(Validators.required);
      this.formGroup.get('mobile_number').clearValidators();
      this.formGroup.get('mobile_number').updateValueAndValidity();
      // this.formGroup.get('mobile_number').updateValueAndValidity();
      return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
    } else {
      this.formGroup.get('mobile_number').setValidators([Validators.required, Validators.pattern('((^[+][0-9]{10,19}))')]);
      // this.formGroup.get('mobile_number').clearValidators();
      // this.formGroup.get('email').updateValueAndValidity();
      this.formGroup.get('mobile_number').updateValueAndValidity();
      return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
    }
    // console.log('valid value: ', this.formGroup.get(field).valid, 'field: ', field);
  }

  closeModal() {
    this.CCRef.hide();
  }

  selectOption(id: number) {
    this.user_type = id;
    this.selected = id;
    this.selectedOption = this.contactTypes[id - 1].name;
    console.log('this', this.selectedOption);
    if (id == 1) {
      this.formGroup.user_type = 'normal';
    } else if (id == 2) {
      this.formGroup.user_type = 'emergency';
    } else if (id == 3) {
      this.formGroup.user_type = 'admin';
    }
    this.isFieldValid('mobile_number');
    this.formGroup.patchValue({
      type: this.formGroup.user_type
    });
    this.formGroup.patchValue({
      user_type: this.formGroup.user_type
    })
  }

  selectGender(id: number) {
    console.log('id', id);
    if (id == 0) {
    } else {
      this.selectedGender = this.genderArray[id].name;
    }
  }
}
