<div class="register-main">
    <!-- BEGIN: Header-->
    <div class="page-topbar" id="header">
      <div class="navbar navbar-fixed">
        <nav class="navbar-main red no-shadow">
        </nav>
      </div>
    </div>
    <!-- END: Header-->

    <!-- BEGIN: Page Main-->
    <div class="row">
      <div class="col s12 m10 offset-m1 l8 offset-l2">
        <div class="container">
          <div class="row mb-1">
            <div class="col s12">
              <div class="right-align"><img src="assets/images/logo/hannah-logo.svg" alt="HANNAH" class="hannah-logo"/>
              </div>
              <!-- <div class="clearfix"></div> -->
              <div class="card card card-default">
                <div *ngIf="type==1" class="card-content">
                    <h4 class="card-title">
                        {{'invalidInvitation.title' | translate}}
                        <br>
                        <br>
                        <span>
                            {{'invalidInvitation.description' | translate}}
                        </span>
                        <br>
                        <i class="material-icons mt-5 mb-5 warning white-text on-h-custom">error</i>
                    </h4>
                </div>
                <div *ngIf="type!=1" class="card-content">
                  <h4 class="card-title">
                      Der von Ihnen genutzte Link ist nicht länger gültig.
                      <br>
                      <br>
                      <span>
                        Melden Sie sich bitte mit Ihren Zugangsdaten an oder melden Sie sich bei einem Administrator des entsprechenden Haushalts um eine neue Einladung zu erhalten.
                      </span>
                      <br>
                      <i class="material-icons mt-5 mb-5 warning white-text on-h-custom">error</i>
                  </h4>
                  <button class="btn btn-center" (click)="toLogin()">Zur Anmeldung</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Page Main-->
  </div>
