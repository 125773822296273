import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging/';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor() {
    // this.angularFireMessaging.messages.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // );
  }
  // requestPermission = () => {
  //   this.angularFireMessaging.requestToken.subscribe(
  //     (token) => {
  //       console.log(token);
  //       localStorage.setItem('fcm_token', token);
  //     },
  //     (err) => {
  //       console.error('Unable to get permission to notify.', err);
  //     }
  //   );
  // }
  // receiveMessage = () => {
  //   this.angularFireMessaging.messages.subscribe(
  //     (payload) => {
  //       console.log('payload in service: ', payload);
  //       this.currentMessage.next(payload);
  //     });
  // }
}
