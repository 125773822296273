import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CalendarService {
  calendarData;
  calendarState = new Subject();
  //sharedCalendarData = this.calendarState.asObservable();

  constructor(private http: HttpClient) {
  }

  nextAlarmState(data: any) {
    this.calendarState.next(data);
  }

  shareCalendarData(type) {
    this.calendarData = type;
    this.nextAlarmState(this.calendarData);
  }

  getCalendarData(householdId) {
    return this.http.get(environment.api + `household-calendar-events/` + householdId).pipe();
  }


  postCalendarData(data) {
    return this.http.post(environment.api + `calendar-events`, data).pipe(map(response => {
      console.log('post calendar response: ', response)
    }, err => {
      console.log(err);
    }));
  }

  updateCalendarData(id, data) {
    return this.http.put(`${environment.api}calendar-events/${id}`, data).pipe();
  }

  deleteCalendarData(eventId, recurrence) {
    console.log('delete recurrence', eventId, recurrence);
    console.log('event id', eventId, 'recurrence', recurrence);
    return this.http.delete(`${environment.api}calendar-events/${eventId}`, recurrence).pipe();
  }
}
