<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'eventEdit.title' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a></li>
              <li class="breadcrumb-item">{{'tabs.alarmStatus' | translate}}</li>
              <li class="breadcrumb-item active">{{'eventEdit.title' | translate}}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">

        <div class="row mt-1">
          <div class="col s12 m12 l12">
            <div class="card-alert card green lighten-5">
              <div class="card-content green-text">
                <p>{{'eventEdit.note' | translate}}</p>
              </div>
            </div>
          </div>
          <div class="col s12 m12 l6">
            <div class="card card-default contacts">
              <div class="card-content">
                <div class="row">
                  <div class="col s12 mb-2">
                    <h4 class="card-title">{{'eventEdit.descriptionWithPosition' | translate}}
                      <a type="button" class="tooltipped right popover-content" data-position="bottom"
                         popover="{{isHotlineBooked ? ('eventEdit.help' | translate) : ('eventEdit.helpNotBooked' | translate)}}"
                         placement="bottom"

                      >
                        <i class="material-icons notranslate">help</i>
                      </a>
                    </h4>
                  </div>
                  <div class="col s12">
                    <div class="draggable-field">
                      <div class="dd" id="nestable">
                        <div
                          cdkDropList
                          #eventLeftList="cdkDropList"
                          [cdkDropListData]="usersWithPosition"
                          [cdkDropListConnectedTo]="[eventRightList]"
                          class="example-list"
                          (cdkDropListDropped)="drop($event, 'left')">
                          <ol class="dd-list">
                            <li class="dd-item" *ngFor="let item of usersWithPosition " cdkDrag >
                              <div class="dd-handle">
                                <img onError="this.src='../../assets/images/avatar/no-image.png'"
                                     *ngIf="item.avatar_path !== undefined && item.avatar_path !== null"
                                     src="{{item.avatar_path}}" alt="image"
                                     class="avatar user-icon">
                                <!-- <img
                                  *ngIf="item.avatar_path === 'female-avatar'"
                                  src="../../assets/images/avatar/female-avatar.png" alt="image" class="avatar user-icon">
                                <img
                                  *ngIf="item.avatar_path === undefined || item.avatar_path === null || item.avatar_path == ''"
                                  src="../../assets/images/avatar/no-image.png" alt="image" class="avatar user-icon"> -->
                                <span>{{item.name}} {{item.surname}}</span><i class="material-icons notranslate zoom-out-map">zoom_out_map</i>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m12 l6">
            <div class="card card-default contacts">
              <div class="card-content">
                <div class="row">
                  <div class="col s12 mb-2">
                    <h4 class="card-title">{{'eventEdit.descriptionWithoutPosition' | translate}}</h4>
                  </div>
                  <div class="col s12">
                    <div class="draggable-field">

                      <div class="dd" id="nestable2">
                        <div
                          cdkDropList
                          #eventRightList="cdkDropList"
                          [cdkDropListData]="usersWithoutPosition"
                          [cdkDropListConnectedTo]="[eventLeftList]"
                          class="example-list"
                          (cdkDropListDropped)="drop($event, 'right')">
                          <ol class="dd-list">
                            <li class="dd-item" *ngFor="let item of usersWithoutPosition" cdkDrag>
                              <div class="dd-handle">
                                <img *ngIf="item.avatar_path !== null && item.avatar_path !== undefined"
                                     onError="this.src='../../assets/images/avatar/no-image.png'"
                                     src="{{item.avatar_path}}" alt="image"
                                     class="avatar user-icon">
                                <!-- <img *ngIf="item.avatar_path =='female-avatar'"
                                     src="../../assets/images/avatar/female-avatar.png" alt="image" class="avatar user-icon">
                                <img *ngIf="item.avatar_path === null || item.avatar_path === undefined"
                                     src="../../assets/images/avatar/no-image.png" alt="image" class="avatar user-icon"> -->
                                <span>{{item.name}} {{item.surname}}</span><i class="material-icons notranslate zoom-out-map">zoom_out_map</i>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  hidden temporary -->
        <div class="row mb-1">
          <div class="col s12 m12 l6">
            <div class="card card-default mb-4 sensitivity-range">
              <div class="card-content">
                <h4 class="mb-0 mt-0">{{'eventEdit.sensitivity.title' | translate}}</h4>
                <p class="mb-2">{{'eventEdit.sensitivity.description' | translate}}</p>
                <div class="row mb-1">
                  <div class="col l6 medium-small pl-1">{{'eventEdit.sensitivity.sensitive' | translate}}</div>
                  <div
                    class="col l6 medium-small right-align pr-1">{{'eventEdit.sensitivity.insensitive' | translate}}</div>
                </div>

                <div class="custom-slider">
                  <ngx-slider [(ngModel)]="sensitivity" [options]="options" (click)="pushSensitivity()"></ngx-slider>
                </div>
              </div>
            </div>

          </div>
          <div class="col s12 m12 l6">
            <div class="card card-default">
              <div class="card-content">
                <div class="row">
                  <div class="col s7 m7">
                    <h4 class="mb-0 mt-0">{{'eventEdit.nightMode.title' | translate}}</h4>
                    <p *ngIf="isChecked" class="mb-4"><strong
                      class="green-text">{{'eventEdit.nightMode.active' | translate}}</strong></p>
                    <p *ngIf="!isChecked" class="mb-4"><strong
                      class="red-text">{{'eventEdit.nightMode.inactive' | translate}}</strong></p>
                  </div>

                  <div class="col s5 m5 right-align">
                    <div>
                      <mat-slide-toggle (change)="nightModeSwitch($event)" color="secondary"
                                        [(ngModel)]="isChecked"></mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="isChecked">
                  <div class="input-field col m4 s12">
                    <label class="active">{{'global.timeFrom' | translate}}</label>
                    <li class="btn-group " dropdown>
                      <a id="button-animated-1" class=" dropdown-toggle" dropdownToggle
                         data-target="profile-dropdown" aria-controls="dropdown-animated-1">
                        <input readonly [value]="fromHour" class="inputtypes form-control" type="text"
                               placeholder="{{'global.timeFrom' | translate}}"
                               [(ngModel)]="fromHour" type="text"/>
                      </a>
                      <ul
                        class="dropdown-menu dropdown-left-manual custom-menu-dropdown-profile custom-menu-dropdown night-mode-dropdown"
                        id="dropdown-animated-1" *dropdownMenu
                        role="menu" aria-labelledby="button-animated-1">
                        <li role="menuitem" *ngFor="let item of times" (click)="passFromHour(item)">
                          <a class="dropdown-item grey-text text-darken-1">

                            <span class="">{{item}} {{'global.hour' | translate}}</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </div>
                  <div class="input-field col m4 s12" *ngIf="fromHourSelected">
                    <label class="active">{{'global.timeTo' | translate}}</label>
                    <li class="btn-group " dropdown>
                      <a id="button-animated-2" class=" dropdown-toggle" dropdownToggle
                         data-target="profile-dropdown" aria-controls="dropdown-animated-2">
                        <input readonly [value]="toHour" class="inputtypes form-control" type=""
                               placeholder="{{'global.timeTo' | translate}}"
                               [(ngModel)]="toHour" type="text"/>
                      </a>
                      <ul
                        class="dropdown-menu dropdown-left-manual custom-menu-dropdown-profile custom-menu-dropdown night-mode-dropdown"
                        id="dropdown-animated-2" *dropdownMenu
                        role="menu" aria-labelledby="button-animated-2">
                        <li role="menuitem" *ngFor="let item of toTimes" (click)="passToHour(item)">
                          <a class="dropdown-item grey-text text-darken-1">

                            <span class="">{{item}} {{'global.hour' | translate}}</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <app-confirm-changes [type]="'event'"></app-confirm-changes>
</ng-template>

