import {Component, HostListener, NgZone, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MessagingService} from './services/messaging.service';
import {AlarmStateService} from './services/alarm-state.service';
import {AlarmLatestHistoryService} from './services/alarm-latest-history.service';
import {HannahAvailabilityService} from './services/hannah-availability.service';
import {EventScoreService} from './services/event-score.service';
import {HouseholdService} from './services/household.service';
import {SharedService} from './services/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from './auth/services/auth.service';
// import * as console from 'console';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'hannah-admin';
  blankUrl = '';
  currentUrl: string;
  checkoutUrls = ['login', 'notfallkontakt-anlegen',
    'household-select', 'position-confirmed', 'forgot-password', 'reset-password', 'invalid-invitation', 'hannah-config', 'hannah-config-expired'];
  message;
  householdId;
  innerWidth;
  mobileApp = false;
  _showBackdrop = true;

  userActivity;
  logoutTimeOut;
  userInactive: Subject<any> = new Subject();

  constructor(private router: Router,
              private messagingService: MessagingService,
              private alarmStateService: AlarmStateService,
              private hannahAvailabilityService: HannahAvailabilityService,
              private latestAlarmHistoryService: AlarmLatestHistoryService,
              private eventScoreService: EventScoreService,
              private householdService: HouseholdService,
              private zone: NgZone,
              private sharedService: SharedService,
              private translate: TranslateService,
              public authService: AuthService
  ) {
    translate.setDefaultLang('de');
    router.events.pipe
    (filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        console.log('navigation end: ', e);
        this.currentUrl = e.url;

        if(this.currentUrl.includes('hannah-config')){
          let returnUrl = this.currentUrl.split('/login?returnUrl=')[1];
          localStorage.setItem('wifi-config-redirect', decodeURIComponent(returnUrl));
        }

        setTimeout(callback => {
          window.scrollTo(0, 0);
        }, 100);
      });

      this.set5minTimeout();
      this.userInactive.subscribe(() => {
        this.sharedService.start5minCountdown();
        this.setLogoutTimeout()
      });

  }
  setLogoutTimeout(){
    this.logoutTimeOut = setTimeout(()=>{this.authService.logout(true)},1000*60*5)
  }
  set5minTimeout() {
    const hh_fullname = localStorage.getItem('household_fullName')
    const hh_id = localStorage.getItem('household_id')
    const jwt_token = localStorage.getItem('JWT_TOKEN')
    const jwt_token_refresh = localStorage.getItem('JWT_TOKEN_REFRESH')
    if(hh_fullname && hh_id && jwt_token && jwt_token_refresh){
     this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1000*60*5);
    }
  }
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    clearTimeout(this.logoutTimeOut)
    this.set5minTimeout();
    this.sharedService.stopCountDown();
  }
  isCheckoutRoute() {
    if (!this.currentUrl) {
      return false;
    }

    const splittedUrl = this.currentUrl.split('/');
    let index = this.checkoutUrls.indexOf(splittedUrl[1]);

    if(splittedUrl[1].includes('?')){
      let paramsUrl = splittedUrl[1].split('?');
      index = this.checkoutUrls.indexOf(paramsUrl[0]);
    }

    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit() {
    // this.sharedService.sidebar.subscribe(() => {
    //   this._opened = !this._opened;
    // })
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 994) {
      this.mobileApp = true;
    } else {
      this.mobileApp = false;
    }
    this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.householdId = localStorage.getItem('household_id');
    });
    this.householdId = localStorage.getItem('household_id');


    // this.messagingService.requestPermission();
    // this.messagingService.receiveMessage();
    // this.message = this.messagingService.currentMessage;
    // this.messagingService.currentMessage.subscribe(
    //   (payload) => {
    //     if (payload) {
    //       console.log('payload', payload);
    //       setTimeout(() => {
    //         if (payload.data.type === 'alarm' || payload.data.type === 'event' || payload.data.type === 'end' ||
    //           payload.data.type === 'endEvent') {
    //           this.alarmStateService.shareAlarmState(payload.data.type);
    //           this.alarmStateService.checkAlarmState(this.householdId);
    //           if (payload.data.type === 'alarm') {
    //             this.alarmStateService.doubleCheckAlarm(this.householdId);
    //           } else if (payload.data.type === 'event') {
    //             this.eventScoreService.doubleCheckEvent(this.householdId);
    //           }
    //           console.log('in timeout household', this.householdId);
    //           this.latestAlarmHistoryService.getLatestAlarmHistory(this.householdId);
    //           this.sharedService.sendUpdatedHistoryData();
    //           if (payload.data.type === 'event') {
    //             // this.eventScoreService.sharedScoreData(payload.data.score);
    //             this.eventScoreService.calculateScore(payload.data.score);
    //           }
    //           if (payload.data.type === 'endEvent') {
    //             console.log('payload end event')
    //             this.eventScoreService.resetScore();
    //           }
    //           // const data = {
    //           //   status: 'online',
    //           //   date: new Date()
    //           // }
    //           // this.hannahAvailabilityService.sharedHannahState(data);
    //         }
    //       }, 5000);
    //     } else {
    //     }
    //   }
    // );
  }
}
