<div class="register-main">
    <!-- BEGIN: Header-->
    <div class="page-topbar" id="header">
      <div class="navbar navbar-fixed">
        <nav class="navbar-main red no-shadow">
        </nav>
      </div>
    </div>
    <!-- END: Header-->

    <!-- BEGIN: Page Main-->
    <div class="row">
      <div class="col s12 m10 offset-m1 l8 offset-l2">
        <div class="container">
          <div class="row mb-1">
            <div class="col s12">
              <div class="right-align"><img src="assets/images/logo/hannah-logo.svg" alt="HANNAH" class="hannah-logo"/>
              </div>
              <!-- <div class="clearfix"></div> -->
              <div class="card card card-default">
                <div class="card-content">
                  <h4 *ngIf="withPosition" class="card-title">{{'positionConfirmed.title' | translate}} <br/> <span [translate]="'positionConfirmed.description'" [translateParams]="{ value: userType }"></span><br/>
                    <i class="material-icons background-round mt-5 mb-5 green white-text on-h-custom">done</i></h4> <h4
                  *ngIf="!withPosition" class="card-title">{{'positionConfirmed.title' | translate}}
                  <br/><span [translate]="'positionConfirmed.description'" [translateParams]="{ value: userType }"></span> <br/>
                  <i class="material-icons background-round mt-5 mb-5 green white-text on-h-custom">done</i></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Page Main-->
  </div>
