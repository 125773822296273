import {Component, OnInit} from '@angular/core';
import {HouseholdService} from '../../services/household.service';
import {Role} from '../../models/role';
import {SharedService} from '../../services/shared.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  collapse = false;
  routingPaths = [];
  constructor(private householdService: HouseholdService, private sharedService: SharedService) {
    // console.log('this.householdService.isAdmin();\n', this.householdService.isAdmin());
  }

  get isAuthorized() {
    return this.householdService.isAuthorized();
  }

  get isAdmin() {
    return this.householdService.hasRole(Role.Admin);
  }
  setRoutingPaths() {
    this.routingPaths = [
      {
        id: 1, name: 'dashboard', path: '/dashboard', icon: 'home', isAuthorized: this.isAuthorized
      },
      {
        id: 2, name: 'statistics', path: '/statistiken', icon: 'show_chart', isAuthorized: this.isAuthorized
      },
      {
        id: 3, name: 'alarmStatus', path: '/', icon: 'alarm', isAuthorized: this.isAuthorized, underTabs: [
          {
            id: 1, name: 'history', path: '/alarmierungs-historie', icon: 'radio_button_unchecked', isAuthorized: this.isAuthorized
          },
          {
            id: 2,
            name: 'alarmEdit',
            path: '/alarmkette-bearbeiten',
            icon: 'radio_button_unchecked', isAuthorized: this.isAuthorized
          },
          {
            id: 3,
            name: 'eventEdit',
            path: 'ereigniskette-bearbeiten',
            icon: 'radio_button_unchecked', isAuthorized: this.isAuthorized
          },
        ]
      },
      {
        id: 4, name: 'manageContacts', path: '/kontakte-verwalten', icon: 'perm_contact_calendar', isAuthorized: this.isAuthorized
      },
      {
        id: 5, name: 'myData', path: '/meine-daten', icon: 'person_outline', isAuthorized: true
      },
      {
        id: 6, name: 'calendar', path: '/calendar', icon: 'today', isAuthorized: this.isAuthorized
      },
      {
        id: 7, name: 'bookService', path: '/services-buchen', icon: 'add_shopping_cart', isAuthorized: this.isAuthorized
      },
      {
        id: 8, name: 'bills', path: '/rechnungen', icon: 'receipt', isAuthorized: this.isAuthorized
      },
      {
        id: 9, name: 'documents', path: '/dokumente', icon: 'import_contacts', isAuthorized: true
      },
      {
        id: 10, name: 'wifiConfig', path: '/wifiConfig', icon: 'wifi', isAuthorized: this.isAuthorized
      },
      {
        id: 11, name: 'help', path: '/hilfe', icon: 'help_outline', isAuthorized: true
      },
    ];
  }

  ngOnInit(): void {
    this.setRoutingPaths();
    this.sharedService.getUpdatedHousehold().subscribe(() => {
      this.setRoutingPaths();
    });
  }

  collapseAlarmTabs() {
    this.collapse = !this.collapse;
  }
}
