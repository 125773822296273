<!-- BEGIN: Page Main-->
<div id="main">
  <div class="row">

    <div class="breadcrumbs-light pb-0 pt-4" id="breadcrumbs-wrapper">
      <!-- Search for small screen-->
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h1 class="breadcrumbs-title mt-0 mb-0"><span>{{'statistics.title' | translate}}</span></h1>
            <ol class="breadcrumbs mb-0">
              <li class="breadcrumb-item"><a routerLink="/">{{'global.home' | translate}}</a></li>
              <li class="breadcrumb-item active">{{'statistics.title' | translate}}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div class="container">
        <div class="row mt-1">
          <div class="col s12 mb-1">
            <div class="card card card-default">
              <div class="card-content">
                <div class="row">
                  <div class="col s12">
                    <ul class="tabs mb-2">
                      <li class="statistics-tabs col m3" *ngFor="let tab of statisticsTabs">
                         <div class="text-center" [ngClass]="tab.selected ? 'active active-history-tabs' : ''">
                           <a class="statistics-title"
                              (click)="setStatisticsType(tab.id)">{{'statistics.tabs.' + tab.title | translate}}
                             <p class="statistics-subtitle">{{'statistics.tabs.' + tab.subtitle | translate}}</p>
                           </a>
                         </div>
                      </li>
                    </ul>
                    <p class="mb-1"><b>Filteroptionen</b></p>
                    <div class="row filter-options">
                      <div class="input-field col m3 l2 s12 form-group">
                        <input type="text" placeholder="Datum" (ngModelChange)="fetchHistory(1, $event)"
                               [(ngModel)]="fromDate" class="inputtypes form-control" [bsValue]="bsValue"
                               [maxDate]="maxDate" [bsConfig]="datePickerConfig" bsDatepicker>
                        <label class="active">{{'global.date' | translate}}</label>
                      </div>
<!--                      <div class="input-field col m3 l2 s12 form-group">-->
<!--                        <input type="text" placeholder="Datum bis" class="inputtypes"-->
<!--                               (ngModelChange)="fetchHistory(2, $event)" [(ngModel)]="toDate" [bsValue]="bsValue"-->
<!--                               [maxDate]="maxDate" [bsConfig]="datePickerConfig" bsDatepicker>-->
<!--                        <label class="active">Datum bis</label>-->
<!--                      </div>-->
                    </div>
                    <h4 class="card-title mb-2">{{'statistics.tabs.' + graphTitle | translate}}</h4>
                    <p class="text-center statistics-subtitle">{{'statistics.tabs.' + graphSubtitle | translate}}</p>
                    <div style="display: block;" *ngIf="showGraph">
                    <highcharts-chart
                      [Highcharts]="highcharts"
                      [options]="chartOptions"
                      [(update)]="updateChart1"
                      style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Page Main-->


