<!-- BEGIN: Header-->
<header class="page-topbar" id="header">
  <div class="navbar navbar-fixed">
    <nav class="navbar-main navbar-color nav-collapsible sideNav-lock red no-shadow">
      <div class="nav-wrapper">

        <div>
          <span *ngIf="countDown" class="inactivityText"
            [translate]="'global.countDownText'" [translateParams]="{ min: minutes, sec: seconds }"></span>
          <ul class="navbar-list right">
            <li>
              <a class="waves-effect waves-block waves-light list-style">
                <span class="show-on-medium-and-down hide-on-med-and-up myNav">
                  {{currentHousehold?.name}} {{currentHousehold?.surname}}
                </span>
                <span class="household-text hide-on-med-and-down myNav">
                  {{currentHousehold?.name}} {{currentHousehold?.surname}}
                </span>
              </a>
            </li>
            <!--            <li dropdown class="">-->
            <!--              <a id="button-animated-1" dropdownToggle-->
            <!--                 class="waves-effect waves-block waves-light notification-button dropdown-toggle"-->
            <!--                 aria-controls="dropdown-animated-1">-->
            <!--                <i class="material-icons notranslate">notifications_none-->
            <!--                  <small class="notification-badge">2</small>-->
            <!--                </i>-->
            <!--              </a>-->
            <!--              <ul id="dropdown-animated-1" *dropdownMenu-->
            <!--                  role="menu" aria-labelledby="button-animated-1"-->
            <!--                  class="dropdown-menu dropdown-left-manual group custom-menu-dropdown custom-menu-dropdown-notification">-->
            <!--                <li role="menuitem" class="list-divider">-->
            <!--                  <a class="dropdown-item">-->
            <!--                    Benachrichtigungen-->
            <!--                  </a>-->
            <!--                </li>-->
            <!--                <li role="menuitem">-->
            <!--                  <a class="dropdown-item">Michael als Notfallkontakt bestätigt-->
            <!--                  </a>-->
            <!--                </li>-->
            <!--                <li role="menuitem">-->
            <!--                  <a class="dropdown-item">Aktiver Alarm wurde ausgelöst</a>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </li>-->
            <li class="btn-group " dropdown>
              <a *ngIf="isLoggedIn" id="button-animated-2" class=" dropdown-toggle myNav" dropdownToggle
                 data-target="profile-dropdown" aria-controls="dropdown-animated-2">
                 <span class="">
                  <!-- <img *ngIf="url + userAvatar === null || url + userAvatar === undefined || userAvatar === undefined || userAvatar === null"
                      class="icon-carto myNav" src="../assets/images/avatar/no-image.png" alt="avatar"/> -->
                  <img *ngIf="userAvatar !== undefined && userAvatar !== null"
                      onError="this.src='../assets/images/avatar/no-image.png'"
                      src="{{userAvatar}}" alt="avatar"
                      class="icon-carto myNav"/>
                </span>
              </a>
              <ul *dropdownMenu
                  class="dropdown-menu dropdown-left-manual custom-menu-dropdown-profile  custom-menu-dropdown"
                  id="dropdown-animated-2"
                  role="menu" aria-labelledby="button-animated-2">
                <li role="menuitem">
                  <a class="dropdown-item grey-text text-darken-1" routerLink="meine-daten">
                    <!--                    <mat-icon>person_outline</mat-icon>-->
                    <img class="pr-4 profile-icon" src="../assets/images/profile/person_outline.svg"/>
                    <span class="">{{'dashboard.menu.myData' | translate}}</span>
                  </a>
                </li>
                <li role="menuitem" *ngFor="let household of households">
                  <a *ngIf="household?.id !== currentHousehold.id" class="dropdown-item grey-text text-darken-1"
                     (click)="changeHousehold(household)">
                    <!--                    <mat-icon>help_outline</mat-icon>-->
                    <img class="pr-4 profile-icon" src="../assets/images/profile/household.svg"/>
                    <span>{{'dashboard.menu.showHousehold' | translate}} {{household?.name}} {{household?.surname}}</span>
                  </a>
                </li>
                <li role="menuitem">
                  <a class="dropdown-item grey-text text-darken-1" routerLink="hilfe">
                    <!--                    <mat-icon>help_outline</mat-icon>-->
                    <img class="pr-4 profile-icon" src="../assets/images/profile/help_outline.svg"/>
                    <span>{{'dashboard.menu.help' | translate}}</span>
                  </a>
                </li>
                <li role="menuitem">
                  <a class="dropdown-item grey-text text-darken-1"
                     (click)="logout()">
                    <!--                    <mat-icon>keyboard_tab</mat-icon>-->
                    <img class="pr-4 profile-icon" src="../assets/images/profile/keyboard_tab.svg"/>
                    <span>{{'dashboard.menu.logout' | translate}}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
<div *ngIf="collapse">
  <ul class="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out"
      data-menu="menu-navigation" data-collapsible="menu-accordion"
      style="overflow: scroll; transform: translateX(0%);">
    <app-tabs></app-tabs>
  </ul>
</div>

<aside class="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light sidenav-active-square">
  <div class="brand-sidebar">
    <h1 class="logo-wrapper">
      <a class="brand-logo darken-1 myNav" routerLink="dashboard" routerLinkActive="active">
        <img
          class="hide-on-med-and-down myNav" src="../assets/images/logo/hannah-logo.svg" alt="hannah"/>
        <img style="margin-right: 50px" width="90"
             class="show-on-medium-and-down hide-on-med-and-up myNav" src="../assets/images/logo/hannah-logo-weiss.svg"
             alt="hannah"/>
      </a>
    </h1>
  </div>
  <ul class="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out"
      data-menu="menu-navigation" data-collapsible="menu-accordion">
    <app-tabs></app-tabs>
  </ul>
  <div class="navigation-background"></div>
  <a
    class="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only white"
    (click)="collapseMenu()" data-target="slide-out"><i class="material-icons notranslate black-text">menu</i></a>
</aside>
<!-- END: SideNav-->
