<div class="create-contact-box">
  <div id="kontaktanlegen" class="container">
    <div id="createevent" class="">
      <div class="">
        <a class="padding-rl-15" (click)="closeModal()" class="modal-action modal-close grey-text"><i
          class="material-icons notranslate">close</i></a>
        <h4>{{'calendar.createNewEvent' | translate}}</h4>
        <div class="row">
          <div class="input-field col s12">
            <input [(ngModel)]="title" id="event_title_create" placeholder="{{'global.title' | translate}}">
<!--            <span class="red-text" *ngIf="specialCharactersDetected.title">Special characters not allowed</span>-->
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input [(ngModel)]="description" id="event_description_create" placeholder="{{'global.description' | translate}}">
<!--            <span class="red-text" *ngIf="specialCharactersDetected.description">Special characters not allowed</span>-->
          </div>
        </div>
        <div class="row">
          <div class="input-field col m3 s12">
            <div class="">
              <label>{{'calendar.startEvent' | translate}}</label> <br/>
            <!--              <input type="text" name="date_from_create" value="" placeholder="Datum von">-->
              <input readonly (ngModelChange)="changeEndDate($event)" [(ngModel)]="startDate" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [min]="todayDate"
                   [ngModelOptions]="{standalone: true}" placeholder="{{'global.dateFrom' | translate}}">
              <owl-date-time #dt1></owl-date-time>
            </div>
          </div>
          <div class="input-field col m3 s12">
            <div class="">
              <label>{{'calendar.endEvent' | translate}}</label> <br/>
              <input readonly [(ngModel)]="endDate" [ngModelOptions]="{standalone: true}" [owlDateTime]="dt2" [min]="startDate"
                   [owlDateTimeTrigger]="dt2" placeholder="{{'global.dateTo' | translate}}">
              <owl-date-time #dt2></owl-date-time>
            <!--              <input type="text" name="date_till_create" value="" placeholder="Datum bis">-->
            </div>
          </div>
          <div class="input-field col m3 s12">
            <div class="" dropdown>
              <div class="">
                <label>{{'calendar.status' | translate}}</label> <br/>
                <input readonly id="button-disabled-item-1" dropdownToggle
                       aria-controls="dropdown-disabled-item-1" [value]="selectedView"
                       class="form-control dropdown-toggle">
              </div>
              <ul id="dropdown-disabled-item-1" *dropdownMenu
                  class="dropdown-menu custom-option"
                  role="menu" aria-labelledby="button-disabled-item-1">
                <li [value]="item.id" *ngFor="let item of viewAs" role="menuitem"
                    [ngClass]="item.state ? 'disabled' : 'dropdown-item'" (click)="selectView(item.id)">
                  <a class="dropdown-item">{{item.name}}</a></li>
              </ul>
            </div>
            <!--              <select>-->
            <!--                <option value="" disabled selected>Bitte wählen</option>-->
            <!--                <option value="1">Zuhause</option>-->
            <!--                <option value="2">Abwesend</option>-->
            <!--              </select>-->
          </div>
          <div class="input-field col m3 s12 select-recurring-date-type">
            <div class="" dropdown>
              <div class="">
                  <label>{{'global.recurring' | translate}}</label><br/>
                  <input readonly id="button-disabled-item" dropdownToggle [value]="selectedSerialAppointment"
                       aria-controls="dropdown-disabled-item" class="form-control dropdown-toggle">
              </div>
              <ul id="dropdown-disabled-item" *dropdownMenu
                  class="dropdown-menu custom-option"
                  role="menu" aria-labelledby="button-disabled-item">
                <li [value]="item.id" *ngFor="let item of serialAppointment" role="menuitem"
                    [ngClass]="item.disabled ? 'disabled' : 'dropdown-item'"
                    (click)="selectSerialAppointment(item.id)">
                  <a class="dropdown-item">{{item.name}}</a></li>
              </ul>
            </div>
            <!--              <select>-->
            <!--                <option value="1" selected>Nein</option>-->
            <!--                <option value="2">Ja</option>-->
            <!--              </select>-->
          </div>
          <div class="" *ngIf="showSeries">
            <div class="input-field col m3 s12 select-recurring-date-type">
              <div class="" dropdown>
                <div class="">
                  <input readonly id="button-disabled-item-3" dropdownToggle [value]="selectedSeries"
                         aria-controls="dropdown-disabled-item-3" class="form-control dropdown-toggle">
                </div>
                <ul id="dropdown-disabled-item-3" *dropdownMenu
                    class="dropdown-menu custom-option"
                    role="menu" aria-labelledby="button-disabled-item-3">
                  <li [value]="item.id" *ngFor="let item of series" role="menuitem"
                      [ngClass]="item.disabled ? 'disabled' : 'dropdown-item'"
                      (click)="selectSeries(item.id)">
                    <a class="dropdown-item">{{item.name}}</a></li>
                </ul>
              </div>
              <!--              <select>-->
              <!--                <option value="1" selected>Nein</option>-->
              <!--                <option value="2">Ja</option>-->
              <!--              </select>-->
            </div>
            <div class="col-md-3">
              <!--              <div>-->
              <!--                <dx-radio-group-->
              <!--                  [items]="resourcesList"-->
              <!--                  [(value)]="selectedResource"-->
              <!--                  layout="horizontal">-->
              <!--                </dx-radio-group>-->
              <!--              </div>-->
              <!--              <div class="input-field">-->
              <!--                <input [(ngModel)]="recurrenceCount"  [ngModelOptions]="{standalone: true}"  placeholder="Ocurrencie(s)" type="number" min="1" max="50"/>-->
              <!--              </div>-->
              <div class="row">
                <label class="radio" *ngFor="let item of resourcesList">
                  <span class="radio__input">
                    <input type="radio" name="radio" (change)="checkSelectedResource(item.id)" [checked]="item.checked">
                    <span class="radio__control"></span>
                  </span>
                  <span class="radio__label">{{item.name}}</span>
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="input-field" *ngIf="selectedResource == 0">
                <input [(ngModel)]="recurrenceDate" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" [min]="startDate"
                       [ngModelOptions]="{standalone: true}" placeholder="{{'global.dateTo' | translate}}">
                <owl-date-time #dt3></owl-date-time>
              </div>
              <div class="input-field" *ngIf="selectedResource == 1">
                <input class="recCountInput" [(ngModel)]="recurrenceCount" [ngModelOptions]="{standalone: true}" placeholder="Ocurrencie(s)"
                       type="number" min="1" max="50"/>
                       <span class="ml-2">Terminen</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <button class="btn btn-large waves-effect waves-light ml-1" type="submit" name="action"
                    (click)="setAppointment()">{{'calendar.saveEvent' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
