import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {StatistikenComponent} from './components/statistiken/statistiken.component';
import {KontakteVerwaltenComponent} from './components/kontakte-verwalten/kontakte-verwalten/kontakte-verwalten.component';
import {AlarmierungsHistorieComponent} from './components/alarmierungs-historie/alarmierungs-historie.component';
import {AlarmketteBearbeitenComponent} from './components/alarmkette-bearbeiten/alarmkette-bearbeiten.component';
import {EreignisketteBearbeitenComponent} from './components/ereigniskette-bearbeiten/ereigniskette-bearbeiten.component';
import {MeineDatenComponent} from './components/meine-daten/meine-daten.component';
import {ServicesBuchenComponent} from './components/services-buchen/services-buchen.component';
import {RechnungenComponent} from './components/rechnungen/rechnungen.component';
import {DokumenteComponent} from './components/dokumente/dokumente.component';
import {HilfeComponent} from './components/hilfe/hilfe.component';
import {LoginComponent} from './auth/components/login/login.component';
import {NotfallkontaktAnlegenComponent} from './components/notfallkontakt-anlegen/notfallkontakt-anlegen.component';
import {AuthGuard} from './auth/guards/auth.guard';
import {CalendarComponent} from './components/calendar/calendar/calendar.component';
import {HouseholdSelectComponent} from './components/household-select/household-select.component';
import {PositionConfirmedComponent} from './components/position-confirmed/position-confirmed.component';
import {ForgotPasswordComponent} from './auth/components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './auth/components/reset-password/reset-password.component';
import {AuthService} from './auth/services/auth.service';
import {Role} from './models/role';
import {AdminGuard} from './app-routing.guard';
import { InvalidInvitationComponent } from './components/notfallkontakt-anlegen/invalid-invitation/invalid-invitation.component';
import { WifiConfigComponent } from './components/wifi-config/wifi-config.component';
import { HannahConfigComponent } from './components/hannah-config/hannah-config.component';


const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: '',
    children: [
      {
        path: 'dashboard', component: DashboardComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {
        path: 'statistiken', component: StatistikenComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {
        path: 'kontakte-verwalten', component: KontakteVerwaltenComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {
        path: 'alarmierungs-historie', component: AlarmierungsHistorieComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {
        path: 'alarmkette-bearbeiten', component: AlarmketteBearbeitenComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {
        path: 'ereigniskette-bearbeiten', component: EreignisketteBearbeitenComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {path: 'meine-daten', component: MeineDatenComponent},
      {
        path: 'calendar', component: CalendarComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {
        path: 'services-buchen', component: ServicesBuchenComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {
        path: 'rechnungen', component: RechnungenComponent, canActivate: [AdminGuard]
      },
      {
        path: 'dokumente', component: DokumenteComponent
      },
      {
        path: 'wifiConfig', component: WifiConfigComponent, canActivate: [AdminGuard], data: {
          role: Role.Admin
        }
      },
      {path: 'hilfe', component: HilfeComponent},
      {path: 'household-select', component: HouseholdSelectComponent},
      {path: 'hannah-config/:id', component: HannahConfigComponent},
      // {path: 'notfallkontakt-anlegen', component: NotfallkontaktAnlegenComponent}, // does not require login
    ],
    canActivate: [AuthGuard],
    data: {
      role: 'admin'
    }
  },
  {path: 'position-confirmed', component: PositionConfirmedComponent}, // does not require login
  {path: 'invalid-invitation/:type', component: InvalidInvitationComponent},

  // { path: '**', component: LoginComponent },
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: 'notfallkontakt-anlegen/:token', component: NotfallkontaktAnlegenComponent}, // does not require login
  {path: 'position-confirmed/:token', component: PositionConfirmedComponent}, // does not require login
  // { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
  {path: '**', component: LoginComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuthService
  ]
})
export class AppRoutingModule {
}
